import api from "./api";
import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import {
  CreateAttachmentResponse,
  PostAttachmentResponse,
} from "./models/my-orders";

export interface Errors {
  exception: string[];
}

export enum AttachmentType {
  image = "image",
}

export interface FileUploadApiResponse {
  guid?: string;
  errors: Errors;
}

export type UploadResponse = {
  response: FileUploadApiResponse;
  url: string;
};

export function createAttachment(
  orderId: string,
  attachmentType: AttachmentType
): Promise<CreateAttachmentResponse> {
  return api
    .get<CreateAttachmentResponse>(`/my/orders/${orderId}/chat_assets/new`, {
      params: {
        attachment_type: attachmentType,
      },
    })
    .then((response) => response.data);
}

export function postAttachment(
  orderId: string,
  assetId: string,
  contentType: string
): Promise<PostAttachmentResponse> {
  return api
    .post<PostAttachmentResponse>(`/my/orders/${orderId}/chat_assets`, {
      chat_asset: {
        asset_uid: assetId,
        content_type: contentType,
      },
    })
    .then((response) => response.data);
}

export function uploadFile(
  url: string,
  file: File,
  type: string,
  onProgress: (event: AxiosProgressEvent) => void
): [Promise<unknown>, CancelTokenSource] {
  const cancelObject = axios.CancelToken.source();

  const response = api
    .put<unknown>(url, file, {
      headers: {
        "Content-Type": type,
      },
      onUploadProgress: onProgress,
      cancelToken: cancelObject.token,
      withCredentials: false,
    })
    .then((response) => response.data);

  return [response, cancelObject];
}

export function deleteFile(guid: string) {
  if (guid) {
    api
      .delete("https://api.kasamba.com/sessions/files/" + guid)
      .then(() => console.log("delete file" + guid + " from blob"));
  }
}

export function advisorChatHangup(orderId: string): Promise<any> {
  return api.delete(`/my/works/${orderId}`).then((response) => response.data);
}

export function changeAppStateAPI(orderId: string, state: string) {
  return api.put(`/my/works/${ orderId }/client_state?state=${ state }`)
    .then(response => response.data);
}
