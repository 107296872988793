import * as React from 'react';
import ReactModal from 'react-modal';

export const ConnectionErrorModal =
    () => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content"  >
            <h1 style={{marginBottom: '20px'}}>Connection Error</h1>
            <div style={{marginBottom: '20px'}}>There was a problem connecting to the server.<br/> Please, check your internet connection.</div>
            <button  className="primary" onClick={()=> window.location.reload()}>RELOAD</button>
        </ReactModal>
    );