import { FirebaseError, initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  DocumentData,
  Firestore,
} from "firebase/firestore";
import { buffers, EventChannel, eventChannel } from "redux-saga";
import { call, put, select, spawn, take } from "redux-saga/effects";
import { userIdSelector } from "../slices/userSlice";
import { answeredOnAnotherDevice, clientAskToStart, declineSession, missedSession } from "../slices/sessionSlice";
import { ServerUserStatus } from "../types/userTypes";
import { userStatusSelector } from "../selectors/userSelectors";
import * as loggly from '../services/logger'
import { activeSessionClientSelector } from "../selectors/sessionSelectors";
import { ClientCardDetailsResponse, getClientCardDetails } from "../services/api/clientApi";
import { handleGetClientList } from "./clientList";
import { appSettingsSelector } from "../selectors/appSettingsSelector";
import { AppSettingsState } from "../types/appSettingsTypes";
import { AppState } from "../store";
import { SessionStatus } from "../types/sessionTypes";

const OrderTypes = {
  Chat: "c",
  Voice: "a",
  Video: "v",
};

const OrderStates = {
  ring: "ring",
  in_progress: "in_progress",
  ended: "ended",
  buyer_ended: "buyer_ended",
  advisor_ended: "advisor_ended",
  missed_call: "missed_call"
};

let firestore: Firestore | null = null;

export function* connectToFirestore(token: string) {
  const appSettings: AppSettingsState = yield select(appSettingsSelector);
  const firebaseConfig = {
    projectId: appSettings.firestoreProjectId,
    apiKey: appSettings.firestoreAPIKey,
    messagingSenderId: appSettings.firestoreSenderId,
    appId: appSettings.firestoreAppId,
  };
  const app = initializeApp(firebaseConfig, "firestore");

  const auth = getAuth(app);
  if (!auth.currentUser) {
    yield call(signInWithCustomToken, auth, token);
  }

  // Initialize Cloud Firestore and get a reference to the service
  firestore = getFirestore(app);

  yield spawn(monitorFirestoreChanges);
}

function* monitorFirestoreChanges() {
  const userId: number = yield select(userIdSelector);
  let channel: EventChannel<DocumentData> = yield call(
    createFirestoreChannel,
    userId
  );

  while (true) {
    try {
      const [id, documentData]: [string, DocumentData] = yield take(channel);

      switch (documentData["s"]) {
        case OrderStates.ring:
          if (documentData["ot"] !== OrderTypes.Chat) {
            continue;
          }
          const userStatus: ServerUserStatus = yield select(userStatusSelector);
          if (userStatus !== ServerUserStatus.Available) {
            continue;
          }
          yield put(clientAskToStart(documentData["uid"], id));
          break;
        case OrderStates.in_progress:
          const sessionStatus: SessionStatus = yield select((state: AppState) => state.session.status);
          if ((sessionStatus & (SessionStatus.accepted | SessionStatus.started)) != 0 && (sessionStatus & SessionStatus.ended) == 0) {
            continue;
          }
          const userInfo: ClientCardDetailsResponse = yield call(getClientCardDetails, documentData["uid"]);
          yield put(answeredOnAnotherDevice(documentData["uid"], userInfo.clientAlias));
          yield handleGetClientList();
          break;
        case OrderStates.ended:
        case OrderStates.buyer_ended:
        case OrderStates.advisor_ended:
          {
            const activeSessionClient: ReturnType<typeof activeSessionClientSelector> = yield select(activeSessionClientSelector);
            if (activeSessionClient && activeSessionClient.id !== documentData["uid"]) {
              console.log(`Received 'ended' event from firestore channel, but skipping it because active session id ${activeSessionClient.id} != ${documentData["uid"]}`)
              continue;
            }
            yield put(declineSession());
            break;
          }
        case OrderStates.missed_call:
          {
            const userStatus: ServerUserStatus = yield select(userStatusSelector);
            if (userStatus !== ServerUserStatus.Available) {
              continue;
            }
            yield put(missedSession());
            break;
          }
      }
    }
    catch (error) {
      console.log(error);
      channel.close();
      channel = yield call(
        createFirestoreChannel,
        userId
      );
    }
  }
}

let initialSnapshotLoad = true;

function createFirestoreChannel(userId: number) {
  console.log(`Create firestore channel for user ${userId}`);

  return eventChannel<[string, DocumentData] | FirebaseError>((emit) => {
    const changeHandler = (id: string, payload: DocumentData) => emit([id, payload]);

    const q = query(
      collection(firestore!, "orders"),
      where("auid", "==", userId),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (initialSnapshotLoad) {
        initialSnapshotLoad = false;
        return;
      }

      const change = querySnapshot
        .docChanges()
        .sort((a, b) => Number(b.doc.id) - Number(a.doc.id))
        .at(0);

      if (!change || change.type == 'removed') {
        return;
      }
      const data = change.doc.data();
      console.log(change.type, change.doc.id, data);
      changeHandler(change.doc.id, data);
    }, (error: FirebaseError) => {
      loggly.error(error, { code: error.code });
      emit(error);
    });

    return () => {
      unsubscribe();
    };
  }, buffers.expanding(3));
}
