import { FC, useState } from "react";
import {
  EarningChartDateItem,
  EarningChartItem,
} from "../../../services/api/models/my-advisor-dashboard";
import { Card } from "../../atoms/Card";

import "./EarningsChart.scss";
import { formatDate } from "../../../helpers/dateFormatHelper";
import { ChartColumn } from "../../atoms/dashboard/ChartColumn";
import { earningItemTitleByType } from "../../../helpers/dashboardHelper";

export const EarningsChart: FC<EarningChartItem> = (props) => {
  const [selectedPosition, setSelectedPosition] = useState(6);
  const prepareChartColumns = () => {
    const amountsList = props.dates.map((x) => x.total_amount);

    return props.dates.map((date, i) => (
      <ChartColumn
        key={i}
        amount={date.total_amount}
        amountsList={amountsList}
        isSelected={selectedPosition == i}
        onClick={() => {
          setSelectedPosition(i);
        }}
      />
    ));
  };

  const chartPart = () => {
    return (
      <div className="chart-part">
        <div className="chart-header">
          <div className="chart-title">
            <span>Earnings </span>
            {formatDate(new Date(props.start_date), false, true)}
            {" - "}
            {formatDate(new Date(props.end_date), false, true)}
          </div>
          <span className="chart-total-amount">
            ${props.total_amount.toFixed(2)}
          </span>
        </div>
        <span className="chart-tooltip">Tap a bar for more data</span>

        <div className="chart-container">{prepareChartColumns()}</div>
      </div>
    );
  };

  const isNoRevenue = props.dates[selectedPosition].total_amount === 0;

  const buildNoEarningsPlaceholderText = () => (
    <span className="no-earnings">No earnings on this day</span>
  );

  const buildDetailsColumn = (earningDay: EarningChartDateItem) => {
    return earningDay.grouped_orders.map((order, i) => (
      <div key={i} className="day-detail-item">
        <span>
          {order.orders_count} {earningItemTitleByType(order.type)}:{" "}
        </span>
        ${order.total_amount.toFixed(2)}
      </div>
    ));
  };

  const buildDayDetail = () => {
    const earningDay = props.dates[selectedPosition];
    return (
      <div className="day-detail">
        <div className="day-detail-left">
          <span className="day-detail-date">
            {formatDate(new Date(earningDay.date), false, true)}
          </span>
          <span className="day-detail-amount">${earningDay.total_amount.toFixed(2)}</span>
        </div>
        <div className="day-detail-right">{buildDetailsColumn(earningDay)}</div>
      </div>
    );
  };

  const detailPart = () => {
    return (
      <div className="detail-part">
        {isNoRevenue ? buildNoEarningsPlaceholderText() : buildDayDetail()}
      </div>
    );
  };

  return (
    <Card className="earnings-chart">
      {chartPart()}
      {detailPart()}
    </Card>
  );
};
