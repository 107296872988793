import ringingModern from "../assets/modern.mp3";
import ringingClassic from "../assets/classic.mp3";

import { FontSize, Ringing } from "../types/appSettingsTypes";
import { CURRENT_SITE_ID } from "../config";

enum Sites {
  Kasamba = "KA",
  Purple = "PO",
}

export function fontSizeName(fontSize: FontSize): string {
  return FontSize[fontSize].toString().toLowerCase();
}

export function fontSizeClass(fontSize: FontSize): string {
  return "chat-text-size-" + fontSizeName(fontSize);
}

export function GetRingingByType(ringing: Ringing) {
  return ringing === Ringing.Classic ? ringingClassic : ringingModern;
}

export const isKasambaApp = (CURRENT_SITE_ID === Sites.Kasamba);
export const isPurpleOceanApp =!isKasambaApp;
