import React from "react";
import { kasambaFormatDate } from "../../helpers/dateFormatHelper";
import "./MessageItem.scss";
import { DiscountCard } from "./DiscountCard";

type MessageItemProps = {
    text: string;
    userName: string;
    avatarUrl?: string;
    isExpertMessage: boolean;
    sentDate: Date;
    readDate?: Date | null;
    hasUsedPromotion?: boolean;
    isSheduled: boolean;
    isOffer: boolean;
    offerDiscount?: number;
    offerExpiryInfo?: string;
}


const MessageItem = ({ userName, text, avatarUrl, isExpertMessage, sentDate, isSheduled, hasUsedPromotion, readDate, isOffer, offerDiscount, offerExpiryInfo }: MessageItemProps) => {
    let className = 'instant-message ' + (isExpertMessage ? 'expert-instant-message' : 'client-instant-message');
    let statusName = 'Unread';
    let statusIconClassName = 'status-icon';
    if (readDate) {
        statusName = 'Read';
        statusIconClassName += ' read';
    }

    if(hasUsedPromotion) {
        statusName = 'Discount used';
        statusIconClassName += ' discount';
    }

    if (isSheduled) {
        statusName = 'Scheduled';
        className += ' scheduled';
    }

    return <div className={className}>
        {/* {isExpertMessage && <div className="message-status"><i className={statusIconClassName}></i><span>{statusName}</span></div>} */}
        <div className="instant-message__header">
            {isExpertMessage && <img alt="" src={avatarUrl} />}
            <div className="instant-message__title">
                <div className="instant-message__user-name">{userName}</div>
                <div className="instant-message__sent-date">{kasambaFormatDate(sentDate)}</div>
            </div>
        </div>
        <div className="instant-message__content">
            {!isOffer && text}
            {isOffer && 
                <>
                    <DiscountCard percent={offerDiscount!} selected={true} showCheckMark={false} thinVersion={true} />
                    <div className="offer-text">{text}</div>
                    <div className="separator" />
                    <div className="expiry-info">{ offerExpiryInfo }</div>
                </>
            }
        </div>
    </div>;
};

export default MessageItem;