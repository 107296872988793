import { useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
  forgotPassword,
  signIn,
  signInSetError,
} from "../../../slices/authorizationSlice";
import { AppState } from "../../../store";
import {
  SignInRequestState,
  TokenType,
} from "../../../types/authorizationTypes";
import "./K3Login.scss";
import { signErrorSelector } from "../../../selectors/authorizationSelector";
import { SUPPORT_SOCIAL_LOGIN } from "../../../config";
import GoogleSignInGsi, {
  GoogleCredentialResponse,
  clickGoogleSignIn,
} from "../../atoms/GoogleSignInGsi";
import FacebookSignIn, {
  FbResponse,
  serviceFB,
} from "../../atoms/FacebookSignIn";
import Spinner from "../../atoms/Spinner";
import { textManager } from "../../../config/textManager";
import { fbAppIdSelector, googleAppIdSelector } from "../../../slices/appSettingsSlice";

const mapState = (state: AppState) => ({
  state: state.authorization.state,
  error: signErrorSelector(state),
  googleAppId: googleAppIdSelector(state),
  fbAppId: fbAppIdSelector(state),
});

const connector = connect(mapState, {
  signInSetError,
  signIn,
  forgotPassword,
});

export const K3Login = (props: PropsFromRedux) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [sdkLoaded, setSdkLoaded] = useState(0);

  const handleEmailSignIn = (e: any) => {
    e.preventDefault();
    props.signInSetError(null);
    props.signIn(email, password, rememberMe);
  };

  const handleSuccessGoogleSignIn = (response: GoogleCredentialResponse) => {
    if (response.credential) {
      props.signInSetError(null);
      props.signIn(email, password, rememberMe, response.credential, TokenType.Google);
    }
  };

  const handleFaceBookSignIn = () => {
    serviceFB.signIn((response: FbResponse) => {
      if (response.status === "connected") {
        props.signInSetError(null);
        props.signIn(
          email,
          password,
          rememberMe,
          response.authResponse.accessToken,
          TokenType.Facebook
        );
      }
    });
  };

  const buttonDisabled =
    !email || !password || props.state === SignInRequestState.Requesting;

  return (
    <div className={"login-container"}>
      <div className={"login-form-container"}>
        <div className={"logo"}>
          <img alt="logo" src={textManager.login.logoSrc} />
        </div>
        <div className="login-title">
          <p>
            {textManager.login.title }
          </p>
          <div className="subtitle">Account credentials are the same as on the app</div>
        </div>
        <form className={"login-form"} onSubmit={handleEmailSignIn}>
          {props.error && <div className="error">{props.error}</div>}
          <div className={"input-container"}>
            <label className={"input-label"}>Email</label>
            <input
              className={"input-field"}
              type="text"
              placeholder={ textManager.login.emailPlaceholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={"input-container"}>
            <label className={"input-label"}>Password</label>
            <input
              className={"input-field"}
              type="password"
              placeholder={ textManager.login.passwordPlaceholder }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={"checkbox-container"}>
            <input
              className={"checkbox-field"}
              type="checkbox"
              checked={rememberMe}
              id="rememberMe"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe" className={"checkbox-label"}>Keep me logged in</label>
          </div>
          <div className={"button-container"}>
            <button
              className={"button"}
              disabled={buttonDisabled}
              type="submit"
            >
              LOG IN TO YOUR ACCOUNT
            </button>
          </div>
        </form>
        <div className={"forgot-password"}>
          <span
            onClick={() => {
              props.forgotPassword(email);
            }}
          >
            Forgot Password
          </span>
        </div>
        {SUPPORT_SOCIAL_LOGIN && (
          <div className="social-network-container">
            <div className="or-connect">Or connect with</div>
            <div className="social-network-login">
              {sdkLoaded === 2 && (
                <>
                  <button
                    onClick={handleFaceBookSignIn}
                    disabled={props.state === SignInRequestState.Requesting}
                  >
                    <img alt="facebook logo" src="/images/facebook-logo.svg" />
                    <span>Facebook</span>
                  </button>
                  <button
                    onClick={clickGoogleSignIn}
                    disabled={props.state === SignInRequestState.Requesting}
                  >
                    <img alt="google logo" src="/images/google-logo.svg" />
                    <span>Google</span>
                  </button>
                </>
              )}
              <GoogleSignInGsi
                successHandler={handleSuccessGoogleSignIn}
                sdkLoaded={() => {
                  setSdkLoaded((old) => old + 1);
                }}
                googleAppId={props.googleAppId}
              />
              <FacebookSignIn
                sdkLoaded={() => {
                  setSdkLoaded((old) => old + 1);
                }}
                fbAppId={props.fbAppId}
              />
            </div>
            {sdkLoaded < 2 && (
              <div>
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(K3Login);
