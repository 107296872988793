import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  setAppFont,
  setAppRinging,
  setAppVolume,
} from "../../slices/appSettingsSlice";
import {
  fontsSelector,
  soundsSelector,
} from "../../selectors/appSettingsSelector";
import { AppState } from "../../store";
import { FontSize, Ringing } from "../../types/appSettingsTypes";
import SoundSettings from "../molecules/SoundSettings";
import FontSettings from "./FontSettings";
import "./Settings.scss";
import { Howl } from "howler";
import { GetRingingByType } from "../../helpers/settingsHelper";
import TemplateSettings from "./TemplateSettings";
import { setAutoChatMessageEnabled } from "../../slices/userSlice";
import { signOut } from "../../slices/authorizationSlice";

export interface ISettingsProps {
  closeClick: () => void;
}

const mapState = (state: AppState, own: ISettingsProps) => ({
  settings: state.appSettings,
  fontsSettings: fontsSelector(state),
  soundSettings: soundsSelector(state),
  isAutoChatMessageEnabled: state.user.isAutoChatMessageEnabled,
  autoMessageText: state.user.autoChatMessageText,
  close: own.closeClick,
});

const connector = connect(mapState, {
  setAppFont: setAppFont,
  setAppRinging: setAppRinging,
  setAppVolume: setAppVolume,
  setAutoMessageEnabled: setAutoChatMessageEnabled,
  signOut: signOut,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const Settings = (props: PropsFromRedux) => {
  function playSample(ringing: Ringing, volume: number) {
    const ringingAudio = new Howl({
      src: GetRingingByType(ringing),
      volume: volume / 100,
    });
    ringingAudio.play();
  }

  return (
    <div className="settings-container">
      <div className="settings-controls">
        <div className="close" onClick={() => props.close()}></div>
      </div>
      <div className="settings-content">
        <div className="settings-title">Settings</div>
        {/* <div className="settings-sound-wrapper">
          <SoundSettings
            selected={props.soundSettings.ringing}
            onSelected={(ringing: Ringing) => props.setAppRinging(ringing)}
            onPlay={(ringing: Ringing) => playSample(ringing, props.soundSettings.volume)}
            volume={props.soundSettings.volume}
            onChangeHandler={(volume: number) => {
              props.setAppVolume(volume);
              playSample(props.soundSettings.ringing, volume);
            }}
          />
        </div>

        <FontSettings
          onSelected={(size: number) => {
            props.setAppFont(size);
          }}
          selectedFontSize={props.settings?.fonts?.size || FontSize.Small}
        />

        {props.autoMessageText &&
        <TemplateSettings 
        isAutoChatMessageEnabled={props.isAutoChatMessageEnabled} 
        onAutoChatMessageEnabledChanged={props.setAutoMessageEnabled} />} */}

        <button onClick={() => props.signOut()}>Sign out</button>
      </div>
    </div>
  );
};

export default connector(Settings);
