import { ReactSVG } from 'react-svg';
import './ArrowButton.scss';

type Props = {
    onClick: VoidFunction;
    direction: 'left' | 'right';
    visible?: boolean;
}

export const ArrowButton = ({ onClick, direction, visible = true }: Props) => {
    return <button className={`arrow-btn ${direction}`} onClick={onClick} style={{ opacity: visible ? 1 : 0 }}>
        <ReactSVG src="/images/arrow-icon.svg" />
    </button>
}