import {
  MessagePayload,
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";

import {
  FirebaseApp,
  FirebaseOptions,
  initializeApp,
} from "firebase/app";
import {
  CurrentUserResponse,
  getCurrentUser,
  setIsAvailable,
  setChatMode,
  subscribeNotification,
  getConnectionConfig,
} from "../services/api/userApi";
import { call, put, retry, select, spawn, take } from "redux-saga/effects";
import { EventChannel, eventChannel } from "redux-saga";
import {
  accountStateChanged,
  currentUserDetailsLoaded,
  isImpersonatedSelector,
  setAvailabilitySucceeded,
} from "../slices/userSlice";
import { AccountState, ServerUserStatus } from "../types/userTypes";
import { closeModal, showAlertModal, showModal } from "../slices/modalSlice";
import { appSettingsSelector } from "../selectors/appSettingsSelector";
import { AppSettingsState } from "../types/appSettingsTypes";
import { NewJobMessageNotification } from "../services/api/models/NewMessageNotification";
import { newJobMessage, setUnreadMessages } from "../slices/clientListSlice";
import { userStatusSelector } from "../selectors/userSelectors";
import {
  askNotificationPermission,
  createNotification,
} from "../services/desktopNotificationService";
import { ModalType } from "../types/modalTypes";
import * as loggly from "../services/logger";
import { liveChatModeOff } from "../actions/messageActions";
import { MyAvailabilityResponse } from "../services/api/models/my-availability";
import {
  ConnectionConfig,
  MyAdvisorResponse,
  mapToCurrentUserResponse,
} from "../services/api/models/my-advisor";
import { connectToFirestore } from "./firestore";

let channel: EventChannel<MessagePayload>;
let messaging: Messaging | null = null;
let isFirebaseInitialized = false;
let swRegistration: ServiceWorkerRegistration | undefined;

enum NotificationType {
  chat_ring = "chat_ring",
  chat_ended = "chat_ended",
  live_chat_mode_off = "live_chat_mode_off",
  new_job_message = "new_job_message",
  status_changed = "status_changed",
  account_state_changed = "account_state_changed",
  trending_advisor = "trending_advisor",
  new_feedback = "new_feedback",
  default = "default",
  new_job = "new_job",
}

export function* setFirebase() {
  const isImpersonated: boolean = yield select(isImpersonatedSelector);

  if (isImpersonated) {
    return false;
  }

  const notificationGranted: boolean = yield call(
    prepareNotificationPermission
  );

  if (!notificationGranted) {
    return false;
  }

  const appSettings: AppSettingsState = yield select(appSettingsSelector);
  yield call(initFirebase, appSettings);
  const connectionConfig: ConnectionConfig = yield call(getConnectionConfig);
  yield call(connectToFirestore, connectionConfig.firebase_auth_token);

  const token: string = yield tryGetToken();

  console.log("token fb", token);

  yield call(subscribeNotification, token);
  yield spawn(monitorForMessages);

  return true;
}

export function* setAdvisorAvailable() {
  if (!isFirebaseInitialized) {
    const succeded: boolean = yield setFirebase();
    if (!succeded) {
      yield put(setAvailabilitySucceeded(ServerUserStatus.Away));
      return false;
    }
  }

  const token: string = yield tryGetToken();
  const userResponse: CurrentUserResponse = yield call(getCurrentUser);

  if (!userResponse.isAvailable) {
    const availabilityResponse: MyAvailabilityResponse = yield call(
      setIsAvailable,
      true,
      token
    );
    if (availabilityResponse.alert) {
      yield put(
        showAlertModal(
          availabilityResponse.alert.title,
          availabilityResponse.alert.body,
          "Got it"
        )
      );
    }
  }

  try {
    const response: MyAdvisorResponse = yield call(setChatMode, true, token);
    yield put(currentUserDetailsLoaded(mapToCurrentUserResponse(response)));
    return true;
  } catch (e: any) {
    if (e.full_messages && e.full_messages.length > 0) {
      yield put(
        showAlertModal(
          "Status change unavailable",
          e.full_messages[0],
          "Got it"
        )
      );
      yield put(setAvailabilitySucceeded(ServerUserStatus.Away));
      return false;
    }
  }
}

export function* setAdvisorAway() {
  try {
    const response: MyAdvisorResponse = yield call(setChatMode, false);
    yield put(currentUserDetailsLoaded(mapToCurrentUserResponse(response)));
    return response;
  } catch {
    return null;
  }
}

export function closeFCMChannel() {
  channel?.close();
}

async function initFirebase(settings: AppSettingsState) {
  const options: FirebaseOptions = {
    apiKey: settings.firebaseAPIKey,
    projectId: settings.firebaseProjectId,
    messagingSenderId: settings.firebaseSenderId,
    appId: settings.firebaseAppId,
  };

  const encodedOptions = encodeURIComponent(
    window.btoa(JSON.stringify(options))
  );

  swRegistration = await navigator.serviceWorker.register(
    `/firebase-messaging-sw.js?options=${encodedOptions}`,
    {
      scope: "./",
    }
  );

  await navigator.serviceWorker.ready;

  if (isFirebaseInitialized) return;

  const app: FirebaseApp = initializeApp(options);
  messaging = getMessaging(app);

  isFirebaseInitialized = true;
}

function* prepareNotificationPermission() {
  if (typeof Notification === "undefined") {
    yield put(
      showAlertModal("", `This browser/platform is not supported`, "OK")
    );
    loggly.error("Notifications API is not supported");
    return false;
  }

  if (Notification.permission === "granted") {
    return true;
  }

  if (Notification.permission === "default") {
    yield put(showModal(ModalType.NotificationsAllow));
    yield take(closeModal.type);
    yield put(showModal(ModalType.Empty));
  }

  const notificationPermission: NotificationPermission = yield call(
    askNotificationPermission
  );
  yield put(closeModal());
  if (notificationPermission === "granted") {
    return true;
  }

  yield put(showModal(ModalType.NotificationsBlocked));
  return false;
}

function* tryGetToken() {
  const appSettings: AppSettingsState = yield select(appSettingsSelector);
  const token: string = yield retry(3, 500, getToken, messaging!, {
    vapidKey: appSettings.firebaseVapidKey,
    serviceWorkerRegistration: swRegistration,
  });

  return token;
}

function* monitorForMessages() {
  channel = yield call(createFcmMessagesChannel, messaging!);

  while (true) {
    const payload: MessagePayload = yield take(channel);
    const { data } = payload;

    if (!data) return;

    console.log("receive firebase-message ", data);

    switch (data["ntype"]) {
      // case NotificationType.chat_ring:
      //   const userStatus: ServerUserStatus = yield select(userStatusSelector);
      //   if (userStatus === ServerUserStatus.Available) {
      //     const {
      //       buyer_id,
      //       buyer_nickname,
      //       message,
      //       tryout,
      //       duration,
      //       server_send_time,
      //     } = data;
      //     yield put(
      //       clientAskToStart(
      //         +buyer_id,
      //         buyer_nickname,
      //         message,
      //         "",
      //         null,
      //         tryout === "true",
      //         +duration / 60,
      //         new Date(server_send_time)
      //       )
      //     );
      //   }
      //   break;
      // case NotificationType.chat_ended:
      //   const { missed, replace_text, answered_on_another_device } = data;
      //   if (answered_on_another_device) {
      //     yield put(answeredOnAnotherDevice());
      //   } else {
      //     yield put(declineSession(missed === "true", replace_text));
      //   }
      //   break;
      case NotificationType.live_chat_mode_off: {
        yield put(liveChatModeOff());
        break;
      }
      case NotificationType.new_job_message:
        const message = data as any as NewJobMessageNotification;
        yield put(setUnreadMessages(+message.buyer_id, true));
        yield put(newJobMessage(message));
        break;
      case NotificationType.status_changed: {
        const { body, message } = data;
        const currentStatus: ServerUserStatus = yield select(
          userStatusSelector
        );
        if (
          [ServerUserStatus.Available, ServerUserStatus.Busy].includes(
            currentStatus
          )
        ) {
          yield put(liveChatModeOff(body));
          yield put(showAlertModal(message, body, "Got it"));
        }
        break;
      }
      case NotificationType.account_state_changed: {
        const { state, notification } = data as any;
        yield put(
          accountStateChanged(state as AccountState, notification.body)
        );
        break;
      }
      case NotificationType.trending_advisor:
      case NotificationType.new_job:
      case NotificationType.new_feedback:
      case NotificationType.default:
        const { notification } = data as any;
        if (notification) {
          createNotification(
            notification.body,
            undefined,
            false,
            data["ntype"]
          );
        }
    }
  }
}

function createFcmMessagesChannel(messaging: Messaging) {
  return eventChannel<MessagePayload>((emit) => {
    const messageHandler = (payload: MessagePayload) => emit(payload);

    const messagesChannel = new BroadcastChannel("fcm-messages");
    messagesChannel.addEventListener(
      "message",
      (event: MessageEvent<MessagePayload>) => messageHandler(event.data)
    );

    const messagesUnsubscribe = onMessage(messaging!, messageHandler);

    return () => {
      messagesChannel.close();
      messagesUnsubscribe();
    };
  });
}
