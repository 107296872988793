import axios from "axios";
import { CANCEL } from "redux-saga";
import { MessageItemData } from "../../types/clientTypes";
import { MessageAsset, MessageBulkSendRequestData, MessageKind, MessageTemplateData } from "../../types/messageTypes";
import api from "./api";
import { MyOrderChatMessagesResponse, mapToSessionReading } from "./models/my-orders";
import { ActivityItem, MyActivityApiResponse, Type } from "./models/MyActivityApiResponse";
import { DiscountCoupon } from "./userApi";

export enum BatchMessageResponseStatus {
    success = 0,
    blacklisted = 1,
};

export type SendScheduledMessageResponse = {
    status: number;
};

export type GetScheduleMessageResponse = {
    id: number;
    content: string;
    createDate: Date | null;
    delayTimeInMinutes: number | null;
    sendDate: Date | null;
};

export type SendMessageResponse = {
    status: number;
    errors: any;
    activity_item?: ActivityItem;
    discount_coupons?: DiscountCoupon[];
};

export type BatchMessageResponse = {
    status: BatchMessageResponseStatus;
};

export type GetMessagesResponse = {
    messages: MessageItemData[] | null;
    responseStatus: number;
    destinationMemberDetails: any;
    nextPage: number | undefined;
};

export type MessageInfo = {
    messageType: number; // 0 - direct, 1 - templated
    lifecycleType?: number | null;
    messageTemplateId?: number;
};

export type MessagePromotionInfo = {
    discountType?: number; // 0 - percent, 1 - fixed
    discountValue?: number;
    discountDurationDays?: number;
};

export type MessageExtra = {
    templateData: MessageInfo;
    promotionData?: MessagePromotionInfo;
};

export type Phrase = {
    owner: number;
    kind: MessageKind;
    body: string | MessageAsset;
};

export type SessionReading = {
    expertId: number;
    clientId: number;
    sessionId: number;
    conversation: Phrase[] | null;
    startedAt: Date | null;
};

export function getScheduleMessage(
    clientId: number
): Promise<GetScheduleMessageResponse> {
    return new Promise((resolve, reject) => {
        return { }

    });

    // return api
    //     .get(`/api/experts/me/clients/${clientId}/scheduledMessage`)
    //     .then((response) => response.data);
}

// export function sendScheduledMessage(
//   clientId: number,
//   text: string,
//   delay?: number
// ): Promise<SendScheduledMessageResponse> {
//   return api
//     .post(`/api/experts/me/scheduled-messages`, { clientId, text, delay })
//     .then((response) => response.data);
// }

export function sendScheduledMessage(
    clientId: number,
    text: string,
    delay?: number,
    extra?: MessageExtra
): Promise<SendMessageResponse> {
    return api
        .post(
            `https://api.kasamba.com/Member/InstantMessages/InsertScheduleMessage`,
            {
                DestinationMemberID: clientId,
                Content: text,
                DelayTimeInMinutes: delay,
                ...extra,
            },
            {
                headers: {
                    AntiForgeryToken: (window as any).antiForgeryToken,
                },
            }
        )
        .then((response) => response.data);
}

export function sendMessage(
    clientId: number,
    text: string,
    couponId?: number,
): Promise<SendMessageResponse> {
    return api
        .post(
            `/users/${clientId}/feed_messages`,
            {"content":{ "message": text, discount_coupon_id: couponId },
            "message_kind": "text"}
        )
        .then((response) => {
            return {
                status: 0,
                errors: null,
                activity_item: response.data.activity_item
            }
        }).catch ((error) => { 
            console.log("error send message",error)
            return {
                status: error.response.status,
                errors: error?.response?.data?.errors.base[0]
            }
        });
}

export function bulkSendMessage(
    request: MessageBulkSendRequestData
): Promise<SendMessageResponse> {
    return api
        .post(
            `/api/experts/me/messages/batch`,
            request,
            {
                headers: {
                    AntiForgeryToken: (window as any).antiForgeryToken,
                },
            }
        )
        .then((response) => response.data);
}

export function getMessages(
    clientId: number,
    pageNum: number,
    pageSize: number
): Promise<GetMessagesResponse> {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let request: any = api
        .get<MyActivityApiResponse>(
            `/my/activity?buyer_id=${clientId}&order=DESC&show_only=messages${pageNum?'&page='+pageNum.toString():''}` //&type=messages${pageNum?'&page='+pageNum.toString():''}`,
        ) //order=ASC/DESC
        .then((response) => {
            return mapToMessagesResponse(response.data); 
        });
    request[CANCEL] = () => source.cancel();
    return request;
}

export function GetMessageTemplates(): Promise<MessageTemplateData[]> {
    let url = `/api/experts/me/messages/templates`;
    //TODO: need to FIX 
    // let request: any = api.get(url).then((response) => response.data);
    //    return request;
    return new Promise<MessageTemplateData[]>(()=>[])
}

export function getSessionReading(sessionId: number): Promise<SessionReading> {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let request: any = api
        .get<MyOrderChatMessagesResponse>(`/my/works/${sessionId}/chat_messages`, {
            cancelToken: source.token,
        })
        .then((response) => mapToSessionReading(response.data, sessionId));

    request[CANCEL] = () => source.cancel();

    return request;
}

export const mapToMessagesResponse = (response: MyActivityApiResponse): GetMessagesResponse => {
    if (!response || !response.activity_items || !response.buyer) {
        throw new Error('Invalid response');
    }

    const getContent = (activityItem: ActivityItem) => {
        switch(activityItem.type) {
            case Type.Coupon: return activityItem.data.message;
            case Type.Message: return activityItem.data.content?.message;
            default: return null;
        }
    }

    let messages = response.activity_items.map(t => {
        if(!t.data) return null;

        return {
            id: t.id,
            memberId: response.buyer.id,
            expertId: 0,
            content: getContent(t),
            isExpertMessage: t.type === Type.Message ? t.data.message_type !== "incoming" : true,
            readDate: null,
            createDate: new Date(t.created_at || ""),
            hasUsedPromotion: false,
            isOffer: t.type === Type.Coupon,
            offerDiscountId: t.type === Type.Coupon ? t.data.discount_coupon_id : null
        }
    })
    messages = messages.reverse();
    return {
        messages: messages.filter(t=>t !== null) as Array<MessageItemData> | null,
        responseStatus: 1,
        destinationMemberDetails: response.buyer,
        nextPage: response.pagination_params?.page
    }
};