import * as React from 'react';
import { AppState } from '../../store';
import { connect, ConnectedProps } from 'react-redux';
import { setReadingViewed } from '../../slices/userSlice'
import './SessionLastReadings.scss';
import Spinner from '../atoms/Spinner';
import { kasambaFormatDate } from '../../helpers/dateFormatHelper';
import { ClientReadingData } from '../../types/clientTypes';
import { getReadingBySessionId } from '../../slices/sessionSlice';
import { textManager } from '../../config/textManager';
import { SessionType } from '../../types/clientListTypes';

const SessionLastReadings = ({ lastReadings,
    setReadingViewed,
    viewedReadingsId,getReadingBySessionId, clientId }: PropsFromRedux & SessionNotesProps) => {
    const readingClickHandler = (item: ClientReadingData) => {
        if (item.sessionType !== SessionType.Chat) {
            return;
        }
        setReadingViewed(item.sessionId);
        getReadingBySessionId(item.sessionId, item.sessionStartTime);
    }

    return <div className="session-last-readings">
        <div className="header">
            <div className="title">
                {textManager.readings.recentReadings}
            </div>
            {lastReadings && lastReadings.length > 0 && <a className="view-all" href="#"
                onClick={() => window.open(`${window.location.origin}/#r/${clientId}`, "_blank", 'noopener,noreferrer')}>
                {textManager.readings.viewAllReadings}</a>}
        </div>
        {lastReadings == null && <Spinner size={40} />}
        {lastReadings && lastReadings.length === 0 && <div className="empty">{textManager.readings.emptyReadings}</div>}
        {lastReadings?.map(r =>
            <div onClick={() => readingClickHandler(r)}
                className={'reading-item' + (viewedReadingsId.includes(r.sessionId) ? ' viewed' : '')}
                key={r.sessionId}>
                <div className="time">{kasambaFormatDate(r.sessionStartTime)}</div>
                {r.sessionType === SessionType.Chat && <div className="view-reading">{textManager.readings.viewReading}</div>}
            </div>
        )}
    </div>;
};

interface SessionNotesProps {
    onNoteEditorOpened?: () => void,
    onTextChangedInTheEnd?: () => void
}

const mapState = (state: AppState) => ({
    lastReadings: state.session.lastReadings,
    viewedReadingsId: state.user.viewedReadingIds,
    clientId: state.session.client?.id
});

const connector = connect(mapState, { setReadingViewed, getReadingBySessionId })
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SessionLastReadings); 