import { SessionReading } from "../../services/api/messagesApi";
import TranscriptPhrase from "./TranscriptPhrase";

export type TranscriptBodyProps = {
  data: SessionReading | null;
  isError: boolean;
  isLoading: boolean;
  clientId: number | undefined;
  clientName: string | undefined;
  expertName: string;
};

export default function TranscriptBody(props: TranscriptBodyProps) {
  const dataExists = (): boolean => {
    return !!(!props.isLoading && !props.isError && props.data);
  };

  const dataAbsent = (): boolean => {
    return !!(
      !props.isLoading &&
      !props.isError &&
      ((props.data && props.data.conversation?.length === 0) || !props.data)
    );
  };

  return (
    <>
      {dataExists() && (
        <div className="transcript-text">
          {props.data?.conversation?.map((t, index) => (
            <TranscriptPhrase
              ownerName={
                (t.owner === props.clientId
                  ? props.clientName
                  : props.expertName) || ""
              }
              isExpert={t.owner !== props.clientId}
              body={t.body}
              kind={t.kind}
              sessionId={props.data!.sessionId}
              key={index + "fr"}
              upKey={index + "fr"}
            />
          ))}
        </div>
      )}

      {props.isError && (
        <div className="transcript-error">
          Can not load the transcript. Please try later.
        </div>
      )}
      {dataAbsent() && (
        <div className="transcript-error">No transcription is available</div>
      )}
    </>
  );
}
