import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { loadClientsByGroups } from '../../slices/groupMessageSlice';
import { groupMessageShowConfirm } from '../../slices/groupMessageSlice';
import { bulkSendMessage } from '../../actions/messageActions';
import { closeModal } from '../../slices/modalSlice';
import { content } from '../../config/content';
import { groupMessageSelector } from "../../selectors/groupMessageSelector";
import { AppState } from '../../store';
import { ClientsByGroupsData } from '../../types/clientListTypes';
import { MessageBulkSendRequestData } from '../../types/messageTypes';
import PromotionMessageButton from '../atoms/buttons/PromotionMessageButton';
import Spinner from '../atoms/Spinner';
import './GroupMessageModal.scss';

enum DiscountType {
    Percentage = '0',
    FixedAmount = '1',
}

const discountTypeOptions = [
    { value: DiscountType.Percentage, label: 'Percentage' },
    { value: DiscountType.FixedAmount, label: 'Fixed Amount' },
]

const discountExpireOptions = [
    { value: '2', label: '2 days' },
    { value: '3', label: '3 days' },
    { value: '4', label: '4 days' },
    { value: '5', label: '5 days' },
    { value: '6', label: '6 days' },
    { value: '7', label: '7 days' },
]

interface IFormInput {
    message: string;
    discountExpire: any;
}

const GroupMessageModal =
    (props: PropsFromRedux) => {
        // TODO: upgrade v18
        const { register, handleSubmit,formState, /*errors,*/ clearErrors } = useForm<IFormInput>();
        const [discountType, setDiscountType] = useState(discountTypeOptions[0]);
        const [discountExpire, setDiscountExpire] = useState(discountExpireOptions[0]);
        const [discountValue, setDiscountValue] = useState<string>('');
        const [newFee, setNewFee] = useState('');
        const [discountErrors, setDiscountErrors] = useState<string>();
        const [isSending, setIsSending] = useState(false);
        const [isPromotionOpened, setIsPromotionOpened] = useState(false);
        const [clientsByGroups, setClientsByGroups] = useState({} as ClientsByGroupsData);
        const [isLoadingClients, setIsLoadingClients] = useState(false);
        const [clientsLoaded, setClientsLoaded] = useState(false);
        const [isRecentSelected, setRecentSelected] = useState(false);
        const [isNewSelected, setNewSelected] = useState(false);
        const [isFavoritesSelected, setFavoritesSelected] = useState(false);
        const [canShowSubmitError, setCanShowSubmitError] = useState(false);
        const [selectedClients, setSelectedClients] = useState([] as number[]);

        const originalFeePerMin = Number((props.originalFee / 60).toFixed(2));

        const onSubmit = (data: IFormInput) => {
            if (missingSubmitData()) {
                setCanShowSubmitError(true);
                return;
            }

            if (isPromotionOpened) {
                if (!discountValue) {
                    setDiscountErrors('Please add promotion value');
                    return;
                }
                if (discountErrors) {
                    return;
                }
            }

            setIsSending(true);
            props.bulkSendMessage(createSendMessageRequest(data))
        };

        const createSendMessageRequest = (data: IFormInput): MessageBulkSendRequestData => {
            let message = data.message;

            if (isPromotionOpened) {
                message += "\n\n" + buildMessageContent(Number(discountType.value), Number(discountExpire.value), discountValue);
            }

            return {
                message: message,
                recipientIds: selectedClients,
                promotionData: isPromotionOpened ? {
                    discountDurationDays: Number(discountExpire.value),
                    discountType: Number(discountType.value),
                    discountValue: Number(discountValue),
                } : undefined,
                templateData: {
                    messageType: 0, // message without template
                },
            };
        };

        const discountTypeChangeHandler = (value: any) => {
            if (value != null) {
                setDiscountValue('');
                setDiscountType(value);
                setDiscountErrors('');
                setNewFee('');
                clearErrors();
            }
        }

        const discountExpireChnageHandler = (value: any) => {
            setDiscountExpire(value);
            clearErrors();
        }

        const discountValueChangeHandler = (e: any) => {
            setNewFee('');
            if (!e.target.value) {
                setDiscountValue(e.target.value);
                setDiscountErrors('Please add promotion value');
                return;
            }

            if (discountType.value == DiscountType.Percentage) {
                const value = e.target.value.substring(0, 2);
                setDiscountValue(value);
                if (value < 1) {
                    setDiscountErrors("Value must be between 1% and 30%");
                } else if (value > 30) {
                    setDiscountErrors("Max value is up to 30%");
                } else {
                    setDiscountErrors('');
                    const newFeePerHour = props.originalFee - (props.originalFee / 100 * value);
                    const newFeeValue = (newFeePerHour / 60).toFixed(2);
                    setNewFee(newFeeValue);
                    clearErrors();
                }
            }

            if (discountType.value == DiscountType.FixedAmount) {
                const value = e.target.value.substring(0, 5);
                setDiscountValue(value);
                if (value < 1.99 || value > originalFeePerMin) {
                    setDiscountErrors(`Value can only be between $1.99 and $${originalFeePerMin} `);
                } else {
                    setDiscountErrors('');
                    const newFeeValue = Number(value).toFixed(2)
                    setNewFee(newFeeValue);
                    clearErrors();
                }
            }
        }

        const buildCommonPart = () => {
            let text = content.promotionMessages.group;
            return text;
        }

        const insertUniqueClients = (clients: number[], insert: number[]) => {
            for (var clientId of insert) {
                if (clients.indexOf(clientId) === -1) {
                    clients.push(clientId);
                }
            }
        }

        const calculateClients = () => {
            let clients: number[] = [];

            if (isRecentSelected) {
                insertUniqueClients(clients, clientsByGroups.recentClientsIds);
            }

            if (isNewSelected) {
                insertUniqueClients(clients, clientsByGroups.newClientsIds);
            }

            if (isFavoritesSelected) {
                insertUniqueClients(clients, clientsByGroups.favoriteClientsIds);
            }

            if (isPromotionOpened && hasClientsWithPromotion()) {
                for (var clientId of clientsByGroups.promotionClientsIds) {
                    var removeIndex = clients.indexOf(clientId);
                    if (removeIndex > -1) {
                        clients.splice(removeIndex, 1);
                    }
                }
            }

            setSelectedClients(clients);
        }

        const hasRecentClients = () => clientsByGroups.recentClientsIds?.length > 0;
        const hasNewClients = () => clientsByGroups.newClientsIds?.length > 0;
        const hasFavoritesClients = () => clientsByGroups.favoriteClientsIds?.length > 0;
        const hasClientsWithPromotion = () => clientsByGroups.promotionClientsIds?.length > 0;

        const toggleRecent = () => {
            if (hasRecentClients()) {
                setRecentSelected(!isRecentSelected);
            }
        }

        const toggleNew = () => {
            if (hasNewClients()) {
                setNewSelected(!isNewSelected);
            }
        }

        const toggleFavorites = () => {
            if (hasFavoritesClients()) {
                setFavoritesSelected(!isFavoritesSelected);
            }
        }

        const buildMessageContent = (discountType: number, discountExpire: number, discountValue: string) => {
            let text = discountType == 0 ? content.promotionMessages.percentage : content.promotionMessages.fixed;
            text = text.replace("[advisor name]", props.expertName);
            text = text.replace("[expire]", discountExpire.toString());
            text = text.replace("[value]", discountValue);

            return text;
        }

        const missingSubmitData = () => !selectedClients?.length || (isPromotionOpened && ((!!discountErrors) || !discountValue));
        const anyGroupChosen = () => isFavoritesSelected || isNewSelected || isRecentSelected;

        useEffect(() => {
            if (!isLoadingClients && !clientsLoaded) {
                setIsLoadingClients(true);
                props.loadClientsByGroups();
            }
        }, [isLoadingClients, clientsLoaded]);

        useEffect(() => {
            if (props.groupMessage.isMessageSent) {
                props.groupMessage.isMessageSent = false;
                props.closeModal(true);
                props.groupMessageShowConfirm({ totalClients: selectedClients?.length });
            }
        }, [props.groupMessage.isMessageSent])

        useEffect(() => {
            if (isLoadingClients) {
                setClientsByGroups(props.groupMessage.clientsByGroups);
                setClientsLoaded(true);
                setIsLoadingClients(false);
            }
        }, [props.groupMessage.clientsByGroups]);

        useEffect(() => {
            calculateClients();
        }, [isRecentSelected, isNewSelected, isFavoritesSelected, isPromotionOpened]);

        return <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content group-message-modal"  >
            <div className="close" onClick={() => props.closeModal()}></div>
            <h1>Send a group message</h1>
            <div className="title-description">Compose your message and send it to a chosen group of clients</div>
            <div className="available-groups-container">
                <div className="available-groups">
                    <div>Select from available groups</div>
                    <div className="actions">
                        <button type="button" onClick={toggleRecent} disabled={!hasRecentClients()} className={`${isRecentSelected ? "group-selected" : ""}`}>
                            Recent Contacts</button>
                        <button type="button" onClick={toggleNew} disabled={!hasNewClients()} className={`${isNewSelected ? "group-selected" : ""}`}>
                            New Clients</button>
                        <button type="button" onClick={toggleFavorites} disabled={!hasFavoritesClients()} className={`${isFavoritesSelected ? "group-selected" : ""}`}>
                            Favorites</button>
                        {
                            selectedClients?.length > 0 && <div className="total-clients">Total clients:&nbsp;<span className="amount-display">{selectedClients?.length}</span></div>
                        }
                    </div>
                </div>
                {
                    isFavoritesSelected &&
                    <div className="sixty-days-note">Please note that the message will only be sent to users who had a reading with you in the past 60 days.</div>
                }
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    isPromotionOpened &&
                    <>
                        <div className="promotion-settings">
                            <div className="discount-type-block">
                                <label htmlFor="discount-type">Promotion type:</label>
                                <Select
                                    classNamePrefix="discount-type"
                                    name="discountType"
                                    onChange={discountTypeChangeHandler}
                                    options={discountTypeOptions}
                                    inputId="discount-type"
                                    value={discountType}
                                />
                            </div>

                            <div className="discount-value-block">
                                <label htmlFor="discount-value">Promotion value:</label>

                                {discountType.value == DiscountType.Percentage && <div className="percentage">
                                    <input type="number"
                                        onChange={discountValueChangeHandler}
                                        value={discountValue}
                                        min={1}
                                        max={30}
                                        id="discount-value"
                                        name="discountValue"
                                        step="1"
                                        className={discountErrors ? 'error' : ''}
                                    ></input>
                                </div>}

                                {discountType.value == DiscountType.FixedAmount && <div className="fixed">
                                    <input type="number"
                                        onChange={discountValueChangeHandler}
                                        value={discountValue}
                                        min={1.99}
                                        max={originalFeePerMin}
                                        id="discount-value"
                                        name="discountValue"
                                        step=".01"
                                        className={discountErrors ? 'error' : ''}
                                    ></input>
                                </div>}
                            </div>


                            <div className="discount-expire-block">
                                <label htmlFor="discount-expire">Expires in:</label>
                                <Select
                                    classNamePrefix="discount-expire"
                                    name="discountExpire"
                                    onChange={discountExpireChnageHandler}
                                    options={discountExpireOptions}
                                    inputId="discount-expire"
                                    value={discountExpire}
                                />
                            </div>

                            <div className="discount-close-block">
                                <button className="white" onClick={() => setIsPromotionOpened(false)}>
                                    <img src="/images/close.svg" />
                                </button>
                            </div>
                        </div>

                        <div className="summary">
                            {discountErrors && <div className="discount-errors">
                                {discountErrors}
                            </div>}
                            {newFee && <div><strong>Promotion value:</strong> ${newFee} instead of <span>${originalFeePerMin.toFixed(2)}</span></div>}
                            {hasClientsWithPromotion() && <div className="discount-errors">Note: This message will not be sent to clients with a pending promotion from you</div>}
                        </div>
                    </>
                }

                <div className="message-label">
                    <label htmlFor="message-message" className="message">Message </label>
                    {
                        !isPromotionOpened && <PromotionMessageButton
                            isDisabled={false}
                            isActive={false}
                            onClick={() => setIsPromotionOpened(true)}
                        />
                    }
                </div>

                <div className="input-wrapper">
                    <textarea placeholder="Type your message"
                        className={formState.errors.message ? 'error' : ''}
                        // TODO: upgrade v18
                        // ref={register({
                        //     required: { value: true, message: "Please add a message to your client." },
                        //     maxLength: 3000,
                        //     minLength: { value: 20, message: "Your message must include at least 20 characters" }
                        // })}
                        id="message-message"
                        defaultValue={buildCommonPart()}
                        name="message" ></textarea>
                    {
                        isPromotionOpened &&
                        <div className="readonly-part">
                            {buildMessageContent(Number(discountType.value), Number(discountExpire.value), discountValue)}
                        </div>
                    }
                </div>

                {
                     formState.errors.message?.message &&
                    <div className="error-message">{formState.errors.message?.message}</div>
                }
                {
                    canShowSubmitError && !anyGroupChosen() &&
                    <div className="error-message">You must choose at least one group of clients</div>
                }

                <button type="submit" className={`submit primary ${missingSubmitData() ? "disabled" : ""}`}>
                    {isSending ? <Spinner size={10} /> : 'Send Message'}
                </button>

            </form>
        </ReactModal>;
    };

const mapState = (state: AppState) => ({
    groupMessage: groupMessageSelector(state),
    originalFee: state.user.userFee,
    expertName: state.user.userName
});

const connector = connect(mapState, {
    closeModal,
    groupMessageShowConfirm,
    loadClientsByGroups,
    bulkSendMessage,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GroupMessageModal);