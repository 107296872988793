import * as React from "react";
import { kasambaFormatDate } from "../../helpers/dateFormatHelper";
import Spinner from "./Spinner";
import "./TranscriptHeader.scss";

export interface ITranscriptHeaderProps {
  startedAt: Date | null | undefined;
  closeHandler: () => void;
  isloading: boolean;
}

export default function TranscriptHeader(props: ITranscriptHeaderProps) {
  return (
    <div className="transcript-header" onClick={props.closeHandler}>
      <img src="/images/arrow-left.svg" alt="exit" />
      {props.isloading && (
        <div className="put-to-center">
          <Spinner size={20} />
        </div>
      )}
      {!props.isloading && (
        <>
          <span className="transcript-date">
            {kasambaFormatDate(props.startedAt)}
          </span>
        </>
      )}
    </div>
  );
}
