import { AccountState } from "../../../types/userTypes";
import { CurrentUserResponse } from "../userApi";

const advisorModes = (advisor: Advisor) => {
  const modes = [];
  if (advisor.is_available) {
    modes.push("available");
  }
  if (advisor.available_for_standard_since !== null) {
    modes.push("standard");
  }
  if (advisor.rush_delivery_expires_at !== null) {
    modes.push("rush");
  }
  if (advisor.live_chat_mode_until !== null) {
    modes.push("chat");
  }
  if (advisor.live_video_mode_until !== null) {
    modes.push("video");
  }
  if (advisor.bitwine_chat_mode) {
    modes.push("bitwine");
  }
  if (advisor.voice_call_mode_until !== null) {
    modes.push("voice");
  }

  return modes;
};

export const mapToCurrentUserResponse = ({
  advisor,
}: MyAdvisorResponse): CurrentUserResponse => {
  return {
    id: advisor.user_attributes.id,
    email: advisor.user_attributes.email,
    name: advisor.user_attributes.nickname,
    avatarUrl: advisor.profile_picture_url,
    fee: +advisor.chat_price_per_minute,
    advisorBalanceUrl: advisor.user_attributes.advisor_balance_url,
    isAvailable: advisor.is_available,
    chatModeUntil: advisor.live_chat_mode_until,
    state: advisor.state,
    isBusy: advisor.busy_status,
    //mixpanel
    advisorId: advisor.id,
    advisorModes: advisorModes(advisor),
    creditBalance: +advisor.user_attributes.client_available_credit, //?
    currentPromotion: null,
    emailAddress: advisor.user_attributes.email,
    gender: advisor.user_attributes.gender,
    language: advisor.language_code,
    lastOrderDate: advisor.user_attributes.analytics["last order date"],
    lastPurchaseDate: advisor.user_attributes.last_purchase_date,
    signupDate: advisor.user_attributes.created_at,
    ordersCount: advisor.orders_count,
    purchasesCount: advisor.user_attributes.purchases_count,
    totalPurchaseFeesPaid: advisor.user_attributes.total_purchase_fees_paid,
    totalPurchased: +advisor.user_attributes.total_purchased,
    score: +advisor.score,
    /*
 day
day of week
days since last purchase
Had Persisted Distinct Id

hour of day
month
platform
signup date





user type
    
    */

    // ignore for now
    lastViewedOnboardingTooltip: 6,
    lastViewedTutorialSlide: 5,
    groupMessagesTooltipViewed: false,
    shownGroupMessages: false,
    isAutoChatMessageEnabled: false,
    managerOfVipAccounts: false,
    showOnboardingTooltips: false,
    showReleaseNotesMark: false,
    antiForgeryToken: "",
    autoChatMessageText: "",
    fontSize: null,

    ringing: null,
    volume: null,
  };
};

// Generated by https://quicktype.io

export interface MyAdvisorResponse {
  advisor: Advisor;
}

export interface Advisor {
  is_available: boolean;
  id: number;
  bitwine_username: string;
  happiness: string;
  credentials: null;
  average_response_seconds: number;
  missed_orders_count: number;
  bitwine_code: null;
  ontime_delivered_count: number;
  video_os: null;
  ssn: null;
  ein: null;
  foreign_tax_id_number: null;
  reference_number: null;
  mailing_address: null;
  mailing_city: null;
  mailing_country_code: null;
  live_video_mode_until: string | null;
  allow_live_mode: boolean;
  allow_chat_mode: boolean;
  live_chat_mode_until: string | null;
  share_video_to_youtube: boolean;
  available_for_standard_since: string | null;
  voted_most_accurate: boolean;
  allow_voice_call_mode: boolean;
  voice_call_mode_until: string | null;
  tryouts_enabled: boolean;
  is_available_updated_at: string;
  show_tides_info: boolean;
  force_busy_until: null;
  default_price: string;
  profile_color: string;
  voice_call_mode_on: boolean;
  readings_count: number;
  year_joined: number;
  live_video_mode_on: boolean;
  live_chat_mode_on: boolean;
  category_ids: number[];
  total_earned: string;
  last_fulfilled_order_date: string;
  busy_status: boolean;
  bitwine_integration_completed: boolean;
  bitwine_cents_per_minute: string;
  bitwine_currency: string;
  bitwine_url: string;
  bitwine_chat_mode: boolean;
  name: string;
  rush_delivery_expires_at: string | null;
  is_fast_responder: boolean;
  profile_picture_url: string;
  avatar_updated_at: string;
  regular_order_hours: number;
  min_reviews_number_for_displaying_rating: number;
  max_client_video_length: number;
  min_text_length: number;
  score: string;
  selective_availability: boolean;
  href: string;
  slug: string;
  voice_only_mode: null;
  accuracy_reviewers_count: number;
  accuracy_percent: number;
  likes_count: number;
  dislikes_count: number;
  feedbacks: Feedback[];
  ontime_deliveries_percent: string;
  price_per_minute: string;
  chat_price_per_minute: string;
  voice_call_price_per_minute: string;
  current_chat_price_per_minute: string;
  pg_chat_full_price: string;
  original_chat_price_per_minute: string;
  current_vc_price_per_minute: string;
  pg_vc_full_price: string;
  original_vc_price_per_minute: string;
  current_voice_call_price_per_minute: string;
  pg_voice_call_full_price: string;
  original_voice_call_full_price: string;
  pricing_options: PricingOption[];
  po_offline_full_base_price: string;
  po_offline_full_rush_price: string;
  current_price: string;
  current_price_expires_at: string;
  rush_price: string;
  can_follow_up: boolean;
  rush_price_strikeout: null;
  favorite: boolean;
  service_title: string;
  service_description: string;
  instructions: string;
  about_me: string;
  profile_video_thumb_url: string;
  profile_video_url: string;
  language_code: string;
  voice_call_link: string;
  voice_call_link_type: string;
  chat_call_link: string;
  chat_call_link_type: string;
  advisor_share_link: string;
  rush_delivery_disabled_until: string;
  pending_queue_full: boolean;
  user_attributes: UserAttributes;
  user_phone_number: string;
  user_full_name: string;
  photo_id_editable: boolean;
  profiles: Profile[];
  video_call_commission: number;
  pg_video_call_commission: number;
  voice_call_commission: number;
  chat_commission: number;
  pg_ppm_discount_percent: number;
  pg_voice_call_ppm_discount_percent: number;
  pg_chat_ppm_discount_percent: number;
  state: AccountState;
  paypal_email: string;
  rush_commission: string;
  advisor_rush_extra_compensation: string;
  orders_count: number;
  rush_delivery_orders_count: number;
  available_for_pt: boolean;
  pt_state: string;
  pt_orders_fulfilled: number;
  max_sales_price_per_minute: string;
  max_sales_voice_call_price_per_minute: string;
  max_sales_chat_price_per_minute: string;
  badge_counters: BadgeCounters;
}

export interface BadgeCounters {
  unread_messages_count_total: number;
}

export interface Feedback {
  like: boolean;
  comment: null | string;
  created_at: string;
  buyer_nickname: string;
  order_id: number;
}

export interface PricingOption {
  duration: number;
  price: number;
  default: boolean;
  additional: boolean;
  tryout: boolean;
  most_popular: boolean;
}

export interface Profile {
  service_title: string;
  service_description: string;
  instructions: null;
  about_me: string;
  profile_video_thumb_url: string;
  profile_video_url: string;
  language_code: string;
  is_primary: boolean;
}

export interface UserAttributes {
  id: number;
  email: string;
  created_at: string;
  facebook_id: null;
  full_name: string;
  phone_number: string;
  session_token: string;
  nickname: string;
  country: string;
  state: null;
  city: string;
  zip: string;
  date_of_birth: string;
  address: string;
  push_token: string;
  country_code: string;
  gender: string;
  mixpanel_alias_created: boolean;
  android_push_token: string;
  use_av_capture_session_vr: boolean;
  in_eu: boolean;
  web_push_token: null;
  disabled_direct_messages: null;
  fcm_push_token: string;
  avatar_url: string;
  login_type: string;
  total_purchased: number;
  last_purchase_date: string;
  purchases_count: number;
  last_submitted_order_date: string;
  is_advisor: boolean;
  is_advisor_submitted: boolean;
  force_user_mode: boolean;
  advisor_state: string;
  current_cashback_percent: number;
  sift_user_id: string;
  full_zodiac_sign: null;
  client_available_credit: string;
  client_locked_credit: string;
  advisor_balance_url: string;
  first_order: boolean;
  notifications_enabled: null;
  allowed_to_become_advisor: null;
  af_status: string;
  install_date: string;
  number_of_orders: number;
  number_of_vc_orders: number;
  number_of_voice_call_orders: number;
  number_of_chat_orders: null;
  upload_logs: boolean;
  eligible_for_cashback: boolean;
  show_enter_coupon_option: boolean;
  show_credit_option_in_side_menu: null;
  paypal_email: string;
  iterable_profile_created: boolean;
  pinpoint_profile_created: boolean;
  unread_direct_messages_count: number;
  test_group: null;
  xfm_program: null;
  zodiac: null;
  settings: Settings;
  analytics: Analytics;
  enable_tryout_eligible_session_mp_event: boolean;
  purplegarden_id: number;
  should_send_purchase_analytics: number;
  invite_url: string;
  invite_message: string;
  total_purchase_fees_paid: number;
  badge_counters: BadgeCounters;
}

export interface Analytics {
  "last order date": string;
}

export interface Settings {
  show_payment_method_menu_item: boolean;
  use_wallet: boolean;
}

export interface ConnectionConfig {
  firebase_auth_token: string;
}