import * as React from "react";
import "./Paging.css";

export interface IPagingProps {
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  nextPage: () => void;
  prevPage: () => void;
  gotoPage: (pageNum: number) => void;
  rowsPerPageChanged: (rowsPerPage: number) => void;
}

export default class Paging extends React.PureComponent<IPagingProps> {
  gotoPage(event: React.ChangeEvent<HTMLSelectElement>) {
    this.props.gotoPage(parseInt(event.target.value, 10));
  }
  rowsPerPageChaged(event: React.ChangeEvent<HTMLSelectElement>) {
    this.props.rowsPerPageChanged(parseInt(event.target.value, 10));
  }

  gotoFirstPage() {
    if (this.props.currentPage !== 1) {
      this.props.gotoPage(1);
    }
  }

  gotoLastPage() {
    const lastPage = Math.ceil(this.props.totalRows / this.props.rowsPerPage);
    if (this.props.currentPage !== lastPage) {
      this.props.gotoPage(lastPage);
    }
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.props.totalRows / this.props.rowsPerPage; i++) {
      items.push(
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      );
    }
    return items;
  }

  rowsInCurrentPage() {
    const start = (this.props.currentPage - 1) * this.props.rowsPerPage + 1;
    let end = this.props.currentPage * this.props.rowsPerPage;
    if (end > this.props.totalRows) {
      end = this.props.totalRows;
    }
    return `${start}-${end}`;
  }

  public render() {
    return (
      <div className="cl-paging">
        <label className="label">Page:</label>
        <select
          className="pages-select"
          value={this.props.currentPage}
          onChange={this.gotoPage.bind(this)}
        >
          {this.createSelectItems()}
        </select>

        <span className="label"> Rows per page: </span>
        <select
          className="rows-select"
          value={this.props.rowsPerPage}
          onChange={this.rowsPerPageChaged.bind(this)}
        >
          <option value="10">10 </option>
          <option value="20">20 </option>
          <option value="30">30 </option>
        </select>
        <span className="records">
          {this.rowsInCurrentPage()} of {this.props.totalRows}
        </span>

        <i className="first" onClick={this.gotoFirstPage.bind(this)} />
        <i className="prev" onClick={this.props.prevPage} />
        <i className="next" onClick={this.props.nextPage} />
        <i className="last" onClick={this.gotoLastPage.bind(this)} />
      </div>
    );
  }
}
