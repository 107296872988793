import { connect, ConnectedProps } from "react-redux";
import { sessionReadingClose } from "../../slices/sessionSlice";
import {
  sessionReadingSelector,
  clientSelector,
} from "../../selectors/sessionSelectors";
import { userNameSelector } from "../../selectors/userSelectors";
import { AppState } from "../../store";
import TranscriptHeader from "../atoms/TranscriptHeader";
import "./SessionReadingTranscript.scss";
import TranscriptBody from "../atoms/TranscriptBody";

const SessionReadingTranscript = (props: PropsFromRedux) => {
  function closeTranscript() {
    props.sessionReadingClose();
  }

  return (
    <div className="session-transcript">
      <TranscriptHeader
        startedAt={props.sessionReadings.data?.startedAt}
        closeHandler={() => closeTranscript()}
        isloading={props.sessionReadings.isLoading}
      />
      <TranscriptBody isLoading={props.sessionReadings.isLoading}
        isError={props.sessionReadings.isError}
        clientId={props.client?.id}
        data={props.sessionReadings.data}
        clientName={props.client?.name}
        expertName={props.expertName}
      />
      {/* <div className="transcript-footer"></div> */}
    </div>
  );
};

const mapState = (state: AppState) => ({
  sessionReadings: sessionReadingSelector(state),
  client: clientSelector(state),
  expertName: userNameSelector(state),
});

const connector = connect(mapState, { sessionReadingClose });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SessionReadingTranscript);
