import _ from "lodash";
import { useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { searchTextChanged } from "../../../slices/clientListSlice";
import {
  filterPresetSelector,
  isLoadingPageDataSelector,
  searchTextSelector,
} from "../../../selectors/clientListSelector";
import { ClientListFilterPresetEnum } from "../../../services/api/clientListApi";
import { AppState } from "../../../store";
import "./SearchInput.scss";

const mapState = (state: AppState) => ({
  searchText: searchTextSelector(state),
  isLoadingPageData: isLoadingPageDataSelector(state),
  preset: filterPresetSelector(state),
});

const connector = connect(mapState, { searchTextChanged: searchTextChanged });

type PropsFromRedux = ConnectedProps<typeof connector>;

const SearchInput = (props: PropsFromRedux) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className="search-wrapper">
        <input
          ref={ref}
          disabled={
            props.isLoadingPageData &&
            props.preset !== ClientListFilterPresetEnum.Search
          }
          type="text"
          className={
            "input-search" +
            (props.searchText ? "" : " magnifier") +
            (props.isLoadingPageData &&
            props.preset === ClientListFilterPresetEnum.Search
              ? " to-top"
              : "")
          }
          placeholder="Search client nickname, name or ID"
          onChange={(e) => props.searchTextChanged(e.target.value)}
          value={props.searchText || ""}
          maxLength={100}
        />
       
        {props.searchText && (
          <img
            className="clear-icon"
            src="/images/clear-field-icon.svg"
            onClick={() => props.searchTextChanged(null)}
          ></img>
        )}
      </div>
    </>
  );
};

export default connector(SearchInput);
