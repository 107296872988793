export enum DashboardItemType {
  summary = "earning_summary",
  buttons = "action_buttons",
  services = "available_services",
  charts = "earning_charts",
}

export enum EarningSummaryTrending {
  up = "up",
  down = "down",
  identical = "identical",
  unknown = "unknown",
}

export enum ServiceType {
  offline = 'offline',
  standard = 'standard',
  rush = 'rush',
  video = 'video',
  chat = 'chat',
  voice = 'voice',
  tides = 'tides',
}

export enum ButtonType {
  crm = "crm",
  tides = "tides",
  insights = "insights",
}

export enum ServiceMode {
  standard = "standard",
  rush = "rush",
  chat = "chat",
  voice = "voice",
  video = "video",
}

export enum ServiceModeState {
  online = "online",
  offline = "offline",
}

export type GroupedOrder = {
  type: ServiceType;
  orders_count: number;
  total_amount: number;
  primary?: boolean;
};

export type EarningSummaryItemData = {
  total_amount: number;
  orders: number;
  clients: number;
  trending?: EarningSummaryTrending;
  grouped_orders?: GroupedOrder[];
};

export type EarningSummaryItem = {
  today: EarningSummaryItemData;
  last_7_days: EarningSummaryItemData;
};

export type EarningSummary = {
  type: DashboardItemType.summary;
  data: EarningSummaryItem;
};

export type ActionButtonInnerItem = {
  type: ButtonType;
};

export type ActionButtonItem = {
  type: DashboardItemType.buttons;
  data: {
    items: ActionButtonInnerItem[];
  };
};

export type AvailableServicesInnerItem = {
  mode: ServiceMode;
  mode_state: ServiceModeState;
};

export type AvailableServicesItem = {
  type: DashboardItemType.services;
  data: {
    items: AvailableServicesInnerItem[];
  };
};

export type EarningChartDateItem = {
  date: string;
  grouped_orders: GroupedOrder[];
  orders_count: number;
  total_amount: number;
};

export type EarningChart = {
  type: DashboardItemType.charts;
  data: EarningChartItem;
};

export type EarningChartItem = {
  start_date: string;
  end_date: string;
  dates: EarningChartDateItem[];
  orders_count: number;
  total_amount: number;
};

export type DashboardItem =
  | EarningSummary
  | ActionButtonItem
  | AvailableServicesItem
  | EarningChart;

export interface AdvisorDashboardDataResponse {
  items: DashboardItem[];
}
