import "./NoteEditor.scss";
import { useState, useRef, useEffect } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertFromRaw,
  DraftHandleValue,
  convertFromHTML,
} from "draft-js";
import { formatDate } from "../../helpers/dateFormatHelper";
import { stateToHTML } from "draft-js-export-html";
import { ReactSVG } from "react-svg";
import { disableNotesEditing, disableNotesRichText } from "../../config";
import { Tooltip } from "react-tooltip";

interface NoteEditorProps {
  text: string | null;
  lastUpdated: Date | null;
  onNoteChange: (text: string, plainText: string, isAdded: boolean) => void;
  onEditorOpened?: () => void;
  onTextChangedInTheEnd?: () => void;
  isImpersonated?: boolean;
}

const NoteEditor = (props: NoteEditorProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(
    props.lastUpdated || new Date()
  );
  const editor = useRef<Editor>(null);

  useEffect(() => {

    setEditorState(EditorState.moveFocusToEnd(editorState));

    if (isEditMode) {
      focusEditor();
    }
  }, [isEditMode]);

  useEffect(() => {
    let text = props.text || "";
    let state: ContentState | null = null;

    if (disableNotesRichText) {
      state = ContentState.createFromText(text);
    } else {
      try {
        const json = JSON.parse(text);
        state = convertFromRaw(json);
      } catch {
        if (/<(br).*?>|<(div|em|h1|h2|h3|h4|h5|h6|p|strong|ul).*?<\/\2>/iug.test(text)) {
          const blocksFromHTML = convertFromHTML(text);
          state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          )
        } else {
          state = ContentState.createFromText(text);
        }
      }
    }

    setEditorState(EditorState.createWithContent(state!));

    setLastUpdated(props.lastUpdated || new Date());
  }, [props.text, props.lastUpdated]);

  const focusEditor = () => {
    if (editor.current) {
      editor.current.focus();
    }
  };

  const hasText = editorState.getCurrentContent().hasText();

  const handleEdit = () => {
    if (props.isImpersonated) {
      return;
    }
    setIsEditMode(true);
    if (props.onEditorOpened) {
      props.onEditorOpened();
    }
  };

  const handleAdd = () => {
    if (!isEditMode && !hasText && !props.isImpersonated) {
      setIsEditMode(true);
      setIsAdded(true);
    }
  };

  const handleSave = () => {
    setIsEditMode(false);
    setLastUpdated(new Date());
    const contentState = editorState.getCurrentContent();
    if (!disableNotesEditing)
      props.onNoteChange(stateToHTML(contentState), editorState.getCurrentContent().getPlainText(), isAdded);
    setIsAdded(false);
  };

  const onChange = (state: EditorState) => {
    setEditorState(state);
    const content = state.getCurrentContent();
    const blockMap = content.getBlockMap();
    const lastBlockKey = blockMap.last()?.getKey();
    const lastBlockLength = blockMap.last()?.getLength();
    const currentBlockKey = state.getSelection().getFocusKey();
    const currentCaretPosition = state.getSelection().getFocusOffset();
    const isCaretInTheEnd =
      currentBlockKey === lastBlockKey &&
      currentCaretPosition === lastBlockLength;
    if (isCaretInTheEnd && props.onTextChangedInTheEnd) {
      props.onTextChangedInTheEnd();
    }
  };

  const onToggleInlineStyle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    setTimeout(focusEditor, 0);
  };

  const onBulletsClick = () => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
    setTimeout(focusEditor, 0);
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  let className = "";

  if (!isEditMode && !hasText) {
    className = "new";
  } else {
    className = isEditMode ? "editable" : "not-editable";
  }

  return (

    <>
      {!hasText && disableNotesEditing && <div className="empty-notes">
        You haven't written any notes for this client yet.
      </div>}

      {(hasText || !disableNotesEditing) &&
        <div className={"note-editor " + className} onClick={handleAdd}>
          {!hasText && !isEditMode && <div>
            Add note
            <ReactSVG className="add-note-icon" src="/images/add-note-icon.svg" />
          </div>}

          {(hasText || isEditMode) && (
            <div className="note-editor__editor-wrapper">
              <div onClick={focusEditor}>
                <Editor
                  readOnly={!isEditMode || disableNotesEditing}
                  ref={editor}
                  editorState={editorState}
                  onChange={onChange}
                  handleKeyCommand={handleKeyCommand}
                />
              </div>
              <div className="note-editor__toolbar">
                {isEditMode && !disableNotesEditing && (
                  <div>
                    {!disableNotesRichText &&
                      <>
                        <button
                          className="icon bold"
                          onClick={() => onToggleInlineStyle("BOLD")}
                        ></button>
                        <button
                          className="icon italic"
                          onClick={() => onToggleInlineStyle("ITALIC")}
                        ></button>
                        <button
                          className="icon underline"
                          onClick={() => onToggleInlineStyle("UNDERLINE")}
                        ></button>
                        <button className="icon bullets" onClick={onBulletsClick}>
                          <i></i>
                        </button>
                      </>
                    }
                  </div>
                )}
                {isEditMode && (
                  <button className="save" onClick={handleSave}>
                    {disableNotesEditing ? "Close" : "Save"}
                  </button>
                )}

                {!isEditMode && (
                  <div className="last-update">
                    Last edited: {formatDate(lastUpdated, false, true, true)}
                  </div>
                )}
                {!isEditMode && (
                  <>
                    {disableNotesEditing && <Tooltip place="top-start" id="notes-readonly" className="open-btn-tooltip" content="Notes are currently view-only in the advisor suite. To update a note, please use the app." />}
                    <button className="open" data-tooltip-id="notes-readonly" onClick={handleEdit}>
                      Open
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};

export default NoteEditor;
