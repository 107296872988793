import React from "react";
import "./ExpertInput.css";
import { FontSize } from "../../types/appSettingsTypes";
import { fontSizeClass } from "../../helpers/settingsHelper";

interface OwnProps {
  editExpertMessage: (text: string) => void;
  commitExpertMessage: () => void;
  submitFormFlag: boolean;
  resetSubmitFormFlag: () => void;
  setSubmitFormFlag: () => void;
  fontSize: FontSize;
}

class ExpertInput extends React.Component<OwnProps> {
  public textAreaRef = React.createRef<HTMLTextAreaElement>();
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      emojiIsOpen: false,
    };
  }

  componentDidUpdate() {
    if (this.props.submitFormFlag) {
      this.onFormSubmit();
      this.props.resetSubmitFormFlag();
    }
    this.setFocusOnInput();
  }

  componentDidMount = () => {
    this.setFocusOnInput();
  };

  setFocusOnInput = () => {
    this.textAreaRef.current && this.textAreaRef.current.focus();
  };

  onFormSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();
    if (!this.textAreaRef.current) return;

    this.props.commitExpertMessage();
    this.textAreaRef.current.value = '';

    this.setFocusOnInput();
  };

  emojiSelected = (emojiCode: string) => {
    this.textAreaRef.current!.value += emojiCode;
    this.props.editExpertMessage(this.textAreaRef.current!.value);
    this.setFocusOnInput();
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.props.setSubmitFormFlag();
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.editExpertMessage(event.target.value);
  };

  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <textarea
          className={
            "input-expert-message " + fontSizeClass(this.props.fontSize)
          }
          ref={this.textAreaRef}
          placeholder="Say hello to your client"
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        ></textarea>
      </form>
    );
  }
}

export default ExpertInput;
