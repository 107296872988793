import React from "react";
import { SortOrder } from "../../../services/api/clientListApi";
import "./SortingIcon.css";

interface SortingIconProps {
  sortingOrder: string;
}

export const SortingIcon: React.FunctionComponent<SortingIconProps> = props => (
    <i className={"sorting-icon" + (props.sortingOrder === SortOrder.Desc ? " sorting-desc":"")}/>
);