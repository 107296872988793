import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  SignInState as AuthorizationState,
  SignInRequestState,
  TokenType,
} from "../types/authorizationTypes";

const initialState: AuthorizationState = {
  error: null,
  state: SignInRequestState.None,
  isSignedIn: false
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: (create) => ({
    signIn: create.preparedReducer(
      (email: string, password: string, rememberMe: boolean, token: string = "", tokenType: TokenType = TokenType.None) => ({ payload: { email, password, rememberMe, token, tokenType } }),
      () => { }
    ),
    signInSetError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    signInSuccess(state) {
      state.isSignedIn = true;
    },
    signOut() { },
    signInChangeState(state, action: PayloadAction<SignInRequestState>) {
      state.state = action.payload;
    },
    forgotPassword: create.preparedReducer(
      (email: string) => ({ payload: { email } }),
      () => { }
    ),
    sendResetPassword: create.preparedReducer(
      (email: string) => ({ payload: { email } }),
      () => { }
    )
  })
});

export const { signIn, signInSetError, signInSuccess, signOut, signInChangeState, forgotPassword, sendResetPassword } = authorizationSlice.actions;
export const authorizationReducer = authorizationSlice.reducer;