import ReactModal from "react-modal";

export const UnauthorizedModal = () => (
  <ReactModal
    isOpen={true}
    overlayClassName="modal-overlay"
    className="modal-content"
  >
    <h1 style={{ marginBottom: "20px" }}>You’ve been logged out</h1>
    <div style={{ marginBottom: "20px" }}>
        Log in to your account to continue using the Advisor Suite
    </div>
    <button className="primary" onClick={() => window.location.reload()}>
        Log in
    </button>
  </ReactModal>
);
