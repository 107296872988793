import { GetScheduleMessageResponse, SessionReading } from "../services/api/messagesApi";
import { GenderType, SessionType } from "./clientListTypes";

export interface ClientReadingData {
  clientId: number;
  sessionId: number;
  sessionType: SessionType;
  sessionStartTime: Date | null;
  durationInSecond?: number | null;
  finalFee: number | null;
  rating: boolean | null;
  ratingComment: string | null;
  kasambaQueryString: string;
}

export type MessageItemData = {
  id: number;
  memberId: number;
  expertId: number;
  content: string;
  isExpertMessage: boolean;
  readDate: Date | null;
  createDate: Date;
  hasUsedPromotion: boolean;
  isOffer: boolean;
  offerDiscountId?: number;
};

export interface ClientCardData {
    clientId: number;
    clientAlias: string;
    sessionCount: number;
    lastSessionEndTime: string | null;
    isFavoriteClient: boolean;
    isMessagingAvailable: boolean;
    hasActivePromotion: boolean;
    tag: number | null;
    tier: number | null;
    isBlocked: boolean;
    isBlockProcessing:boolean;
    lastConvertedMessageId?: number;
    dobOrder: number;
    isPotential: boolean;
    avatarUrl: string | null;
    totalPaid: number;
    dob: Date | null;
    givenDob: Date | null;
    gender: GenderType;
    firstSession: Date |null;
    offerAvailable: boolean;
    offerDisabledUntil: Date | null;
}

export enum ClientSupportedFeatures {
  ImagesSharing = "image"
}

export enum ClientCardTab {
  Notes,
  LastReading,
  Messages,
}

export enum EditTitleFields {
  DOB = 1,
  Name = 2,
}
export interface ClientState {
  lastReadings: ClientReadingData[];
  nextPage: number | undefined;
  isLastReadingsLoading: boolean;
  messages: MessageItemData[];
  isMessageLoading: boolean;
  scheduledMessage: GetScheduleMessageResponse | null;
  isScheduleMessageLoading: boolean;
  selectedClient: ClientCardData | null;
  selectedCardTab: ClientCardTab | null;
  setFocusOnField: EditTitleFields | null;
  isClientLoading: boolean;
  selectedClientId: number | null;
  isTranscriptLoading: boolean;
  sessionTranscript: SessionReading | null;
  transcriptState: PopupState;
}

export enum PopupState{
  Open = 1,
  Close = 0
}