import { MessageAsset, MessageKind } from "../../../types/messageTypes";
import { SessionReading } from "../messagesApi";

export type OrderChatMessageModel = {
  user_id: number;
  is_advisor: boolean;
  created_at: string;
  time: string;
  kind: MessageKind;
  body: string | MessageAsset;
};

export type MyOrderChatMessagesResponse = {
  chat_messages: Array<OrderChatMessageModel>;
};

export type CreateAttachmentResponse = {
  caption_max_length: number;
  resize_longest_side: number;
  reduce_quality: number;
  content_type: string;
  presigned_url_key: string;
  presigned_url: string;
};

export type PostAttachmentResponse = {
  chat_asset_id: number;
};

export function mapToSessionReading(
  response: MyOrderChatMessagesResponse,
  sessionId: number
): SessionReading {
  let firstMsg =
    response.chat_messages?.length > 0 ? response.chat_messages[0] : null;

  return {
    clientId: firstMsg?.user_id || 0,
    expertId: 0,
    conversation: response.chat_messages.map((x) => ({
      owner: x.user_id,
      body: x.body,
      kind: x.kind,
    })),
    sessionId: sessionId,
    startedAt: null,
  };
}
