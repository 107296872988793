import * as React from "react";
import ReactModal from "react-modal";
import { connect, ConnectedProps } from "react-redux";
import "./BlockClientHighValuedModal.scss";
import { closeModal } from "../../slices/modalSlice";

const BlockClientHighValuedModal = ({ closeModal }: PropsFromRedux) => {
  
  return (
    <ReactModal
      isOpen={true}
      overlayClassName="modal-overlay"
      className="modal-content block-client-high-valued-modal"
    >
      <div onClick={() => closeModal()} className="noselect">
        <h2>Requested to block a high-valued client</h2>
        <div className="text-message">
          You have requested to block a high-valued client of Kasamba. This is
          to inform you that they cannot be blocked through this channel.
          <br />
          Please read{" "}
          <a
            href="https://www.kasamba.com/lp/advisor-quality-program/managing-client-relationships/"
            target="blank"
          >
            this article
          </a>{" "}
          for more information about what to do in this situation.
          <br /> Thank you for your understanding!
        </div>
      </div>
    </ReactModal>
  );
};

const connector = connect(null, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BlockClientHighValuedModal);
