import { AppState } from "../store";

export const isNewVersionSelector = (state: AppState):boolean => state.appSettings.isNewVersion;

export const isnewVersionBannerHiddenSelector = (state: AppState):boolean => state.appSettings.isnewVersionBannerHidden;

export const appSettingsSelector = (state: AppState) => state.appSettings;

export const fontsSelector = (state: AppState) => state.appSettings.fonts;

export const soundsSelector = (state: AppState) => state.appSettings.sounds;

export const dashboardEnabledSelector = (state: AppState) => state.appSettings.dashboardEnabled;

export const advisorBlockClientTierSelector = (state: AppState) => state.appSettings.advisorBlockClientTier;

export const BlockHighValuedClientHelpUrlSelector = (state: AppState) => state.appSettings.blockHighValuedClientHelpUrl;

export const inChatMediaSendImagesEnabledSelector = (state: AppState) => state.appSettings.inChatMediaSendImagesEnabled;
