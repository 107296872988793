import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { groupMessageShowConfirm, groupMessageShowForm, groupMessageTooltipViewed } from "../../../slices/groupMessageSlice";
import { groupMessageSelector } from "../../../selectors/groupMessageSelector";
import { isGroupMessageEnabledSelector } from "../../../selectors/userSelectors";
import { AppState } from "../../../store";
import { SlidingButton } from "../buttons/SlidingButton";

const mapState = (state: AppState) => ({
    groupMessage: groupMessageSelector(state),
    isGroupMessageEnabled: isGroupMessageEnabledSelector(state),
});

const ClientListGroupMessage = (props: PropsFromRedux) => {

    const clickGroupMessage = () => {
        props.groupMessageShowForm();
    };

    const clickStopPropagation = (e: any) => {
        e.stopPropagation();
    };

    const tooltipContent = () =>
        <div
            className="on-boarding-tool-tip-wrapper"
            onClick={clickStopPropagation}
        >
            <div className="on-boarding-tool-tip-title">SEND A GROUP MESSAGE</div>
            <div className="on-boarding-tool-tip-body">Compose one message and send it to a chosen group of clients</div>

            <div className="on-boarding-tool-tip-button-wrapper">
                <div
                    className="on-boarding-tool-tip-button finish"
                    onClick={() => props.groupMessageTooltipViewed()}
                >
                    <span className="on-boarding-tool-tip-button-text">GOT IT</span>
                </div>
            </div>
        </div>;

    return <>
        {
            props.isGroupMessageEnabled &&
            <SlidingButton
                showTooltip={props.groupMessage.showTooltip}
                tooltipContent={tooltipContent()}
                icon="/images/group-message-icon.svg"
                expandedWidth="185px"
                onClick={clickGroupMessage} >
                Group Message
            </SlidingButton>
        }
    </>
}

const connector = connect(mapState, {
    groupMessageTooltipViewed,
    groupMessageShowForm,
    groupMessageShowConfirm,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClientListGroupMessage);
