import { hotjar } from 'react-hotjar';
import { HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION, HOTJAR_ENABLED } from '../../config';

export const hotJarInitialize = () => {
  if (!HOTJAR_ENABLED || hotjar.initialized()) return;
  hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION);
};

export const hotJarIdentifyUser = (id: number, name: string, email: string) => {
  if (!HOTJAR_ENABLED || !hotjar.initialized()) return;
  hotjar.identify(`${id}`, { name, email });
};

export const hotJarResetUser = () => {
  if (!HOTJAR_ENABLED || !hotjar.initialized()) return;
  hotjar.identify(null, {});
};