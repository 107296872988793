import * as React from "react";
import "./CloseSign.scss";

interface CloseSignProps {
    onclick: () => void;
}
  
  const CloseSign = (props: CloseSignProps) => {
    return (
      <>
        <div className="close-sign" onClick={props.onclick.bind(this)}>
         <div className="close"></div>
        </div>
      </>
    );
  };
  
  export default CloseSign;
