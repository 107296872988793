import * as React from "react";
import "./ClientListHeader.scss";
import SearchInput from "../../molecules/ClientList/SearchInput";


export interface IClientListHeaderProps {}

export function ClientListHeader(props: IClientListHeaderProps) {
  return (
    <div className="cl-header-container">
      <div className="cl-title">Manage Clients</div>
      <div>
         <SearchInput/> 
      </div>
    </div>
  );
}
