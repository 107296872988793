import { FC, useEffect, useMemo, useState } from "react";
import "./ChartColumn.scss";

type Props = {
  amount: number;
  amountsList: number[];
  isSelected: boolean;
  onClick: () => void;
};

export enum Productivity {
  high = "high",
  low = "low",
  medium = "medium",
}

const minHeight = 33;
const maxHeight = 108;

export const ChartColumn: FC<Props> = (props) => {
  const [productivity, setProductivity] = useState(Productivity.medium);
  const [chartHeight, setChartHeight] = useState(minHeight);

  const amountsList = useMemo(() => props.amountsList, [props.amountsList]);

  useEffect(() => {
    const minValue = Math.min(...props.amountsList);
    const maxValue = Math.max(...props.amountsList);

    const calculateChartHeight = (minValue: number, maxValue: number) => {
      const proportion = (props.amount * 100) / maxValue;
      return (proportion * (maxHeight - minHeight)) / 100 + 33;
    };

    if (minValue == maxValue) {
      setProductivity(Productivity.medium);
      setChartHeight(minHeight);
    } else if (props.amount == minValue) {
      setProductivity(Productivity.low);
      setChartHeight(minHeight);
    } else if (props.amount == maxValue) {
      setProductivity(Productivity.high);
      setChartHeight(maxHeight);
    } else {
      setProductivity(Productivity.medium);
      setChartHeight(calculateChartHeight(minValue, maxValue));
    }
  }, [props.amount, props.isSelected, amountsList]);

  return (
    <div className="chart-column" onClick={props.onClick}>
      <div
        className={`chart-column-container ${productivity}${
          props.isSelected ? ` selected` : ""
        }`}
        style={{ height: chartHeight }}
      >
        <div className="chart-column-inner-container"></div>
      </div>
      <span className="chart-column-ammount">${Math.floor(props.amount)}</span>
      {props.isSelected && <img src="/images/triangle.svg"/>}
    </div>
  );
};
