import {
  ClientReadingData,
  MessageItemData,
  ClientCardData,
  PopupState,
  ClientCardTab,
} from "../types/clientTypes";
import * as ClientActions from "../slices/clientSlice";
import { put, select, takeLatest } from "redux-saga/effects";
import * as ClientApi from "../services/api/clientApi";
import * as MessageApi from "../services/api/messagesApi";
import { AppState } from "../store";
import { replace } from "redux-first-history";
import { RoutesM } from "../router";
import { setUnreadMessages } from "../slices/clientListSlice";
import * as loggly from "../services/logger";
import { RETRY_DELAY, RETRY_MAX_TRIES } from "../config";
import {
  BaseResponseStatusModel,
  changeClientBlockStatus,
} from "../services/api/clientApi";
import { SessionReading, getSessionReading } from "../services/api/messagesApi";
import {
  selectedClientSelector,
  sessionTranscriptSelector,
} from "../selectors/clientSelectors";
import { advisorBlockClientTierSelector } from "../selectors/appSettingsSelector";
import { loadNote } from "../slices/noteSlice";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { retry } from "./extensions";
import { clientTranscriptPopupState } from "../slices/clientSlice";

function* handleClientLastReadingsLoadRequest(action: ReturnType<typeof ClientActions.loadClientLastReadings>) {
  const nextPage: number | undefined = yield select(
    (state: AppState) => state.client.nextPage
  );

  const readingsResponse: ClientApi.GetLastReadingsResponse = yield retry(
    3,
    10000,
    ClientApi.getLastReadings,
    action.payload.clientId,
    nextPage
  );

  let newItems = readingsResponse.items;
  if (nextPage && nextPage > 0) {
    const clientState: ClientReadingData[] = yield select(
      (state: AppState) => state.client.lastReadings
    );
    newItems = clientState.concat(newItems);
  }

  yield put(
    ClientActions.clientLastReadingsLoaded(newItems, readingsResponse.nextPage)
  );
}

function* handleClientMessagesLoadRequest(action: ReturnType<typeof ClientActions.loadClientMessages>) {
  const response: MessageApi.GetMessagesResponse = yield retry(
    3,
    10000,
    MessageApi.getMessages,
    action.payload.clientId,
    action.payload.pageNum,
    action.payload.pageSize
  );
  let newItems = response.messages;
  if (!newItems) {
    newItems = []; // just shows as empty list in case of any error etc
    response.nextPage = undefined;
  }

  if (action.payload.pageNum) {
    const clientState: MessageItemData[] = yield select(
      (state: AppState) => state.client.messages
    );
    newItems = newItems.concat(clientState);
  }

  yield put(
    ClientActions.clientMessagesLoaded(
      newItems,
      response.nextPage
    )
  );
  yield put(setUnreadMessages(action.payload.clientId, false));
}

function* handleShowClientCard(action: ReturnType<typeof ClientActions.showClientCard>) {
  const clientId = action.payload.clientId;

  if (clientId) {
    yield put(replace(RoutesM.Clients));
    yield put(clientTranscriptPopupState(PopupState.Close)); // close transcript if opened
    let clientDetails: ClientApi.ClientCardDetailsResponse = yield retry(
      3,
      10000,
      ClientApi.getClientCardDetails,
      clientId
    );

    if (!clientDetails) {
      yield put(ClientActions.clientLoaded(null));
      return;
    }

    if (clientDetails.dobOrder >= 0 && clientDetails.dobOrder < 3) {
      yield put(loadNote(clientId));
    }
    
    yield put(
      ClientActions.clientLoaded({
        clientId: clientId,
        ...clientDetails,
        isBlockProcessing: false,
      })
    );
  }
}

function* handleBlockClient(action: ReturnType<typeof ClientActions.blockClient>) {
  let currentClient: ClientCardData | null = yield select(
    selectedClientSelector
  );

  if (!currentClient) {
    return;
  }

  let clientId = currentClient?.clientId || 0;

  mixpanelService.trackEvent(TrackEvents.ClientBlockedByAdvisor, {
    "client id": clientId,
    "client name": currentClient?.clientAlias,
    "action name": action.payload.isBlocked ? "block" : "unblock",
    "blocking location": "client card",
  });

  let advisorBlockClientTier: number = yield select(
    advisorBlockClientTierSelector
  );

  if ((currentClient?.tier || 0) <= advisorBlockClientTier) {
    yield put(ClientActions.blockClientProcessing());

    let result: ClientApi.ChangeClientBlockStatusResponseVm = {
      clientBlocked: false,
      status: BaseResponseStatusModel.Failed,
      errors: {},
    };

    try {
      result = yield retry(
        RETRY_MAX_TRIES,
        RETRY_DELAY,
        changeClientBlockStatus,
        clientId,
        action.payload.isBlocked
      );
    } catch (error: any) {
      loggly.error(error);
    }

    currentClient = yield select(selectedClientSelector);

    if (currentClient?.clientId === clientId) {
      yield put(
        ClientActions.blockClientProcessed(
          result.status === ClientApi.BaseResponseStatusModel.Success &&
            result.clientBlocked
            ? action.payload.isBlocked
            : currentClient.isBlocked
        )
      );
    }
  }
}
function* handleClientTranscriptLoad(action: ReturnType<typeof ClientActions.clientTranscriptLoad>) {
  let currentClient: ClientCardData | null = yield select(
    selectedClientSelector
  );
  mixpanelService.trackEvent(TrackEvents.TranscriptClicked, {
    "client id": currentClient?.clientId,
    "client name": currentClient?.clientAlias,
    "order id": action.payload.sessionId,
  });
  try {
    let transcriptData: SessionReading | null = yield select(
      sessionTranscriptSelector
    );

    yield put(ClientActions.clientTranscriptPopupState(PopupState.Open));

    if (
      !transcriptData ||
      transcriptData.sessionId !== action.payload.sessionId
    ) {
      yield put(ClientActions.clientTranscriptClear());
      transcriptData = yield retry(
        3,
        10000,
        getSessionReading,
        action.payload.sessionId
      );
      transcriptData!.startedAt = action.payload.sessionStartDate;
    }

    yield put(ClientActions.clientTranscriptLoaded(transcriptData));
  } catch (error) {
    yield put(ClientActions.clientTranscriptLoaded(null));
  }
}

export default function* clientRoot() {
  yield takeLatest(
    ClientActions.loadClientLastReadings.type,
    handleClientLastReadingsLoadRequest
  );
  yield takeLatest(ClientActions.loadClientMessages.type, handleClientMessagesLoadRequest);
  yield takeLatest(ClientActions.showClientCard.type, handleShowClientCard);
  yield takeLatest(ClientActions.blockClient.type, handleBlockClient);
  yield takeLatest(ClientActions.clientTranscriptLoad.type, handleClientTranscriptLoad);
}
