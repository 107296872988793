import { createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "../types/dashboardTypes";
import { ClientListFilterPresetEnum } from "../services/api/clientListApi";
import { AdvisorDashboardDataResponse } from "../services/api/models/my-advisor-dashboard";
import { InsightApiResponse } from "../services/api/models/insight";

const initialState: DashboardState = {
  dashboardData: null,
  dashboardDataLoading: true,
  insightData: null
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: (create) => ({
    gotoDashboard() {},

    dataLoading(state) {
      state.dashboardDataLoading = true;
      state.dashboardData = null;
    },
    dashboardDataLoaded: create.preparedReducer(
      (data: AdvisorDashboardDataResponse) => ({ payload: { data } }),
      (state, action) => {
        state.dashboardData = action.payload.data;
        state.dashboardDataLoading = false;
      }
    ),
    insightDataLoaded: create.preparedReducer(
      (data: InsightApiResponse) => ({ payload: { data } }),
      (state, action) => {
        state.insightData = action.payload.data.items;
        state.dashboardDataLoading = false;
      }
    ),
    viewClientListPreset: create.preparedReducer(
      (preset: ClientListFilterPresetEnum | null) => ({ payload: preset }),
      () => {}
    ),
  }),
   selectors: {
    insightDataSelector: (state) => state.insightData,
  }
});

export const { insightDataSelector } = dashboardSlice.selectors;

export const {
  gotoDashboard,
  dataLoading,
  dashboardDataLoaded,
  viewClientListPreset,
  insightDataLoaded,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
