import * as React from 'react';
import './FeePicker.css';
import { ConnectedProps, connect } from 'react-redux';
import { AppState } from '../../store';
import { isUserHiredSelector, isSessionEndedSelector } from '../../selectors/sessionSelectors';
import { decreaseFee } from '../../slices/sessionSlice';
import { useState, useRef } from 'react';


export const feePerHourToMinute = (feePerHour: number) => {
  return Number((feePerHour / 60).toFixed(2)); // round to 2 digits after dot
};

const globalMinFee = 1.99;
const globalMaxFee = 30;


const FeePicker = ({ feePerMinute, isUserHired, maxFeePerMinute, decreaseFee, isSessionEnded }: PropsFromRedux) => {

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [tempFee, setTempFee] = useState(feePerMinute.toString());
  const [error, setError] = useState<string | null>(null);
  const inputEl = useRef<HTMLInputElement>(null);


  const openPopup = () => {
    setTempFee(feePerMinute.toString());
    setIsEditorOpen(true);
    setTimeout(() => {
      if (inputEl !== null) {
        if(inputEl.current){
          inputEl.current.focus();
        }
      }
    }, 1)
  };

  const closePopup = () => {
    setError(null);
    setIsEditorOpen(false);
  };

  const updateFee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value < 0) {
      return;
    }

    setTempFee(event.target.value);
  };


  const submitFee = () => {
    let value = Number(tempFee);

    if (value > maxFeePerMinute) {
      setError("Fee can only be reduced");
    } else if (value < globalMinFee || value > globalMaxFee) {
      setError(`Value must be between ${globalMinFee} and ${globalMaxFee}`);
    } else {
      if (value !== feePerMinute) {
        decreaseFee(value * 60);
      }
      closePopup();
    }
  };

  if (!feePerMinute) {
    return null;
  }

  return <span>Rate: ${feePerMinute} /min</span>;

  // if (isUserHired || isSessionEnded) {
  //   return <div className="fee-picker">
  //     <span>Fee: ${feePerMinute} /min</span>
  //   </div>;
  // }

  if (isEditorOpen) {

    return <div className="fee-picker-popup">
      <div className="header">
        <span>Fee: ${feePerMinute} /min</span>
        <button className="icon close" onClick={closePopup}><i></i></button>
      </div>
      <div className="content">
        <div>
          <label> New fee:
            <div className="label-content">
              <span>$</span>
              <input ref={inputEl} className={error != null ? "has-error" : ''} min={0} type="number" step=".01" onChange={updateFee} value={tempFee} />
            </div>
          </label>
          <button disabled={!tempFee} className="change" onClick={submitFee}>Change</button>
        </div>
        {error != null && <div className="error">{error}</div>}
      </div>
    </div>;
  }

  return <div className="fee-picker " >
    <div className="editable" onClick={openPopup}>
      <span>Fee: ${feePerMinute} /min</span>
      <button className="icon edit"><i></i></button>
    </div>
  </div>;
};

const mapState = (state: AppState) => ({
  feePerMinute: feePerHourToMinute(state.session.feePerHour),
  maxFeePerMinute: feePerHourToMinute(state.session.maxFeePerHour),
  isUserHired: isUserHiredSelector(state),
  isSessionEnded: isSessionEndedSelector(state)
});

const connector = connect(mapState, { decreaseFee })
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FeePicker);

