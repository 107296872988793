import React from 'react';
import {
    ScrollMenu,
    VisibilityContext,
    type publicApiType,
} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ArrowButton } from '../atoms/buttons/ArrowButton';
import { DiscountCard } from '../atoms/DiscountCard';

import './DiscountHorizontalMenu.scss';

const LeftArrow = () => {
    const visibility = React.useContext<publicApiType>(VisibilityContext);
    const isFirstItemVisible = visibility.useIsVisible('first', true);
    return (
        <ArrowButton
            visible={!isFirstItemVisible}
            onClick={() => visibility.scrollPrev()}
            direction="left"
        />
    );
};

const RightArrow = () => {
    const visibility = React.useContext<publicApiType>(VisibilityContext);
    const isLastItemVisible = visibility.useIsVisible('last', false);
    return (
        <ArrowButton
            visible={!isLastItemVisible}
            onClick={() => visibility.scrollNext()}
            direction="right"
        />
    );
};

type Props = {
    options: any[];
    selectedIndex: number | null;
    onClick: (index: number) => void;
};

export const DiscountHorizontalMenu = ({ options, selectedIndex, onClick }: Props) => {
    return <div className="discount-menu">
        <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        >
            {options.map((value, index) => (
                <DiscountCard
                    key={index}
                    percent={value}
                    selected={selectedIndex === index}
                    showCheckMark={true}
                    onClick={() => onClick(index)}
                />
            ))}
        </ScrollMenu>
    </div>
}