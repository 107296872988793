import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import { closeModal } from '../../slices/modalSlice';
import { AppState } from '../../store';
import { GroupMessageConfirmationFormParams } from '../../types/groupMessageTypes';
import './GroupMessageConfirmationModal.scss';

const GroupMessageConfirmationModal =
	({
		closeModal,
		formParams,
	}: PropsFromRedux) => (
		<ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content group-message-confirm-modal"  >
			<img src="/images/group-message-confirm.svg" alt="Message Sent!" title="Message Sent!" />
			<h1>Message Sent!</h1>
			<div className="confirm-message">Your message was sent to {formParams.totalClients} clients</div>
			<button onClick={() => closeModal()} >Close</button>
		</ReactModal>
	);

const mapState = (state: AppState) => ({
	formParams: state.modal.props as GroupMessageConfirmationFormParams,
});

const connector = connect(mapState, {
	closeModal,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GroupMessageConfirmationModal);