import { CancelTokenSource } from "axios";
import { FileUploadItemStatus } from "../components/atoms/FileUploadItem";

export interface ChatState {
  prevMessages: number[];
  isClientTyping: boolean;
  realTimeMessage: string;
  nextMessageId: number;
  messages: { [key: number]: ChatMessage };
  editableMessage: number;
  submitFormFlag: boolean;
  files: { [key: string]: IFile };
}

export interface IFile {
  progress: number;
  file: File;
  status: FileUploadItemStatus;
  cancelObject: CancelTokenSource | null;
  assetId: number | null;
  aspectRatio: number;
  caption?: string;
  startAt: Date;
  duration: null | number;
}

export enum ChatMessageType {
  Client = "CLIENT",
  Expert = "USER",
  System = "SYSTEM",
}

export abstract class ChatMessage {
  constructor(text: string, assetId?: number) {
    this.createdOn = Date.now();
    this.text = text;
    this.assetId = assetId;
  }
  abstract type: ChatMessageType;
  createdOn: number;
  text: string;
  assetId?: number;
}

export class ChatClientMessage extends ChatMessage {
  constructor(text: string, assetId?: number) {
    super(text, assetId);
  }
  type = ChatMessageType.Client;
}

export class ChatExpertMessage extends ChatMessage {
  constructor(text = "", assetId?: number) {
    super(text, assetId);
  }
  type = ChatMessageType.Expert;
}

export class ChatSystemMessage extends ChatMessage {
  constructor(text: string) {
    super(text);
  }
  type = ChatMessageType.System;
}

export enum RealTimeActivityLocation {
  inMessagesView = 10,
  aboveUserInput = 30,
}
