import { createSlice } from "@reduxjs/toolkit";
import { ConnectionStatus } from "../services/kserver/hub";
import { HubState } from "../types/hubTypes";
import { ServerMessageId } from "../services/kserver/hubServerEvents";
import { HubRequestMessageId } from "../services/kserver/hubRequests";
import { ServerUserStatus } from "../types/userTypes";

const initialState: HubState = {
  connectionStatus: ConnectionStatus.Disconnected,
  kserverInited: false
};

const hubSlice = createSlice({
  name: 'hub',
  initialState,
  reducers: (create) => ({
    hubServerMessage: create.preparedReducer(
      (messageId: number, body: any, clientId: number, sessionId: string) =>
        ({ payload: { messageId, messageName: ServerMessageId[messageId], body, clientId, sessionId } }),
      () => { }
    ),
    hubRequest: create.preparedReducer(
      (messageId: HubRequestMessageId, body: { [key: string]: string } = {}, source?: number, dest?: number) =>
        ({ payload: { messageId, messageName: HubRequestMessageId[messageId], body, source, dest } }),
      () => { }
    ),
    connecting(/*state*/) {
      // state.connectionStatus = ConnectionStatus.Connecting;
    },
    connected(state) {
      state.connectionStatus = ConnectionStatus.Connected;
    },
    connectFail() { },
    reconnecting(state) {
      state.connectionStatus = ConnectionStatus.Reconnecting;
    },
    disconnected(state) {
      state.connectionStatus = ConnectionStatus.Disconnected;
    },
    loseFocus() { },
    keepAliveResponse: create.preparedReducer(
      (expertStatus: ServerUserStatus) => ({ payload: expertStatus }),
      () => { }
    ),
    connectToKServer() { },
    forceDisconnect() { },
    forceConnect() { },
    kserverInited(state) {
      state.kserverInited = true;
    }
  })
});

export const {
  hubServerMessage, hubRequest, connecting, connected,connectFail, reconnecting, disconnected, loseFocus, 
  keepAliveResponse, connectToKServer, forceDisconnect, forceConnect, kserverInited
} = hubSlice.actions;

export const hubReducer = hubSlice.reducer;