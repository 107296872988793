import "./StatusPicker.css";
import { AccountState, ServerUserStatus } from "../../types/userTypes";
import { AppState } from "../../store";
import { userStatusSelector } from "../../selectors/userSelectors";
import { connect, ConnectedProps } from "react-redux";
import useComponentVisible from "../../helpers/hooks";
import {
  accountStateSelector,
  setAvailabilityRequest,
  setAvailabilitySucceeded,
} from "../../slices/userSlice";

import { ConnectionStatus } from "../../services/kserver/hub";
import Spinner from "./Spinner";
import { showAlertModal } from "../../slices/modalSlice";
import { isInSessionSelector } from "../../selectors/sessionSelectors";

const StatusPicker = ({
  status,
  setAvailabilityRequest,
  isSetStatusRequested,
  showAlertModal,
  userState,
  isInSession,
  activeSessionClient
}: PropsFromRedux) => {
  const {
    ref,
    isComponentVisible: isPopupOpened,
    setIsComponentVisible: setIsPopupOpened,
  } = useComponentVisible(false);

  const openPopup = () => {
    if (isSetStatusRequested || isInSession) {
      return;
    }

    if (userState !== AccountState.approved) {
      showAlertModal("", "Currently, you are unable to modify your availability. Please contact our support team for further assistance.");
      return;
    }

    setIsPopupOpened(true);
  };

  const statusName = ServerUserStatus[status];
  const setAvailable = () => {
    setIsPopupOpened(false);
    if (status == ServerUserStatus.Available) {
      return;
    }
    setAvailabilityRequest(ServerUserStatus.Available);
  };

  const setAway = () => {
    setIsPopupOpened(false);
    if (status == ServerUserStatus.Away) {
      return;
    }

    setAvailabilityRequest(ServerUserStatus.Away);
  };

  if (activeSessionClient) {
    return <div className="status busy">In a reading</div>;
  }

  switch (status) {
    case ServerUserStatus.Available:
    case ServerUserStatus.Away:
      let menuClassName =
        "status-picker-popup-content " + (isPopupOpened ? "opened" : "");
      if (status == ServerUserStatus.Available) {
        menuClassName += " reverse";
      }
      return (
        <div
          className="status-wrapper"
          style={{ opacity: isSetStatusRequested ? 0.5 : 1 }}
        >
          <div
            onClick={openPopup}
            className={"status " + statusName.toLowerCase()}
          >
            {statusName}
          </div>
          {isSetStatusRequested && <Spinner size={5} />}
          <div ref={ref} className={menuClassName}>
            <div onClick={setAway} className="status away">
              Away
            </div>
            <div onClick={setAvailable} className="status available">
              Available
            </div>
          </div>
        </div>
      );
    case ServerUserStatus.Busy:
      return <div className="status busy">In a reading</div>;
    case ServerUserStatus.Suspended:
      return <div className="status suspended">Suspended</div>;
  }
};

const mapState = (state: AppState) => ({
  isUserConnected: state.hub.connectionStatus == ConnectionStatus.Connected,
  isSetStatusRequested: state.user.isSetStatusRequested,
  status: userStatusSelector(state),
  connectionStatus: state.hub.connectionStatus,
  reconnectionStatus: state.user.isReconnection,
  userState: accountStateSelector(state),
  isInSession: isInSessionSelector(state),
  activeSessionClient: state.session.activeSessionClient,
});

const connector = connect(mapState, {
  setAvailabilityRequest,
  setAvailabilitySucceeded,
  showAlertModal
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatusPicker);
