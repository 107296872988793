import { showClientCard } from "../slices/clientSlice";
import { ClientCardTab } from "../types/clientTypes";
import { AppState, store } from "../store";

export default function* urlRoot() {
  processHash();
  window.addEventListener("hashchange", function (e) {
    processHash();
  });
}

function processHash() {
  try {
    if (window.location.hash) {
      const params = window.location.hash.split("/");
      if (params.length !== 2) return;

      const clientId = Number(params[1]);

      if (!clientId) return;

      let tab: ClientCardTab;

      switch (params[0].slice(1)) {
        case "m":
          tab = ClientCardTab.Messages;
          break;
        case "r":
          tab = ClientCardTab.LastReading;
          break;
        default:
          tab = ClientCardTab.Notes;
          break;
      }

      store.dispatch(showClientCard(clientId, tab));
      window.history.pushState(null, "", window.location.href.split("#")[0]);
    }
  } catch (e) {
    console.error(e);
  }
}
