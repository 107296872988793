import { FC } from "react";
import { InsightResponseItem } from "../../../services/api/models/insight";
import "./SliderCard.scss";
import { Card } from "../../atoms/Card";

export const SliderCard: FC<InsightResponseItem> = (props) => {
  if (!props.data?.items) {
    return <></>;
  }

  const item = props.data.items[0];

  return (
    <Card className="slide-card">
      <div className="slide-card-title">{props.data.title}</div>

      <div className="slider-container">
        <div className="line">
          <div className="expert-text">{item.advisor_average_value}</div>
          <div className="thumb-expert"></div>
          {item.global_average_value && (
            <>
              <div className={`thumb-average ${item.global_position}`}></div>
              <div className={`average-text ${item.global_position}`}>
                <div className="text-container">
                  <span className="amount">{item.global_average_value}</span>
                  <span className="subtitle-average">
                    {item.average_value_text}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
