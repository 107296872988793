import React, { RefObject } from "react";
import "./TypingArea.scss";
import ExpertAvatar from "../atoms/ExpertAvatar";
import ExpertInput from "./ExpertInput";
import { endSession } from "../../slices/sessionSlice";
import {
  sessionIdSelector,
  isSessionEndedSelector,
  isClientImagesSharingSupportedSelector,
} from "../../selectors/sessionSelectors";
import {
  submitFormFlagSelector,
  filesSelector,
} from "../../selectors/chatSelectors";
import { AppState } from "../../store";
import {
  userStatusSelector,
  userAvatarUrlSelector,
} from "../../selectors/userSelectors";
import { connect, ConnectedProps } from "react-redux";
import {
  commitExpertMessage,
  editExpertMessage,
  fileRemove,
  fileSelected,
  fileWrongSelected,
  resetSubmitFormFlag,
  setSubmitFormFlag,
  startTyping,
  stopTyping,
} from "../../slices/chatSlice";
import EmojiIcon from "../atoms/EmojiIcon";
import EmojiPicker from "./EmojiPicker";
import { fontsSelector } from "../../selectors/appSettingsSelector";
import FileUploadIcon from "../atoms/FileUploadIcon";
import {
  KASAMBA_ATTACH_FILES_LINK,
  MAXIMUM_NUMBER_OF_FILES_IN_ONE_MESSAGE,
  MAXIMUM_SIZE_IN_BYTES_OF_THE_UPLOADED_FILE,
} from "../../config";
import { FileInput } from "../atoms/FileInput";
import { showAlertModal } from "../../slices/modalSlice";
import { Tooltip } from "react-tooltip";
import CloseSign from "../atoms/CloseSign";
import { FileUploadItemStatus } from "../atoms/FileUploadItem";
import CircularProgress from "../atoms/CircularProgress";
import { inChatMediaSendImagesEnabledSelector } from "../../selectors/appSettingsSelector";

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: AppState) => ({
  sessionId: sessionIdSelector(state),
  expertAvatar: userAvatarUrlSelector(state),
  isSessionEnded: isSessionEndedSelector(state),
  isClientImagesSharingSupported: isClientImagesSharingSupportedSelector(state),
  userStatus: userStatusSelector(state),
  submitFormFlag: submitFormFlagSelector(state),
  fontSettings: fontsSelector(state),
  files: filesSelector(state),
  inChatMediaSendImagesEnabled: inChatMediaSendImagesEnabledSelector(state),
});

const connector = connect(mapStateToProps, {
  startTyping: startTyping,
  stopTyping: stopTyping,
  commitExpertMessage: commitExpertMessage,
  editExpertMessage: editExpertMessage,
  endSession: endSession,
  resetSubmitFormFlag: resetSubmitFormFlag,
  setSubmitFormFlag: setSubmitFormFlag,
  fileSelected,
  showAlertModal,
  fileRemove,
  fileWrongSelected,
});

interface State {
  emojiIsOpen: boolean;
  imgSrc: string;
  fileName: string;
  typing: boolean;
  typingTimeout?: NodeJS.Timeout;
}
class TypingArea extends React.Component<PropsFromRedux, State> {
  fileInput: RefObject<FileInput>;
  expertInput: RefObject<ExpertInput>;

  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      emojiIsOpen: false,
      imgSrc: "",
      fileName: "",
      typing: false,
    };
    this.fileInput = React.createRef();
    this.expertInput = React.createRef();
  }
  componentDidUpdate(prevProps: PropsFromRedux) {
    if (prevProps.submitFormFlag) {
      this.setState(() => ({
        emojiIsOpen: false,
      }));
    }
    let files = Object.keys(this.props.files);
    if (files.length === 0 && this.state.fileName) {
      this.setState(() => ({
        imgSrc: "",
        fileName: "",
      }));
    }
  }

  toggleEmoji = () => {
    this.setState(({ emojiIsOpen }) => ({
      emojiIsOpen: !emojiIsOpen,
    }));
  };

  openfileDialog = () => {
    if (
      this.props.isClientImagesSharingSupported &&
      this.fileInput.current &&
      !this.state.fileName
    ) {
      this.fileInput.current.openDialog();
    }
  };

  opentAttachFiles() {
    const url = KASAMBA_ATTACH_FILES_LINK.replace(
      "{sessionId}",
      this.props.sessionId
    );
    window.open(url, "_blank", "noopener,noreferrer");
  }

  onFileAdds(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;

    var selectedCount = Object.keys(this.props.files).length;

    if (files) {
      const arrFiles = Array.from(files);

      if (
        files.length + selectedCount <=
        MAXIMUM_NUMBER_OF_FILES_IN_ONE_MESSAGE
      ) {
        let errorBySize = "";
        let wrongExt = false;

        const permitExt = ["JPG", "JPEG", "PNG", "GIF"];

        arrFiles.map((file, index) => {
          const fileExt = file.name.split(".").pop()?.toUpperCase();

          if (permitExt.findIndex((value) => fileExt === value) === -1) {
            wrongExt = true;
          } else {
            if (file.size > MAXIMUM_SIZE_IN_BYTES_OF_THE_UPLOADED_FILE) {
              errorBySize += (errorBySize ? ", " : "") + file.name;
            } else {
              // Assuming only image
              const file1 = files[0];

              var reader = new FileReader();
              var url = reader.readAsDataURL(file1);

              const self = this;
              reader.onloadend = function () {
                self.setState({
                  imgSrc: URL.createObjectURL(file),
                  fileName: file.name,
                });
              }.bind(this);

              this.props.fileSelected(file);
            }
          }
        });

        if (errorBySize || wrongExt) {
          this.props.fileWrongSelected(files[0], errorBySize ? "size" : "type");
          const msg = `Maximum file size is 5mb. Supported types: JPG, JPEG, PNG, GIF.`;
          this.props.showAlertModal("Unable to Upload File", msg, "GOT IT");
        }

        if (this.fileInput.current) {
          this.fileInput.current.reset();
        }
      } else {
        const msg = `You can upload only ${MAXIMUM_NUMBER_OF_FILES_IN_ONE_MESSAGE} files per message`;
        this.props.showAlertModal("Alert", msg, "GOT IT");
      }
    }
  }

  removeFile() {
    this.props.fileRemove(this.state.fileName);
    this.setState(() => ({
      imgSrc: "",
      fileName: "",
    }));
  }

  onEmojiSelected(emojiUnicode: any) {
    this.expertInput.current?.emojiSelected(emojiUnicode.native);
  }

  handleEditExpertMessage(text: string) {
    this.props.editExpertMessage(text);

    if (!this.state.typing) {
      this.setState({
        typing: true,
      });
      this.props.startTyping();
    }

    clearTimeout(this.state.typingTimeout);
    this.setState({
      typingTimeout: setTimeout(() => {
        this.props.stopTyping();
        this.setState({
          typing: false,
        });
      }, 2000),
    });
  }

  render() {
    const { emojiIsOpen } = this.state;
    return (
      <>
        <div className="typing-area">
          <div className="avatar-container">
            <ExpertAvatar expertAvatarUrl={this.props.expertAvatar} />
          </div>
          {this.state.imgSrc && (
            <div className="preview-container">
              <div className="close-container">
                <CloseSign onclick={() => this.removeFile()} />
              </div>
              <div className="preview-img-container">
                {this.state.fileName &&
                  this.props.files[this.state.fileName]?.status ===
                    FileUploadItemStatus.Uploading && (
                    <CircularProgress
                      size={25}
                      strokeWidth={3}
                      percentage={
                        this.props.files[this.state.fileName].progress
                      }
                      color="#892788"
                      fontSize={0}
                      background="White"
                    />
                  )}

                <img
                  className={
                    "preview " +
                    (this.state.fileName &&
                    this.props.files[this.state.fileName]?.status ===
                      FileUploadItemStatus.Uploading
                      ? " loading"
                      : "")
                  }
                  src={this.state.imgSrc}
                />
              </div>
            </div>
          )}

          <div className="input-container">
            <ExpertInput
              ref={this.expertInput}
              editExpertMessage={this.handleEditExpertMessage.bind(this)}
              commitExpertMessage={this.props.commitExpertMessage}
              setSubmitFormFlag={this.props.setSubmitFormFlag}
              submitFormFlag={this.props.submitFormFlag}
              resetSubmitFormFlag={this.props.resetSubmitFormFlag}
              fontSize={this.props.fontSettings.size}
            />
          </div>
          <div
            className={
              "emoji-container " + (emojiIsOpen ? "open-emoji" : "close-emoji")
            }
          >
            <EmojiIcon onclick={this.toggleEmoji} />
          </div>

          {this.props.inChatMediaSendImagesEnabled && (
            <div
              className={
                "clip-container " +
                (this.props.isClientImagesSharingSupported
                  ? ""
                  : "icon-disabled")
              }
              data-tooltip-content={
                this.state.fileName
                  ? "You can upload only one image at a time. "
                  : this.props.isClientImagesSharingSupported
                  ? "Attach a file"
                  : "Your client device temporarily can't receive files."
              }
              data-tooltip-id={"filtertooltipid_fileattach"}
              data-multiline="true"
            >
              <FileUploadIcon
                onclick={this.openfileDialog}
                enabled={
                  !this.state.fileName &&
                  (this.props.isClientImagesSharingSupported ?? false)
                }
              />
              <FileInput
                ref={this.fileInput}
                onFilesAdded={this.onFileAdds.bind(this)}
              />
            </div>
          )}

          <div className="emoji-picker-container">
            <EmojiPicker
              onSelect={this.onEmojiSelected.bind(this)}
              isOpen={emojiIsOpen}
              width="346px"
            />
          </div>
        </div>
        <Tooltip id={"filtertooltipid_fileattach"} />
      </>
    );
  }
}

export default connector(TypingArea);
