import "./Session.scss";
import Chat from "../components/organisms/Chat";
import SessionTopBar from "../components/molecules/SessionTopBar";
import ClientDetail from "../components/molecules/ClientDetail/ClientDetail";
import SessionNotes from "../components/molecules/SessionNotes";
import { useEffect, useRef } from "react";
import SessionLastReadings from "../components/molecules/SessionLastReadings";
import { AppState } from "../store";
import { isSessionReadingShowSelector, sessionIdSelector } from "../selectors/sessionSelectors";
import { connect, ConnectedProps } from "react-redux";
import SessionReadingTranscript from "../components/molecules/SessionReadingTranscript";
import NetworkIndication from "../components/atoms/NetworkIndication";
import { changeAppStateAPI } from "../services/api/sessionApi";

const Session = (props: PropsFromRedux) => {
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const sessionNotesRef = useRef<HTMLDivElement>(null);
  const handleNoteEditorOpened = () => {
    setTimeout(() => scrollToBottom(), 0);
  };

  const scrollToBottom = () => {
    if (leftColumnRef.current) {
      if (sessionNotesRef.current?.offsetTop) {
        leftColumnRef.current.scrollTop = sessionNotesRef.current.offsetTop;
      }
    }
  };

  useEffect(() => {
    const { onunload } = window;

    window.onunload = () => {
      changeAppStateAPI(props.sessionId, 'closed_window');
    };

    return () => {
      window.onunload = onunload;
    };
  }, [props.sessionId]);

  return (
    <div className="session-page">
      <div className="session-page__left-column">
        <div className="session-page__left-column_wrapper" ref={leftColumnRef}>
          <div hidden={props.isSessionReadingShow}>
            <div className="client-detail-container">
              <ClientDetail />
            </div>
            <div ref={sessionNotesRef}>
              <SessionNotes
                onNoteEditorOpened={handleNoteEditorOpened}
              />
            </div>
            <SessionLastReadings />
          </div>
          {props.isSessionReadingShow && <SessionReadingTranscript />}
        </div>
      </div>
      <div className="session-page__right-column">
        <SessionTopBar />
        <NetworkIndication />
        <Chat />
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  isSessionReadingShow: isSessionReadingShowSelector(state),
  sessionId: sessionIdSelector(state),
});

const connector = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Session);
