import { call, put, takeEvery } from "redux-saga/effects";
import { showModal } from "../slices/modalSlice";
import { setGroupMessageTooltipViewed } from "../services/api/userApi";
import { ModalType } from "../types/modalTypes";
import { groupMessageShowConfirm, groupMessageShowForm, groupMessageTooltipViewed } from "../slices/groupMessageSlice";

function* handleGroupMessageTooltipViewed() {
    yield call(setGroupMessageTooltipViewed);
}

function* handleShowGroupMessageForm() {
    yield put(showModal(ModalType.GroupMessage));
}

function* handleShowGroupMessageConfirm(action: ReturnType<typeof groupMessageShowConfirm>) {
    yield put(showModal(ModalType.GroupMessageConfirm, action.payload))
}

export default function* root() {
    yield takeEvery(groupMessageTooltipViewed.type, handleGroupMessageTooltipViewed);
    yield takeEvery(groupMessageShowForm.type, handleShowGroupMessageForm);
    yield takeEvery(groupMessageShowConfirm.type, handleShowGroupMessageConfirm);
}
