export enum ServerMessageId {
    Connected = 4000,
    KeepAliveResponse = 1025,
    LoginOk = 1227,
    LoginFailed = 1228,
    StatusSetsToAvailableToChat = 1234,
    StatusSetsToNotAvailable = 1235,
    ClientAskToStartSession = 2024,
    SessionStarted = 2029,
    SessionEnded = 2028,
    EnableChat = 2033,
    InitSessionFee = 1085,
    ModifyFeeResponse = 2081,
    ShowRealTimeActivityText = 2041,
    IncomingMessage = 1084,
    UserHired = 1087,
    StartSuspension = 2037,
    ContinueSuspension = 2038,
    OpenWebDialog = 2071,
    ShowDynamicMsgBox = 2072,
    KM_SET_STATUS_RESPONSE = 2053 // occurred after session was closed by the client
}

export interface ServerMessageBaseData {
    messageId: ServerMessageId;
    body: any;
    clientId: number;
    sessionId: string;
}