export interface InsightApiResponse {
    items: InsightResponseItem[];
}

export interface InsightResponseItem {
    type:         string;
    data:         Data;
    tooltip_key?: string;
}

export interface Data {
    title?:          string;
    items?:          DataItem[];
    type?:           string;
    left?:           string;
    right?:          string;
    current_rating?: number;
}

export interface DataItem {
    global_average_value?: string;
    advisor_average_value: string;
    average_value_text?:   string;
    global_position?:      string;
    type?:                 string;
}

export enum InsightType {
    Slider="slider",
    Comparison="comparison",
    Rating="rating"
}

export enum GlobalPosition {
    Right="right",
    Left="left",
}
