import { createSlice } from "@reduxjs/toolkit";
import { RevenueState } from "../types/revenueTypes";

const initialState: RevenueState = {
};

const dashboardSlice = createSlice({
  name: 'revenue',
  initialState,
  reducers: (create) => ({

    gotoRevenue: create.preparedReducer(
      () => ({payload: null}),
      () => {}
    )
  })
});

export const { 
  gotoRevenue
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;