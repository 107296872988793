import { delay, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  setIsNewVersion,
  showNewVersionBanner,
  reloadApp
} from "../slices/appSettingsSlice";
import { setAvailabilityRequest } from "../slices/userSlice";
import { APP_VERSION, DELAY_BETWEEN_NEW_VERSION_CHECKS } from "../config";
import {
  isnewVersionBannerHiddenSelector,
  isNewVersionSelector,
} from "../selectors/appSettingsSelector";
import { userStatusSelector } from "../selectors/userSelectors";
import { getAppVersionOnServer } from "../services/api/appSettingsApi";
import { ServerUserStatus } from "../types/userTypes";
import { Response } from "../services/api/api";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { retry } from "./extensions";

function* getServerVersion() {
  while (true) {
    const userStatus: ServerUserStatus = yield select(userStatusSelector);
    if (userStatus !== ServerUserStatus.Busy) {
      const isNewVersion:boolean = yield select(isNewVersionSelector);
      if (!isNewVersion) {
        try {
          const version:Response<string> = yield retry(3, 10000, getAppVersionOnServer);

          if (version.result > APP_VERSION) {
            yield put(setIsNewVersion(true));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        const isBannerHidden:boolean = yield select(isnewVersionBannerHiddenSelector);
        if (isBannerHidden) {
          yield put(showNewVersionBanner());
        }
      }
    }
    yield delay(DELAY_BETWEEN_NEW_VERSION_CHECKS);
  }
}

function* handlerReloadApp() {
  
  const userStatus: ServerUserStatus = yield select(userStatusSelector);

  if (userStatus !== ServerUserStatus.Away) {
    mixpanelService.trackEvent(TrackEvents.BecameUnavailable);
    mixpanelService.trackEvent(TrackEvents.ChatBecameUnavailable);

    yield setAvailabilityRequest(ServerUserStatus.Away);
  }
  
  yield window.location.reload();
}

export default function* root() {
  //yield fork(getServerVersion);
  yield takeEvery(reloadApp.type, handlerReloadApp);
}
