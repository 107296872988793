import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../store";
import { clientListSelector } from "../../../selectors/clientListSelector";
import { isManagerOfVIPAccountsSelector } from "../../../selectors/userSelectors";
import "./ClientListFilterPreset.css";
import {
  filterPresetWasChanged,
  openClientList,
} from "../../../slices/clientListSlice";
import { ClientListFilterPresetEnum } from "../../../services/api/clientListApi";
import OnBoardingToolTip from "../OnBoardingToolTip";

const mapState = (state: AppState) => ({
  clientList: clientListSelector(state),
  isManagerOfVIPAccounts: isManagerOfVIPAccountsSelector(state),
});

const connector = connect(mapState, {
  filterPresetWasChanged: filterPresetWasChanged,
  openClientList: openClientList,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

class ClientListFilterPreset extends React.Component<PropsFromRedux> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.props.openClientList();
  }

  markActive(filterPreset: ClientListFilterPresetEnum) {
    return this.props.clientList.filterPreset == filterPreset ? "active" : "";
  }
  public render() {
    return (
      <div className="vertical-menu">
        {this.props.isManagerOfVIPAccounts && (
          <>
            <OnBoardingToolTip
              title="FILTER YOUR CLIENT LISTS"
              body="Find a specific client and track your work using our predefined categories."
              step={2}
              placement="right-start"
            >
              <a
                href="#"
                className={this.markActive(
                  ClientListFilterPresetEnum.VIPClients
                )}
                onClick={() =>
                  this.props.filterPresetWasChanged(
                    ClientListFilterPresetEnum.VIPClients
                  )
                }
              >
                VIP CLIENTS
              </a>
            </OnBoardingToolTip>
          </>
        )}
        {!this.props.isManagerOfVIPAccounts && (
          <>
            <OnBoardingToolTip
              title="FILTER YOUR CLIENT LISTS"
              body="Find a specific client and track your work using our predefined categories."
              step={2}
              placement="right-start"
            >
              <a
                href="#"
                className={this.markActive(
                  ClientListFilterPresetEnum.RecentlyContacted
                )}
                onClick={() =>
                  this.props.filterPresetWasChanged(
                    ClientListFilterPresetEnum.RecentlyContacted
                  )
                }
              >
                RECENT CONTACTS
              </a>
            </OnBoardingToolTip>
            <a
              href="#"
              className={this.markActive(ClientListFilterPresetEnum.Favorite)}
              onClick={() =>
                this.props.filterPresetWasChanged(
                  ClientListFilterPresetEnum.Favorite
                )
              }
            >
              FAVORITES
            </a>
            {/* temporary removed IM-110 <OnBoardingToolTip
              title="NEW! POTENTIAL CLIENTS"
              body="Reach out to high potential clients who showed an interest in you and invite them for a reading. The list is updated daily."
              step={6}
              placement="right-end"
            >
            <a
              href="#"
              className={this.markActive(ClientListFilterPresetEnum.PotentialClients)}
              onClick={() =>
                this.props.filterPresetWasChanged(
                  ClientListFilterPresetEnum.PotentialClients
                )
              }
            >
              POTENTIAL CLIENTS
            </a>
            </OnBoardingToolTip> */}

            <a
              href="#"
              className={this.markActive(ClientListFilterPresetEnum.Everyone)}
              onClick={() =>
                this.props.filterPresetWasChanged(
                  ClientListFilterPresetEnum.Everyone
                )
              }
            >
              ALL CLIENTS
            </a>
            <a
              href="#"
              className={this.markActive(ClientListFilterPresetEnum.Past)}
              onClick={() =>
                this.props.filterPresetWasChanged(
                  ClientListFilterPresetEnum.Past
                )
              }
            >
              PAST CLIENTS
            </a>
            <a
              href="#"
              className={this.markActive(ClientListFilterPresetEnum.New)}
              onClick={() =>
                this.props.filterPresetWasChanged(
                  ClientListFilterPresetEnum.New
                )
              }
            >
              NEW CLIENTS
            </a>
            {/* removed by https://ingenio.atlassian.net/browse/IM-151
             <a
              href="#"
              className={this.markActive(ClientListFilterPresetEnum.Blocked)}
              onClick={() =>
                this.props.filterPresetWasChanged(
                  ClientListFilterPresetEnum.Blocked
                )
              }
            >
              BLOCKED CLIENTS
            </a> */}
          </>
        )}
      </div>
    );
  }
}

export default connector(ClientListFilterPreset);
