import * as React from "react";
import { ClientListHeader } from "../components/atoms/ClientList/ClientListHeader";
import "./ClientList.css";

import { connect, ConnectedProps } from "react-redux";
import ClientListFilterPreset from "../components/atoms/ClientList/ClientListFilterPreset";
import ClientListGroupMessage from "../components/atoms/ClientList/ClientListGroupMessage";
import Spinner from "../components/atoms/Spinner";
import ClientListTable from "../components/organisms/ClientListTable";
import PotentialClientsTable from "../components/organisms/PotentialClientsTable";
import { clientListSelector } from "../selectors/clientListSelector";
import { ClientListFilterPresetEnum } from "../services/api/clientListApi";
import { AppState } from "../store";
import { wemBannerSelector } from "../slices/appSettingsSlice";
import { useEffect, useState } from "react";


const mapState = (state: AppState) => ({
    clientList: clientListSelector(state),
    wemBanner: wemBannerSelector(state)

});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ClientListProps extends PropsFromRedux {
}

const ClientList = (props: ClientListProps) => {
    const [canUseGroupMessageFeature, setCanUseGroupMessageFeature] = useState(false);

    useEffect(() => {
        setCanUseGroupMessageFeature(
            props.clientList.filterPreset === ClientListFilterPresetEnum.RecentlyContacted ||
            props.clientList.filterPreset === ClientListFilterPresetEnum.New ||
            props.clientList.filterPreset === ClientListFilterPresetEnum.Favorite);
    }, [props.clientList.filterPreset])

    const handleBannerClick = () => {
        if (props.wemBanner?.banner_link) {
            window.open(props.wemBanner.banner_link, '_blank');
        }
    };

    return (
        <>
            {props.wemBanner?.url && <div className="wem-banner-container" >
                <div className="wem-banner-image-container" onClick={handleBannerClick} style={{ cursor: props.wemBanner.banner_link ? 'pointer' : 'default'}}>
                <img 
                    src={props.wemBanner.url} 
                    alt="WEM Banner" 
                    className="wem-banner-image"
                />
                </div>
            </div>
            }
            <ClientListHeader />
            {
                canUseGroupMessageFeature && <ClientListGroupMessage />
            }
            <div className="client-list-continer">
                <div className="filter-preset">
                    <ClientListFilterPreset />
                </div>
                <div className="result">

                    {props.clientList.filterPreset !== ClientListFilterPresetEnum.PotentialClients && <ClientListTable />}
                    {/* temporary removed IM-110 {props.clientList.filterPreset === ClientListFilterPresetEnum.PotentialClients && <PotentialClientsTable />} */}


                    <div className="cl-cover-result" hidden={!props.clientList.isLoadingPageData} >
                        <Spinner size={40} />
                    </div>
                </div>
            </div>
        </>
    )
};

export default connector(ClientList);

