import emojiRegex from "emoji-regex";

const findEmojis = (str: string) => {
  const regex = emojiRegex();
  return str.match(regex);
};

const getEmojiInTail = (str: string): string | null => {
  const emojis = findEmojis(str);
  if (!emojis) return null;
  const lastEmoji = emojis[emojis.length - 1];
  const isEmojiInTail = str.slice(-1 * lastEmoji.length) === lastEmoji;
  return isEmojiInTail ? lastEmoji : null;
};

export const calculateNumOfCharsToDelete = (str: string): number => {
  const emojiInTail = getEmojiInTail(str);
  return emojiInTail ? emojiInTail.length : 1;
};

export const BrokenEmojis: { [id:string]: boolean }={
  '1F971':true, // yawning face
  '1F90F':true,  // pinching hand
  '1F9BE':true,  // Mechanical Arm=> code =>1F9BE
  '1F9BF':true,  // Mechanical Leg
  '1F9BB':true,  // Ear with Hearing Aid
  '1F9D1-200D-1F9B1':true,  // Curly Haired Person
  '1F9D1-200D-1F9B3':true,  // White Haired Person
  '1F9D1-200D-1F9B2':true,  // Bald Person
  '1F9D1-200D-1F9B0':true,  // Red Haired Person
  '263A-FE0F':true,  // White Smiling Face
  
  '1F9CF':true,  // Deaf Person
  '1F9CF-200D-2642-FE0F':true,  // Deaf Man
  '1F9CF-200D-2640-FE0F':true,  // Deaf Woman
  '1F9D1-200D-2696-FE0F':true,  // Judge
  '1F9D1-200D-1F3A4':true,  // Singer
  '1F9D1-200D-1F527':true,  // Mechanic

  '1F9CD':true,  // Standing Person
  '1F9CD-200D-2642-FE0F':true,  // Man Standing
  '1F9CD-200D-2640-FE0F':true,  // Woman Standing
  '1F9CE':true,  // Kneeling Person
  '1F9CE-200D-2642-FE0F':true,  // Man Kneeling
  '1F9CE-200D-2640-FE0F':true,  // Woman Kneeling
  '1F9D1-200D-1F9AF':true,  // Person with Probing Cane
  '1F468-200D-1F9AF':true,  // Man with Probing Cane
  
  '1F469-200D-1F9AF':true,  // Woman with Probing Cane
  '1F9D1-200D-1F9BC':true,  // Person in Motorized Wheelchair 
  '1F468-200D-1F9BC':true,  // Man in Motorized Wheelchair
  '1F469-200D-1F9BC':true,  // Woman in Motorized Wheelchair
  '1F9D1-200D-1F9BD':true,  // Person in Manual Wheelchair
  '1F468-200D-1F9BD':true,  // Man in Manual Wheelchair
  '1F469-200D-1F9BD':true,  // Woman in Manual Wheelchair
  '1F90E':true,  // Brown Heart
  '1F90D':true, // White Heart
  '1F9A7':true, // Orangutan
  '1F9AE':true, // Guide Dog
  '1F415-200D-1F9BA':true, // Service Dog
  '1F9A5':true, // Sloth
  '1F9A6':true, // Otter
  '1F9A8':true, // Skunk
  '1F9A9':true, // Flamingo
  '1F9C4':true, // Garlic
  '1F9C5':true, // Onion
  '1F9C7':true, // Waffle
  '1F9C6':true, // Falafel
  '1F9C8':true, // Butter
  '1F9AA':true, // Oyster
  '1F9C3':true, // Beverage Box
  '1F9C9':true, // Mate Drink
  '1F9CA':true, // Ice Cube
  '1FA80':true, // Yo-Yo
  '1F93F':true, // Diving Mask
  '1FA81':true, // Kite
  
  '1F9D1-200D-1F91D-200D-1F9D1':true, // People Holding Hands
  '1F46D':true, // Two Women Holding Hands
  '1F46B':true, // Man and Woman Holding Hands
  '1F46C':true, // Two Men Holding Hands
  '1F9D1-200D-2695-FE0F':true, // Health Worker
  '1F9D1-200D-1F393':true, // Student
  '1F9D1-200D-1F3EB':true, // Teacher
  '1F9D1-200D-1F33E':true, // Farmer
  '1F9D1-200D-1F373':true, // Cook
  '1F9D1-200D-1F3ED':true, // Factory Worker
  '1F9D1-200D-1F4BC':true, // Office Worker
  '1F9D1-200D-1F52C':true, // Scientist
  '1F9D1-200D-1F4BB':true, // Technologist
  '1F9D1-200D-1F3A8':true, // Artist
  '1F9D1-200D-2708-FE0F':true, // Pilot
  '1F9D1-200D-1F680':true, // Astronaut
  '1F9D1-200D-1F692':true, // Firefighter

};