import * as React from 'react';
import ReactModal from 'react-modal';
import './BlacklistAlertModal.scss';
export const BlacklistAlertModal =
    () => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content blacklist-alert-modal"  >
            <h1>Error</h1>
            <div>There was a problem sending your message. Please contact Customer Service</div>
            <button className="primary" onClick={() => window.location.reload()}>RELOAD</button>
        </ReactModal>
    );