import {
    call, delay, put, select, takeEvery,
    takeLatest
} from "redux-saga/effects";
import * as clientActions from "../slices/clientSlice";
import {
    markAsScheduled,
    newJobMessage,
    refreshPage
} from "../slices/clientListSlice";
import {
    bulkMessageSent,
    bulkSendMessage,
    loadMessageTemplates,
    loadScheduleMessage,
    messageTemplatesLoaded,
    newJobMessageNotificationReceive,
    scheduledMessageSent,
    scheduleMessageLoaded,
    sendMessage,
    sendScheduledMessage,
    showScheduledMessageForm
} from "../actions/messageActions";
import { closeModal, showAlertModal, showModal } from "../slices/modalSlice";
import * as SessionActions from "../slices/sessionSlice";
import { setScheduledMessageLink } from "../slices/sessionSlice";
import { diffMinutes } from "../helpers/dateFormatHelper";
import { filterPresetSelector } from "../selectors/clientListSelector";
import { selectedClientSelector } from "../selectors/clientSelectors";
import { userStatusSelector } from "../selectors/userSelectors";
import * as clientApi from "../services/api/clientApi";
import { ClientListFilterPresetEnum } from "../services/api/clientListApi";
import * as messageApi from "../services/api/messagesApi";
import {
    BatchMessageResponse,
    BatchMessageResponseStatus,
    SendMessageResponse
} from "../services/api/messagesApi";
import { createNotification } from "../services/desktopNotificationService";
import * as loggly from "../services/logger";
import { AppState, store } from "../store";
import { ClientCardData, ClientCardTab, MessageItemData } from "../types/clientTypes";
import {
    MessageTemplateData,
} from "../types/messageTypes";
import { ModalType } from "../types/modalTypes";
import { ServerUserStatus } from "../types/userTypes";
import { retry } from "./extensions";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { Type } from "../services/api/models/MyActivityApiResponse";
import { setCoupons } from "../slices/userSlice";

// const allowedURLs = ["https://www.kasamba.com"];
// const Scheduled_Message_Sent = "Scheduled_Message_Sent";

// const isValidOrigin = (origin: string): boolean => {
//   return allowedURLs.some(url => url.includes(origin));
// };

// const getEventOrigin = (event: MessageEvent): string => {
//     const origin = event.origin || (event as any).originalEvent.origin;
//     return origin;
//   };

//   const readAction = (event: MessageEvent) => {
//     try {
//       return JSON.parse(event.data);
//     } catch (ex) {
//       return { type: "" };
//     }
//   };

// function handlePostMessages() {
//     return eventChannel(emit => {
//       const postMessageToAction = (event: MessageEvent) => {
//         const origin = getEventOrigin(event);
//         if (!isValidOrigin(origin)) return;
//         const action = readAction(event);

//         switch (action.type) {
//           case Scheduled_Message_Sent:
//             emit(scheduledMessageSent(action.clientId));
//             break;
//           default:
//             break;
//         }
//       };
//       window.addEventListener("message", postMessageToAction, false);
//       return () => {
//         window.removeEventListener("message", postMessageToAction, false);
//       };
//     });
//   }

function* handleShowScheduledMessageForm(action: ReturnType<typeof showScheduledMessageForm>) {
    const params = action.payload;
    // if opened from session summary, need to get recalculated client type from the server
    if (params.clientType === undefined) {
        yield put(SessionActions.sessionSetIsClientLifecyleLoading(true));
        yield delay(2000); // let server successful  end the session
        try {
            const actualLifecycle: number | null = yield call(
                clientApi.getClientLifecycle,
                params.clientId
            );
            params.clientType = actualLifecycle;
        } finally {
            yield put(SessionActions.sessionSetIsClientLifecyleLoading(false));
        }
    }

    loggly.log(`Open scheduled message form for client ${params.clientId}`);
    yield put(showModal(ModalType.ScheduledMessage, params));
}

function* handleMessageSend(action: ReturnType<typeof sendMessage>) {
    yield put(scheduleMessageLoaded(null));

    const result: SendMessageResponse = yield call(
        messageApi.sendMessage,
        action.payload.clientId,
        action.payload.content,
        action.payload.couponId
    );

    if (result.status === 0) {
        var selectedClient: ClientCardData | null = yield select(
            selectedClientSelector
        );
        if (selectedClient?.isPotential) {
            yield put(clientActions.setMessagingUnavailable());
            yield put(refreshPage());
        }

        const messages: MessageItemData[] = yield select((state: AppState) => state.client.messages);
        const lastMessageId = messages.length ? messages[messages.length - 1].id : 0;
        const message: MessageItemData = {
            id: lastMessageId + 1,
            memberId: action.payload.clientId,
            expertId: 0,
            content: action.payload.content,
            isExpertMessage: true,
            hasUsedPromotion: false,
            createDate: new Date(result.activity_item?.created_at || ""),
            readDate: null,
            isOffer: result.activity_item?.type === Type.Coupon,
            offerDiscountId: result.activity_item?.type === Type.Coupon ? result.activity_item.data.discount_coupon_id : undefined
          };

        yield put(clientActions.addClientMessage(message));
        yield put(closeModal());
        return;
    }

    yield put(
        showAlertModal(
            "",
            result.errors
        )
    );
    if (result.status === 422) {
        if (action.payload.couponId)
            yield put(clientActions.setOfferUnavailable(action.payload.clientId));
        else
            yield put(clientActions.setMessagingUnavailable());
    }

    if (result.discount_coupons) {
        yield put(setCoupons(result.discount_coupons));
    }
}

function* handleMessageBulkSend(action: ReturnType<typeof bulkSendMessage>) {
    yield put(scheduleMessageLoaded(null));

    const response: BatchMessageResponse = yield call(
        messageApi.bulkSendMessage,
        action.payload
    );

    switch (response.status) {
        case BatchMessageResponseStatus.blacklisted:
            yield put(showModal(ModalType.BlacklistAlert));
            break;
        case BatchMessageResponseStatus.success:
        default:
            yield put(bulkMessageSent());
            break;
    }
}

function* handleSendScheduledMessage(action: ReturnType<typeof sendScheduledMessage>) {
    const result: messageApi.SendScheduledMessageResponse = yield call(
        messageApi.sendScheduledMessage,
        action.payload.clientId,
        action.payload.text,
        action.payload.delay,
        action.payload.extra
    );
    if (result && result.status === 0) {
        loggly.log(`Scheduled message was sent`);
        yield put(closeModal());
        const scheduledDate = new Date(
            new Date().getTime() + action.payload.delay * 60000
        );
        yield put(
            scheduledMessageSent(
                action.payload.clientId,
                new Date().toJSON(),
                scheduledDate.toJSON()
            )
        );
    } else {
        loggly.warn(`Scheduled message error. Status = ${result?.status}`);
        yield put(
            showAlertModal(
                "Error",
                "It looks like there was an error and your message was not scheduled."
            )
        );
    }
}

function* handleScheduledMessageSent(action: ReturnType<typeof scheduledMessageSent>) {
    yield put(setScheduledMessageLink(null));
    const filterPreset: ClientListFilterPresetEnum = yield select(
        filterPresetSelector
    );
    if (filterPreset === ClientListFilterPresetEnum.PotentialClients) {
        yield put(refreshPage());
    } else {
        yield put(
            markAsScheduled(
                action.payload.clientId,
                action.payload.created,
                action.payload.scheduledDate
            )
        );
    }
}

function* handleScheduledMessageLoad(action: ReturnType<typeof loadScheduleMessage>) {
    const result: messageApi.GetScheduleMessageResponse = yield retry(
        3,
        10000,
        messageApi.getScheduleMessage,
        action.payload.clientId
    );
    yield put(scheduleMessageLoaded(result));
}

let messageTemplatesLastLoaded: Date;
function* handleMessageTemplatesLoad(action: ReturnType<typeof loadMessageTemplates>) {
    let result: MessageTemplateData[] = [];
    try {
        if (
            !messageTemplatesLastLoaded ||
            diffMinutes(new Date(), messageTemplatesLastLoaded) >= 30
        ) {
            result = yield retry(3, 10000, messageApi.GetMessageTemplates);
        } else {
            result = yield select((state: AppState) => state.user.messageTemplates);
        }
    } catch {
        loggly.error("Unable to load message templates");
    }
    yield put(messageTemplatesLoaded(result));
    messageTemplatesLastLoaded = new Date();
}

function* markUnreadMessages(action:ReturnType<typeof newJobMessageNotificationReceive>) {
    const userStatus: ServerUserStatus = yield select(userStatusSelector);
    if (userStatus !== ServerUserStatus.Busy) {
        if (action.payload.buyer_id) {
                createNotification(
                    `You have a new message from client "${action.payload.client_nickname} (ID ${action.payload.buyer_id})"`,
                    () => {
                        store.dispatch(
                            clientActions.showClientCard(
                                +action.payload.buyer_id,
                                ClientCardTab.Messages
                            )
                        );
                    },
                    true,
                    `incomingMessageFrom-${action.payload.buyer_id}`
                );
            
        }
    }
}

export default function* postMessageRoot() {
    yield takeEvery(sendScheduledMessage.type, handleSendScheduledMessage);
    yield takeEvery(sendMessage.type, handleMessageSend);
    yield takeEvery(bulkSendMessage.type, handleMessageBulkSend);
    yield takeEvery(showScheduledMessageForm.type, handleShowScheduledMessageForm);
    yield takeEvery(scheduledMessageSent.type, handleScheduledMessageSent);
    yield takeLatest(loadScheduleMessage.type, handleScheduledMessageLoad);
    yield takeLatest(loadMessageTemplates.type, handleMessageTemplatesLoad);
    yield takeEvery(newJobMessage.type, markUnreadMessages);

}
