import { isKasambaApp } from "../helpers/settingsHelper";

export interface ITextManager {
  login: {
    title: string;
    logoSrc: string;
    emailPlaceholder: string;
    passwordPlaceholder: string;
  };
  clientListTableHeader: {
    sessions: string;
    lastReading: string;
  };
  clientCard: {
    sessions: string;
  };
  readings: {
    firstReading: string;
    lastReading: string;
    clientReading: string;
    allReading: string;

    viewAllReadings: string;
    viewReading: string;
    recentReadings: string;
    emptyReadings: string;
    noReadings: string;
  };
}

const textManagerPurple: ITextManager = {
  login: {
    title: "Welcome to Purple Advisor Suite.",
    logoSrc: "/images/purple-ocean-logo.svg",
    emailPlaceholder: "Email on Purple Ocean",
    passwordPlaceholder: "Password on Purple Ocean",
  },
  clientListTableHeader: {
    sessions: "# ORDERS",
    lastReading: "LAST ORDER",
  },
  clientCard: {
    sessions: "Orders",
  },
  readings: {
    firstReading: "First Order with Client",
    lastReading: "Last Order: ",
    clientReading: "Client Orders",
    allReading: "All Orders",
    viewAllReadings: "View all orders",
    viewReading: "view order",
    recentReadings: "Recent Orders",
    emptyReadings: "There's no previous orders with this client",
    noReadings: "No orders",
  },
};

const textManagerKasamba: ITextManager = {
  login: {
    title: "Welcome to Kasamba Advisor Suite.",
    logoSrc: "/images/K3-logo.png",
    emailPlaceholder: "Email on Kasamba",
    passwordPlaceholder: "Password on Kasamba",
  },
  clientListTableHeader: {
    sessions: "# SESSIONS",
    lastReading: "LAST READING",
  },
  clientCard: {
    sessions: "Sessions",
  },
  readings: {
    firstReading: "First Reading with Client",
    lastReading: "Last Reading: ",
    clientReading: "Client Readings",
    allReading: "All Readings",
    viewAllReadings: "View all readings",
    viewReading: "view reading",
    recentReadings: "Recent Readings",
    emptyReadings: "There's no previous reading with this client",
    noReadings: "No readings",
  },
};

export const textManager: ITextManager = isKasambaApp
  ? textManagerKasamba
  : textManagerPurple;
