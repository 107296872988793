import { HttpStatusCode } from "axios";
import { CallEffect, SagaReturnType, call, delay } from "redux-saga/effects";

const unauthorizedResponses = [
  HttpStatusCode.Unauthorized,
  HttpStatusCode.Forbidden,
];

export function* retry<Fn extends (...args: any[]) => any>(
  maxTries: number,
  delayLength: number,
  fn: Fn,
  ...args: Parameters<Fn>
): Generator<CallEffect<SagaReturnType<Fn>>> {
  for (let i = 0; i < maxTries; i++) {
    try {
      return yield call(fn, ...args);
    } catch (e: any) {
      if (e.isAxiosError && e.response && unauthorizedResponses.includes(e.response.status)) {
        throw e;
      }

      if (i < maxTries) {
        yield delay(delayLength);
        continue;
      }
      throw e;
    }
  }
}
