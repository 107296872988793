import * as React from "react";
import "./SettingsButton.css";

export interface ISettingsButtonProps {
  settingsClick: () => void;
  disabled: boolean;
}

export const SettingsButton = (props: ISettingsButtonProps) => (
  <button disabled={props.disabled} className="settings icon" onClick={() => props.settingsClick()}>
    <i></i>
  </button>
);
