import "./ExpertStatus.css";
import StatusPicker from "../atoms/StatusPicker";
import { AppState } from "../../store";
import {
  userNameSelector,
  userAvatarUrlSelector,
  userStatusSelector,
} from "../../selectors/userSelectors";
import { connect, ConnectedProps } from "react-redux";
import StatusToFavIcon from "../atoms/StatusToFavIcon";
import { isSignedInSelector } from "../../selectors/authorizationSelector";
import { isImpersonatedSelector } from "../../slices/userSlice";

const ExpertStatus = (props: PropsFromRedux) => (
  <>
    {props.userName && (
      <div className="expert-status">
        <img alt="" src={props.userAvatarUrl} />
        <div>
          <div className="advisor-name">{props.userName}</div>
          {!props.isImpersonated && <StatusPicker />}
          <StatusToFavIcon
            status={props.status}
            isUserSignedId={props.isUserSignedId}
          />
        </div>
      </div>
    )}
  </>
);

const mapState = (state: AppState) => ({
  userName: userNameSelector(state),
  userAvatarUrl: userAvatarUrlSelector(state),
  status: userStatusSelector(state),
  isUserSignedId: isSignedInSelector(state),
  isImpersonated: isImpersonatedSelector(state),
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ExpertStatus);
