import { createSlice } from "@reduxjs/toolkit";
import { NUMBER_OF_ONBOARDING_TOOLTIPS } from "../config";
import { OnBoardingState } from "../types/onBoardingTypes";

const initialState: OnBoardingState = {
  isShowing: false,
  step: 0,
  countOfSteps: NUMBER_OF_ONBOARDING_TOOLTIPS,
  isCompleted: false,
  onlyNewShow: false,
};

const onBoardingSlice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: (create) => ({
    showFirst(state) {
      state.isShowing = true;
      state.step = 1;
    },
    showNext(state) {
      state.step = state.step + 1;
    },
    onBoardingFinish(state) {
      state.step = 0;
      state.isShowing = false;
      state.isCompleted = true;
    },
    onBoardingStart() { },
    onBoardingStartFromStep: create.preparedReducer(
      (step: number) => ({ payload: step }),
      (state, action) => {
        state.isShowing = true;
        state.step = action.payload;
        state.onlyNewShow = true;
      }
    )
  })
});

export const { showFirst, showNext, onBoardingFinish, onBoardingStart, onBoardingStartFromStep } = onBoardingSlice.actions;
export const onBoardingReducer = onBoardingSlice.reducer;