import * as React from "react";
import TopBar from "../organisms/TopBar";
import { AppState } from "../../store";
import { ConnectedProps, connect } from "react-redux";
import { isSignedInSelector } from "../../selectors/authorizationSelector";

const mainlayout = (props: PropsFromRedux) => (
  <>
    {props.isUserSignedIn && (
      <header>
        <TopBar />
      </header>
    )}
    <div className="main container">{props.own.children}</div>
  </>
);

const mapState = (state: AppState, own: LayoutProps) => ({
  isUserSignedIn: isSignedInSelector(state),
  own,
});

const connector = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connector>;
type LayoutProps = {
  children?: React.ReactNode;
};
//export default connector(TopBar);

export default connector(mainlayout);
