import * as React from "react";
import ReactModal from "react-modal";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../store";
import { closeModal } from "../../slices/modalSlice";
import { useState } from "react";
import { Loader } from "../atoms/Loader";
import "./FeedbackForm.css";

const FeedbackFormModal = (props: PropsFromRedux) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const iframeLoaded = () => {
    setIsIframeLoaded(true);
  };

  return (
    <ReactModal
      isOpen={true}
      overlayClassName="modal-overlay"
      className="modal-content feedback-form"
    >
      <div className="close" onClick={() => props.closeModal()}></div>
      <iframe
        scrolling="yes"
        style={{ display: isIframeLoaded ? "block" : "none" }}
        src={props.url}
        onLoad={iframeLoaded}
        width="630" 
        height="520"
        
        >Loading…</iframe>
      {!isIframeLoaded && <Loader />}
    </ReactModal>
  );
};

const mapState = (state: AppState) => ({
  url: state.appSettings.urlToFeedbackPage
});

const connector = connect(mapState, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FeedbackFormModal);
