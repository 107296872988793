export enum ConnectionStatus {
    Connecting = 0,
    Connected = 1,
    Reconnecting = 2,
    Disconnected = 4
}

export enum StatusChangeMode {
    Manual = 10,
    Automatic = 20
}

export enum StatusChangeReason {
    MissedChatsInARow = 1,
    SessionEnded = 2
}

export enum DeclineReason {
    Active = 100,
    Passive = 101
}

export const WORKER_SERVER_MESSAGE_RECIEVED = "hub_server_message";
export const WORKER_SERVER_REQUEST = "hub_request";
export const WORKER_CONNECTION_STATUS_CHANGED = "hub_connection_status_changed";
export const WORKER_CONNECTION_ERROR = "hub_connection_failed";

export const WORKER_CONNECTION_START = "hub_connect";
export const WORKER_CONNECTION_STOP = "hub_stop";

export const WORKER_KEEPALIVE_START = "hub_keepalive_start";
export const WORKER_KEEPALIVE_STOP = "hub_keepalive_stop";
export const WORKER_KEEPALIVE_RESET = "hub_keepalive_reset";



export class WorkerMessage {
   
    type: string;
    data: any;

    constructor(type: string, data: any = 'undefined') {
        this.type = type;
        this.data = data;
      }

}