import * as React from 'react';
import "./TopBarMenu.scss"

export interface ITopBarMenuProps {
  children?: React.ReactNode
}

export default class TopBarMenu extends React.PureComponent<ITopBarMenuProps> {
  public render() {
    return (
      <div className="top-bar-menu">
        {this.props.children}
      </div>
    );
  }
}
