import { SessionReading } from "../services/api/messagesApi";
import { AdvisorStartChatResponse } from "../services/api/userApi";
import { ClientReadingData, ClientSupportedFeatures } from "./clientTypes";

export interface CallingClient {
  id: number;
  name: string;
  category: string;
  device: string;
  lastConsulted: Date | null;
  isReturningClient: boolean;
  supportedFeatures: ClientSupportedFeatures[];
  memberSince: string | null;
  isFavorite: boolean | null;
  tryout: boolean;
  durationMinutes: number;
}

export type Client = CallingClient & AdvisorStartChatResponse;

export interface SessionSummary {
  title: string;
  large_numbers: Array<SummaryItem>;
  small_numbers: Array<SummaryItem>;
  hangup_error?: SummaryItem;
  alert?: SummaryAlert;
  total: SummaryItem;
}


export type SummaryAlert = {
  label: string;
  hint: string;
};

export type SummaryItem = {
  label: string;
  value: string;
  hightlight?: boolean;
  help_text?: string;
};

export interface SessionReadingDetail {
  isLoading: boolean;
  isShow: boolean;
  isError: boolean;
  data: SessionReading | null;
}

export enum SessionStatus {
  none = 0,
  accepted = 1,
  started = 2,
  paused = 4,
  ended = 8
}

export interface SessionState {
  sessionId: string;
  uuid: string;
  channelId: string;
  sessionSummary: SessionSummary | null;
  client?: Client | null;
  callingClient: CallingClient | null;
  ringData: AdvisorStartChatResponse | null;
  maxFeePerHour: number; // In cent
  feePerHour: number; // In cent
  sessionStartUtcTime?: number;
  userHiredUtcTime?: number; // Paid session start!
  sessionEndingUtcTime?: number;
  scheduledMessageLink: string | null;
  isMessageScheduled: boolean;
  sessionCloseReason: string | null;
  lastReadings: ClientReadingData[] | null;
  nextPage: number | undefined;
  sessionReadingData: SessionReadingDetail;
  isClientLifecycleTypeLoading: boolean;
  isTryout: boolean;
  freeDuration: number;
  paidDuration: number;
  networkStatus: NetworkStatus;
  status: SessionStatus;
  activeSessionClient: {
    id: number;
    name: string;
  } | null
}

export enum NetworkStatus {
  connected = 'connected',
  networkDown = 'down',
  unknown = 'unknown'
}