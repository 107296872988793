import { createSlice } from "@reduxjs/toolkit";
import { ClientsByGroupsData } from "../types/clientListTypes";
import { GroupMessageConfirmationFormParams, GroupMessageState } from "../types/groupMessageTypes";

const initialState: GroupMessageState = {
  showTooltip: false,
  isTooltipViewed: false,
  isMessageSent: false,
  clientsByGroups: {} as ClientsByGroupsData,
};

const groupMessageSlice = createSlice({
  name: 'groupMessage',
  initialState,
  reducers: (create) => ({
    groupMessageShowTooltip(state) {
      state.showTooltip = true;
    },
    groupMessageTooltipViewed(state) {
      state.showTooltip = false;
    },
    groupMessageShowForm(){},
    groupMessageShowConfirm: create.preparedReducer(
      (formParams: GroupMessageConfirmationFormParams) => ({payload: formParams}),
      () => {}
    ),
    loadClientsByGroups(){},
    clientsByGroupsLoaded: create.preparedReducer(
      (data: ClientsByGroupsData) => ({ payload: data }),
      (state, action) => {
        state.clientsByGroups = action.payload;
      }
    ),
    bulkMessageSent(state) {
      state.isMessageSent = true;
    }
  })
});

export const {
  groupMessageShowTooltip, groupMessageTooltipViewed, groupMessageShowForm, groupMessageShowConfirm, 
  loadClientsByGroups, clientsByGroupsLoaded, bulkMessageSent
} = groupMessageSlice.actions;

export const groupMessageReducer = groupMessageSlice.reducer;
