import { FC } from "react";
import { InsightResponseItem } from "../../../services/api/models/insight";
import "./RatingWidget.scss";
import { Card } from "../../atoms/Card";
import { Star } from "../../atoms/dashboard/Star";
import { TooltipQuestionMark } from "../../atoms/dashboard/TooltipQuestionMark";

export const RatingWidget: FC<InsightResponseItem> = (props) => {

  const calculateDefList = (rating: number, maximum = 5) => {
    let result = new Array(maximum).fill(0);
    for (let i = 1; i <= maximum; i++) {
      if (i <= rating) {
        result[i-1] = 1;
      } else {
        result[i-1] = rating % 1;
        break;
      }
    }
    return result;
  }

  return (
    <Card className="rating-widget">
      <div className="rating-widget-title">
        {props.data.title}
        <TooltipQuestionMark 
        tooltipId="dashboard-widget-tooltip" 
        text="Your current rating is based on the amount of positive and negative reviews received from your clients in the last four months" />
      </div>
      <div className="container">
        {calculateDefList(props.data.current_rating!).map((fill, index) => (<Star fill={fill} index={index} key={index} />))}
        <h1>{props.data.current_rating?.toFixed(1)}</h1>
      </div>
    </Card>
  )
};
