import React from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; // This only needs to be imported once in your app
import "./LightboxWrapper.scss";

export interface IProps {
  imageUrl: string;
  thumbnailUrl: string;
  width: number;
  thumbnailLoaded: () => void;
  caption?: string;
}

export interface IState {
  photoIndex: number;
  isOpen: boolean;
  isLoaded: boolean;
}

export default class LightboxWrapper extends React.PureComponent<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      isLoaded: false,
    };
  }

  render() {
    const { isOpen } = this.state;

    return (
      <>
        <figure>
          <img
            onLoad={() => {
              this.setState({ isLoaded: true });
              this.props.thumbnailLoaded();
            }}
            className={
              "lightbox-preview " + (this.state.isLoaded ? "" : "loading-img")
            }
            src={this.props.thumbnailUrl}
            onClick={() => this.setState({ isOpen: true })}
            style={{ maxWidth: this.props.width + "px" }}
          ></img>
          {this.props.caption && <figcaption style={{ maxWidth: this.props.width + "px" }}>{this.props.caption}</figcaption>}
        </figure>

        {isOpen && (
          <Lightbox
            mainSrc={this.props.imageUrl}
            nextSrc={""}
            prevSrc={""}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: 0,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: 0,
              })
            }
          />
        )}
      </>
    );
  }
}
