import { toUTCFormatDate } from "../../helpers/dateFormatHelper";
import api from "./api";
import axios from 'axios';
import * as loggly from '../logger';


export type ClientInfos = {
  notes : NotesResponse
  custom_dob : Date
  note_title : NotesTitle
  updated_at: string
}

export type NotesResponse = {
  title: NotesTitle;
  text: string;
  lastUpdated: Date;
};

export type NotesTitle = {
  clientName: string;
  birthDate: Date | null;
}

export function getNotes(clientId: number): Promise<NotesResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const request: Promise<any> = api.get<ClientInfos>(`/my/client_infos/${clientId}`, { cancelToken: source.token })
      .then(response => response.data)
      .catch(error => {
        loggly.error('Error fetching client info:', error);
        throw error;
      });


  return request.then(clientInfos => {

    const result: NotesResponse = {
      title: {
        birthDate: clientInfos.custom_dob ? new Date(clientInfos.custom_dob) : null,
        clientName: clientInfos.note_title,
      },
      text: clientInfos.notes || "",
      lastUpdated: new Date(clientInfos.updated_at)

    };
    return result;
  });
}

export function updateNote(clientId: number, text: string): Promise<any> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return api.put(`/my/client_infos/${clientId}`, { notes :  text }, { cancelToken: source.token });
}

export function updateClientDetails(
  clientId: number,
  clientName: string,
  birthDate: Date | null,
): Promise<any> {
  return api.put(`/my/client_infos/${clientId}`, { note_title: clientName, custom_dob: toUTCFormatDate(birthDate) });
}
