import * as React from "react";
import "./BirthdayIcon.scss";

export interface IBirthdayIconProps {
  tooltipText :string
}

export default function BirthdayIcon(props: IBirthdayIconProps) {
  return (
    <img
      src="/images/birthday-mark-icon.svg"
      className="birthday-icon"
      alt="birthday icon"
      data-tooltip-html={props.tooltipText}
      data-tooltip-id="wem-tooltip"
    />
  );
}
