import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../store";
import "./Dashboard.scss";
import { EarningsSummary } from "../components/molecules/Dashboard/EarningsSummary";
import {
  DashboardItemType,
  EarningChart,
  EarningSummary,
} from "../services/api/models/my-advisor-dashboard";
import { EarningsChart } from "../components/molecules/Dashboard/EarningsChart";
import Spinner from "../components/atoms/Spinner";
import { SliderCard } from "../components/molecules/Dashboard/SliderCard";
import { RatingWidget } from "../components/molecules/Dashboard/RatingWidget";
import { insightDataSelector } from "../slices/dashboardSlice";
import { InsightType } from "../services/api/models/insight";
import { ComparisonWidget } from "../components/molecules/Dashboard/ComparisonWidget";
import { Tooltip } from "react-tooltip";
export interface IDashboardProps {}

const mapState = (state: AppState) => ({
  summary: state.dashboard.dashboardData?.items.find(
    (x) => x.type === DashboardItemType.summary
  ) as EarningSummary,
  chart: state.dashboard.dashboardData?.items.find(
    (x) => x.type === DashboardItemType.charts
  ) as EarningChart,
  dashboardDataLoading: state.dashboard.dashboardDataLoading,
  insightData: insightDataSelector(state)
});

const connector = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

function Dashboard(props: PropsFromRedux) {



  const sliders = () => {
   
    if (props.insightData) {
      return props.insightData.filter((t)=>t.type === InsightType.Slider 
        && !t.data.title?.includes("Your average delivery time")
      ).map((x, index) => {
        return <SliderCard key={index} {...x} />
      })  
    }
    return <></>
  }

  const ratingWidget = () => {
    const insightData = props.insightData?.find(x => x.type === InsightType.Rating)
    return insightData && <RatingWidget {...insightData} />
  }

  const comparisonWidget = () => {
    const insightData = props.insightData?.find(x => x.type === InsightType.Comparison)
    return insightData && <ComparisonWidget {...insightData} />
  }

  return (
    <>
      <div className="dashboard">
        <div className="context-to-center">
          <div className="dashboard-title">Advisor Dashboard</div>
          <div className="content-wrapper">
            <div className="column">
              {props.dashboardDataLoading ? (
                <Spinner />
              ) : (
                <>
                  <EarningsSummary {...props.summary.data} />
                  <EarningsChart {...props.chart.data} />
                  <SliderCard type={""} data={{ title: "Your accuracy rating" }} />
                  {sliders() }
                  {comparisonWidget()}
                  {ratingWidget()}
                  <Tooltip id="dashboard-widget-tooltip" place="bottom" style={{"width": "400px"}} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connector(Dashboard);
