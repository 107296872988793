import { ConnectedProps, connect } from "react-redux";
import { AppState } from "../../store";

import "./NetworkIndication.scss";
import { NetworkStatus } from "../../types/sessionTypes";

const NetworkIndication: React.FC<PropsFromRedux> = ({ status }) => {
  return <div className={`network-status ${status}`}>{status === NetworkStatus.networkDown && "Network seems to be offline"}</div>;
};

const mapState = (state: AppState) => ({
  status: state.session.networkStatus,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NetworkIndication);
