import * as FullStory from "@fullstory/browser";
import { checkOpenedConnections } from "../services/api/connectionApi";
import { Response } from "../services/api/api";
import { all, call, delay, put, select } from "redux-saga/effects";
import {
  getCurrentUser,
  CurrentUserResponse,
  signIn,
  signOut,
  sendResetPassword,
  socialSignIn,
  getClientSettings,
  ClientSettingsResponse,
} from "../services/api/userApi";
import {
  accountStateChanged,
  accountStateSelector,
  clientSettingsLoaded,
  currentUserDetailsLoaded,
} from "../slices/userSlice";
import { showAlertModal, showModal } from "../slices/modalSlice";
import { ModalType } from "../types/modalTypes";
import * as loggly from "../services/logger";
import {
  AppSettingsResponse,
  getAppSettings,
} from "../services/api/appSettingsApi";
import { loadedSoundAndFont, mixpanelIdSelector, setAppSettings } from "../slices/appSettingsSlice";
import { replace } from "redux-first-history";
import { RoutesM } from "../router";
import { SignInRequestState, TokenType } from "../types/authorizationTypes";
import {
  signInSetError,
  signInSuccess,
  signInChangeState,
  forgotPassword,
  sendResetPassword as sendResetPasswordAction,
  signIn as signInAction,
} from "../slices/authorizationSlice";
import mixpanel from "../services/mixpanel";
import { LoginResponse } from "../services/api/models/loginResponse";
import { retry } from "./extensions";
import {
  hotJarInitialize,
  hotJarIdentifyUser,
  hotJarResetUser,
} from "../services/api/hotjarApi";
import { handleAccountStateChanged, verifyUserIsBusy } from "./user";
import { AccountState, UserState } from "../types/userTypes";
import { userSelector } from "../selectors/userSelectors";

const authorizationChannel = new BroadcastChannel("authorization");
authorizationChannel.addEventListener("message", () =>
  window.location.reload()
);

declare let FreshworksWidget: any;

export default function* init(): any {
  try {
    loggly.log("Application started");

    const [currentUserDetails, appSettings, clientSettings]: [
      CurrentUserResponse,
      AppSettingsResponse,
      ClientSettingsResponse
    ] = yield all([
      retry(3, 10000, getCurrentUser),
      retry(3, 10000, getAppSettings),
      retry(3, 1000, getClientSettings),
    ]);

    (window as any).antiForgeryToken = currentUserDetails.antiForgeryToken;

    yield put(setAppSettings(appSettings));
    yield put(currentUserDetailsLoaded(currentUserDetails));
    yield call(
      handleAccountStateChanged,
      accountStateChanged(currentUserDetails.state, "")
    );
    yield put(clientSettingsLoaded(clientSettings));

    yield put(
      loadedSoundAndFont(
        currentUserDetails.volume,
        currentUserDetails.fontSize,
        currentUserDetails.ringing
      )
    );

    if (FreshworksWidget) {
      yield FreshworksWidget("identify", "ticketForm", {
        name: currentUserDetails.name,
        email: currentUserDetails.email,
      });
    }

    yield FullStory.identify(currentUserDetails.id.toString(), {
      displayName: currentUserDetails.name,
      email: currentUserDetails.email,
    });

    const mixpanelId: string = yield select(mixpanelIdSelector);
    const user: UserState = yield select(userSelector);
    mixpanel.init(mixpanelId, user.userId, user.advisorId);

    mixpanel.identify(`${currentUserDetails.id}`);
    hotJarInitialize();
    hotJarIdentifyUser(currentUserDetails.id, currentUserDetails.name, currentUserDetails.email);
  } catch (e: any) {
    mixpanel.reset();
    loggly.error(e);
    yield put(showModal(ModalType.ConnectionError));
    return false;
  }

  /*
    if (Howler.ctx != null && Howler.ctx.state === 'suspended') {
        yield put(showAlertModal('Allow sound', 'You need to allow sound for chat ringer', 'Allow'));
        yield take(MODAL_CLOSE);
    }
    */
  return true;
}

export function* checkConnection() {
  // workaround to avoid "connection exists" behaviour when user refresh the page and previous connection was not removed from the server
  //yield delay(2000) ;

  let isSuccess = false;

  for (
    let checkBusyConnectionAttempts = 0;
    checkBusyConnectionAttempts < 3;
    checkBusyConnectionAttempts++
  ) {
    if (checkBusyConnectionAttempts > 0) {
      yield delay(20000);
    }

    const openedConnectionsResponse: Response<boolean> = yield retry(
      3,
      10000,
      checkOpenedConnections
    );

    if (!openedConnectionsResponse.result) {
      isSuccess = true;
      break;
    }
  }

  if (!isSuccess) {
    loggly.warn(`Could not connect. Expert is in a session`);

    yield put(
      showAlertModal(
        "We recognize you are in the middle of a session.",
        "There is no option to log in with a different tab while you are in the middle of a session.\n\n" +
          "After you finish the session you will be able to log in (this means you will be logged out of the previous one.)",
        "Got it"
      )
    );
    //yield take('EXISTED_CONNECTION_DIALOG_CONFIRM');
    return false;
  }
  return isSuccess;
}

export function* handleSignIn(action: ReturnType<typeof signInAction>) {
  try {
    yield put(signInChangeState(SignInRequestState.Requesting));
    let user: LoginResponse;
    switch (action.payload.tokenType) {
      case TokenType.None:
        user = yield call(
          signIn,
          action.payload.email,
          action.payload.password,
          action.payload.rememberMe,
        );
        break;
      case TokenType.Google:
      case TokenType.Facebook:
        user = yield call(
          socialSignIn,
          action.payload.token,
          action.payload.tokenType
        );
        break;
    }
    if (user && !user.user.is_advisor) {
      yield call(signOut);
      yield put(
        signInSetError(
          "You are not an advisor. Sorry, you can not use this application."
        )
      );
    } else {
      authorizationChannel.postMessage("signInSuccess");
      yield put(signInSuccess());
      yield put(replace(RoutesM.Init));
    }
  } catch (e: any) {
    if (e.response?.data.error) {
      yield put(signInSetError(e.response?.data.error));
    } else if (
      e.response?.data?.full_messages &&
      e.response.data.full_messages.length > 0
    ) {
      yield put(signInSetError(e.response.data.full_messages.pop()));
    }
  } finally {
    yield put(signInChangeState(SignInRequestState.Completed));
  }
}

export function* handleSignOut() {
  const accountState: AccountState = yield select(accountStateSelector);
  if (accountState === AccountState.approved) {
    const isBusy: boolean = yield call(verifyUserIsBusy);
    if (isBusy) {
      return;
    }
  }
  hotJarResetUser();
  yield call(signOut);
  authorizationChannel.postMessage("signOut");
  window.location.reload();
}

export function* handleForgotPassword(
  action: ReturnType<typeof forgotPassword>
) {
  yield put(showModal(ModalType.ForgotPassword, action.payload.email));
}

export function* handleResetPassword(
  action: ReturnType<typeof sendResetPasswordAction>
) {
  try {
    yield call(sendResetPassword, action.payload.email);
  } catch {
    // ignore
  }
}
