import { GenderType } from "../../types/clientListTypes";
import "./GenderIcon.scss";
export type GenderIconProps = {
  gender: GenderType;
};

export default function GenderIcon(props: GenderIconProps) {
  let className = "gender-icon ";
  switch (props.gender) {
    case GenderType.Female:
      className += "female";
      break;
    case GenderType.Male:
      className += "male";
      break;
    case GenderType.Other:
      className += "other";
      break;
  }
  return <i className={className}></i>;
}
