import React from "react";
import "./PulseIndicator.css"
import PulseLoader from "react-spinners/PulseLoader";

interface LoadersProps {
  
}

export const PulseIndicator: React.FunctionComponent<LoadersProps> = props => (
  
  <>
  <div className="typing-indicator">
      <div className="bubblebox">
        <span className="bubble"></span>
        <span className="bubble"></span>
        <span className="bubble"></span>
      </div>
  </div>

  </>

);