export enum SignInRequestState {
  None,
  Requesting,
  Completed,
}

export enum TokenType {
  None,
  Google,
  Facebook,
}

export interface SignInState {
  state: SignInRequestState;
  error: string | null;
  isSignedIn: boolean;
}