// Generated by https://quicktype.io

export interface MyActivityApiResponse {
    activity_items:    ActivityItem[];
    pagination_params: PaginationParams | undefined;
    advisor:           Advisor;
    buyer:             Buyer;
}

export type ActivityItem = { id: number; created_at: string } & (CouponActivityItem | MessageActivityItem | OrderActivityItem);

export interface CouponActivityItem {
    type:       Type.Coupon;
    data:       CouponData;
}

export interface CouponData {
    discount_coupon_id?: number;
    expires_at?: Date;
    message?: string;
    state: 'reached' | 'expired' | 'pending';
}

export interface MessageActivityItem {
    type:       Type.Message;
    data:       MessageData;
}

export interface MessageData {
    content?: Content;
    message_type?: string;
}

export interface OrderActivityItem {
    type:       Type.Order;
    data:       OrderData;
}

export interface OrderData {
    id:               number;
    order_type?:      string;
    total_paid?:      number;
    charge_duration:  number;
    state?:           string;
    submitted_at?:    string;
    message_kind?:    string;
    created_at?:      string;
    feedback_vote: boolean | null,
    feedback_comment: string | null
    earned?:          number;
}

export interface Content {
    message: string;
}

export enum Type {
    Message = "message",
    Order = "order",
    Coupon = "coupon"
}

export interface Advisor {
    badge_counters: BadgeCounters;
}

export interface BadgeCounters {
    unread_messages_count_total: number;
}

export interface Buyer {
    id:                    number;
    nickname:              string;
    profile_picture_url:   null;
    gender:                string;
    dob:                   string;
    reading_count:         number;
    total_spent:           number;
    messaging_status:      string;
    unread_messages_count: number;
    last_reading_at:       string;
    first_reading_at:      string;
}

export interface PaginationParams {
    page:       number;
    created_at: string;
    items_per_page: number;
    order: string;
}
