import * as React from 'react';
import { useEffect, useState } from 'react';
import "./CircularProgress.css";

export interface ICircularProgress {
    size: number;
    strokeWidth: number;
    percentage: number;
    color:string;
    fontSize:number;
    background:string ;
  }


const CircularProgress = ({ size, strokeWidth, percentage, color, fontSize, background="#0" }:ICircularProgress) => {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
      setProgress(percentage);
    }, [percentage]);
  
    const viewBox = `0 0 ${size} ${size}`;
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * Math.PI * 2;
    const dash = (progress * circumference) / 100;
  
    return (
      <svg width={size} height={size} viewBox={viewBox} className="spin-me put-to-center" >
        <circle
          fill="none"
          stroke={background} //"#ccc"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          fill="none"
          stroke={color}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          strokeDasharray={''+dash+' '+(circumference - dash)}
          strokeLinecap="round"
          style={{ transition: "all 0.5s" }}
        />
        {fontSize && <text
          fill="black"
          fontSize={fontSize+"px"}
          x="50%"
          y="50%"
          dy={fontSize/2+"px"}
          textAnchor="middle"
        >
          {`${percentage}%`}
        </text>}
      </svg>
    );
  };
  
  export default CircularProgress;
  