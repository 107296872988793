
import React from "react";
import "./SendButton.scss"

interface SendButtonProps {
  isdisabled?:boolean;
  onClick: () => void;
}

export class SendButton extends React.Component<SendButtonProps> {

  constructor(props: SendButtonProps) {
    super(props);
  }


  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event && event.preventDefault();
    if (!this.props.isdisabled)
    {
      this.props.onClick();
    }
  }

  render() {
   
    return (
      
      <>
  
        <div className={"send-button" + (this.props.isdisabled?" send-button-disabled ":"")} onClick={this.handleClick}>
        </div>
  
      </>
        
      
    );
  }  

}

