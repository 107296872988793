import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import './BlockClientConfirmModal.scss';
import { closeModal } from '../../slices/modalSlice';

import { blockClient } from '../../slices/clientSlice';

const BlockClientConfirmModal =
    ({ closeModal, blockClient }: PropsFromRedux) => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content block-client-confirm-modal"  >
            <div>
                <h2>Are you sure you want to block this client?</h2>
                <div className="actions">
                    <button className="primary" onClick={() => closeModal()}>Cancel</button>
                    <button className="primary confirm" onClick={() => { closeModal(); blockClient(true);}}>Yes, Block</button>
                </div>
            </div>
        </ReactModal>
    );


const connector = connect(null, { closeModal, blockClient });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BlockClientConfirmModal);