import React from "react";

interface IFileInputProps {
    onFilesAdded: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IFileInputState {

}

export class FileInput extends React.Component<IFileInputProps, IFileInputState> {
    fileInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IFileInputProps) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    public openDialog() {
        let current = this.fileInputRef.current;
        if(current) {
            current.click();
        }
    }

    public reset() {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.value = "";
        }
    }

    render() {

        return (
            <>
                <input
                    ref={this.fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    //multiple only one file per msg
                    accept=".jpg,.jpeg,.png,.gif"
                    
                    onChange={this.props.onFilesAdded}
                />

            </>
        );
    }
}