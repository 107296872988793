import * as React from 'react';
import './EmojiIcon.css';

interface EmojiIconProps {
    onclick:() => void; 
}

const EmojiIcon = (props:EmojiIconProps) => {
    return <i className="emoji-icon" onClick={props.onclick}/>;
};

export default EmojiIcon;