import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import './NotificationsBlockedModal.css';
import { closeModal } from '../../slices/modalSlice'

const NotificationsBlockedModal =
    ({ closeModal }: PropsFromRedux) => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content notifications-blocked-modal"  >
            <div>
                <h2>You’re missing incoming chats</h2>
                <p>While notifications are disabled, we can’t alert you whenever a client wants to chat with you. Your status on the advisor suite will be “Away”.</p>
                <a href="https://www.google.com/url?q=https://pushassist.com/knowledgebase/how-to-enable-or-disable-push-notifications-on-chrome-firefox-safari-b/&sa=D&source=hangouts&ust=1600884968364000&usg=AFQjCNFeVGrozR_jYC0p-eXnZ7cwfyST7g"
                    onClick={() => closeModal()} target="_blank">Here’s how to turn on notifications</a>
            </div>
        </ReactModal>
    );


const connector = connect(null, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationsBlockedModal);