import { FC, ReactNode, useState } from "react";
import { Card } from "../../atoms/Card";
import { SlidingSegmentedControl } from "../../atoms/SlidingSegmentedControl";
import "./EarningsSummary.scss";
import {
  EarningSummaryItem,
  EarningSummaryTrending,
} from "../../../services/api/models/my-advisor-dashboard";

import green from "../../../assets/images/green.svg";
import red from "../../../assets/images/red.svg";
import orange from "../../../assets/images/orange.svg";
import { BadgeAndText } from "../../atoms/dashboard/BadgeAndText";
import { earningItemTitleByType } from "../../../helpers/dashboardHelper";

const segments = {
  today: "Today",
  last7Days: "Last 7 days",
};

export const EarningsSummary: FC<EarningSummaryItem> = (
  props: EarningSummaryItem
) => {
  const [selectedSegment, setSelectedSegment] = useState(segments.today);
  const data =
    selectedSegment === segments.today ? props.today : props.last_7_days;

  const buildTrendingAsset = (trending?: EarningSummaryTrending): ReactNode => {
    switch (trending) {
      case EarningSummaryTrending.up:
        return <img src={green} alt="green"/>;
      case EarningSummaryTrending.down:
        return <img src={red} alt="red"/>;
      case EarningSummaryTrending.identical:
        return <img src={orange} alt="orange"/>;
      default:
        return <></>;
    }
  };

  return (
    <Card className="earnings-summary">
      <div className="earnings-summary-container">
        <SlidingSegmentedControl
          segments={Object.values(segments)}
          onSegmentChanged={setSelectedSegment}
        />
        <p className="earnings-header">My earnings</p>
        <div className="earnings-sum">
          <span className="value">${data.total_amount.toFixed(2)}</span>
          <div className="trending">{buildTrendingAsset(data.trending)}</div>
        </div>
        <div className="badges">
          <BadgeAndText
            count={data.clients}
            text={data.clients === 1 ? "Client" : "Clients"}
          />
          <BadgeAndText
            count={data.orders}
            text={data.orders === 1 ? "Order" : "Orders"}
          />
        </div>
        {data.grouped_orders && (
          <div className="service-earnings-list">
            {data.grouped_orders.map((order, i) => {
              const title = earningItemTitleByType(order.type);
              const pluralizedOrders =
                order.orders_count > 1 ? "orders" : "order";
              return (
                <div key={i} className="service-earning-item">
                  {title && (
                    <div className="service-earning-title">{title}</div>
                  )}
                  <div className="service-earning-row">
                    {order.primary && <span className="star" />}
                    <span className="amount">
                      ${order.total_amount.toFixed(2)} / {order.orders_count}{" "}
                      {pluralizedOrders}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Card>
  );
};
