import { createSlice } from "@reduxjs/toolkit";
import { ClientCardData, ClientCardTab, ClientReadingData, ClientState, EditTitleFields, MessageItemData, PopupState } from "../types/clientTypes";
import { updateClientDetails, updatedClientDetails } from './noteSlice';
import { SessionReading } from "../services/api/messagesApi";
import { setFavorite } from "./clientListSlice";
import { loadScheduleMessage, scheduleMessageLoaded, sendMessage } from "../actions/messageActions";
import { dateDiffInDays } from "../helpers/dateFormatHelper";
import { stat } from "fs";

const initialState: ClientState = {
  isLastReadingsLoading: false,
  lastReadings: [],
  nextPage: 0,
  messages: [],
  isMessageLoading: false,
  scheduledMessage: null,
  isScheduleMessageLoading: false,
  selectedClient: null,
  selectedCardTab: null,
  setFocusOnField: null,
  isClientLoading: false,
  selectedClientId: null,
  isTranscriptLoading: false,
  sessionTranscript: null,
  transcriptState: PopupState.Close,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: (create) => ({
    clientLastReadingsLoaded: create.preparedReducer(
      (readings: ClientReadingData[], nextPage: number | undefined) => ({ payload: { data: readings, nextPage } }),
      (state, action) => {
        state.isLastReadingsLoading = false;
        state.lastReadings = action.payload.data;
        state.nextPage = action.payload.nextPage;
      }
    ),
    clientMessagesLoaded: create.preparedReducer(
      (messages: MessageItemData[], nextPage: number | undefined) =>
        ({ payload: { data: messages, nextPage } }),
      (state, action) => {
        state.isMessageLoading = false;
        state.messages = action.payload.data;
        state.nextPage = action.payload.nextPage;
      }
    ),
    loadClientLastReadings: create.preparedReducer(
      (clientId: number, contToken?: number) => ({ payload: { clientId, contToken } }),
      (state) => {
        state.isLastReadingsLoading = true;
      }
    ),
    loadClientMessages: create.preparedReducer(
      (clientId: number, pageSize: number, pageNum: number) => ({ payload: { clientId, pageNum, pageSize } }),
      (state) => {
        state.isMessageLoading = true;
      }
    ),
    addClientMessage: create.preparedReducer(
      (message: MessageItemData) => ({ payload: { message } }),
      (state, action) => {
        state.messages.push(action.payload.message);
      }
    ),
    resetLastReadingsClientData(state) {
      state.lastReadings = [];
      state.nextPage = 0;
      state.isLastReadingsLoading = false;
    },
    resetMessagesClientData(state) {
      state.messages = [];
      state.isMessageLoading = false;
      state.nextPage = undefined;
      state.isScheduleMessageLoading = false;
      state.scheduledMessage = null;
    },
    clientLoaded: create.preparedReducer(
      (client: ClientCardData | null) => ({ payload: { client } }),
      (state, action) => {
        state.selectedClientId = action.payload.client?.clientId || null;
        state.isClientLoading = false;
        state.selectedClient = action.payload.client;
      }
    ),
    showClientCard: create.preparedReducer(
      (clientId: number, tab: ClientCardTab | null = null, setFocusOnField: EditTitleFields | null = null) => ({ payload: { clientId, tab, setFocusOnField } }),
      (state, action) => {
        state.isClientLoading = true;
        state.selectedClientId = action.payload.clientId;
        state.selectedCardTab = action.payload.tab;
        state.setFocusOnField = action.payload.setFocusOnField;
      }
    ),
    resetNoteTitleFocusedField(state) {
      state.setFocusOnField = null;
    },
    setHasActivePromotion: create.preparedReducer(
      (value: boolean) => ({ payload: value }),
      (state, action) => {
        if (state.selectedClient) {
          state.selectedClient.hasActivePromotion = action.payload
        }
      }
    ),
    blockClient: create.preparedReducer(
      (isBlocked: boolean) => ({ payload: { isBlocked } }),
      () => { }
    ),
    blockClientProcessing(state) {
      if (state.selectedClient) {
        state.selectedClient.isBlockProcessing = true;
      }
    },
    blockClientProcessed: create.preparedReducer(
      (isBlocked: boolean) => ({ payload: { isBlocked } }),
      (state, action) => {
        if (state.selectedClient) {
          state.selectedClient.isBlockProcessing = false;
          state.selectedClient.isBlocked = action.payload.isBlocked;
        }
      }
    ),
    setMessagingUnavailable(state) {
      if (state.selectedClient) {
        state.selectedClient.isMessagingAvailable = false;
      }
    },
    setOfferUnavailable(state, options) {
      if (state.selectedClient) {
        state.selectedClient.offerAvailable = false;
      }
    },
    clientTranscriptLoad: create.preparedReducer(
      (sessionId: number, sessionStartDate: Date | null) => ({ payload: { sessionId, sessionStartDate } }),
      (state, action) => {
        state.isTranscriptLoading = true;
      }
    ),
    clientTranscriptLoaded: create.preparedReducer(
      (transcriptData: SessionReading | null) => ({ payload: { transcriptData } }),
      (state, action) => {
        state.isTranscriptLoading = false;
        state.sessionTranscript = action.payload.transcriptData;
      }
    ),
    clientTranscriptPopupState: create.preparedReducer(
      (state: PopupState) => ({ payload: { state } }),
      (state, action) => {
        state.transcriptState = action.payload.state;
      }
    ),
    clientTranscriptClear(state) {
      state.sessionTranscript = null;
    }
  }),
  extraReducers: (builder) => {
    builder
      .addCase(updateClientDetails, (state) => {
        state.setFocusOnField = null;
      })
      .addCase(updatedClientDetails, (state, action) => {
        if (state.selectedClient?.clientId === action.payload.clientId) {
          state.selectedClient.givenDob = action.payload.clientDob;
          state.selectedClient.dobOrder = action.payload.clientDob
            ? Math.min(dateDiffInDays(new Date(), action.payload.clientDob), 10)
            : 10;
        }
      })
      .addCase(setFavorite, (state, action) => {
        if (state.selectedClient?.clientId === action.payload.clientId) {
          state.selectedClient.isFavoriteClient = action.payload.isFavorite;
        }
      })
      .addCase(loadScheduleMessage, (state) => {
        state.isScheduleMessageLoading = true;
      })
      .addCase(scheduleMessageLoaded, (state, action) => {
        state.isScheduleMessageLoading = false;
        state.scheduledMessage = action.payload;
      })
      .addCase(sendMessage, (state) => {
        if (state.selectedClient) {
          state.selectedClient.lastConvertedMessageId = undefined;
        }
      })
  }
})

export const {
  clientLastReadingsLoaded, clientMessagesLoaded, loadClientLastReadings, loadClientMessages, resetLastReadingsClientData,
  resetMessagesClientData, clientLoaded, showClientCard, resetNoteTitleFocusedField, blockClient, blockClientProcessed,
  blockClientProcessing, clientTranscriptClear, clientTranscriptLoad, clientTranscriptLoaded, clientTranscriptPopupState,
  setHasActivePromotion, setMessagingUnavailable, addClientMessage, setOfferUnavailable
} = clientSlice.actions;
export const clientReducer = clientSlice.reducer;