import { AppState } from "../store";

export const clientListSelector = (state: AppState) => state.clientlist;

export const searchTextSelector = (state: AppState) => state.clientlist.searchText;

export const isLoadingPageDataSelector = (state: AppState) => state.clientlist.isLoadingPageData;

export const isLoadingTagsCountSelector = (state: AppState) => state.clientlist.tags.isLoading;

export const filterPresetSelector = (state: AppState) => state.clientlist.filterPreset;

export const tagsSelector = (state: AppState) => state.clientlist.tags;

export const requestViewPendingListSelector = (state: AppState) => state.clientlist.requestedPreset;

export const sortFieldSelector = (state: AppState) => state.clientlist.sortField;

export const pageDataSelector = (state: AppState) => state.clientlist.pageData;



