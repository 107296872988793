import * as React from 'react';
import './FileUploadItem.css';
import { Component } from 'react';

export enum FileUploadItemStatus {

    Uploading = 1,
    Uploaded = 2,
    Error = 3,
    Sending = 4,
    
}

interface IFileUploadItemProps {
    fileName:string;
    removeItem:(fileName:string) => void; 
    status:FileUploadItemStatus;
    progress:number;

}

class FileUploadItem extends Component<IFileUploadItemProps> {

    constructor(props:IFileUploadItemProps) {
      super(props)
      this.state = {}
    }
    onclose(){
        this.props.removeItem(this.props.fileName);
    }

    render() {
            return (
        <div className="file-upload-item-container">

            <div className="name-and-status">
                <div className="file-name-container">
                    {this.props.fileName}
                </div>
                {/* <div className="file-status-container"> */}
                {this.props.status === FileUploadItemStatus.Uploading && 
                    <div className="file-uploading-container">
                        <div className="uploading-title">
                            Uploading
                        </div>
                        <div className="uploading-progress"
                            style={{ width: this.props.progress + '%' }}
                        >
                        </div>
                    </div>
                }

                {this.props.status === FileUploadItemStatus.Uploaded && 
                    <div className="file-uploaded-container">
                        Attached
                    </div>
                }

                {this.props.status === FileUploadItemStatus.Error && 
                    <div className="file-error-container">
                        The upload failed. Please try again.
                    </div>
                }
            {/* </div> */}
            </div>

            <div className="close-icon-container">
                <i className="close-icon" onClick={this.onclose.bind(this)}/>
            </div>
            
        </div>
            )
    };
}

export default FileUploadItem;