import * as React from "react";
//import "emoji-mart/css/emoji-mart.css";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import "./EmojiPicker.css";
//import { BaseEmoji } from "@emoji-mart/react";
import { BrokenEmojis } from "../../helpers/chatUtils";

interface EmojiPickerProps {
  isOpen: boolean;
  onSelect: (emojiUnicode: any) => void; //onSelect: (emojiUnicode: BaseEmoji) => void;
  width?: string;
}
// (emoji: BaseEmoji) => onSelect(emoji.native)
const EmojiPicker: React.FunctionComponent<EmojiPickerProps> = ({
  isOpen,
  onSelect,
  width = "100%",
}) => {
  return (
    <>
      {isOpen && (
        <Picker
          data={data}
          onEmojiSelect={onSelect}
          set={"native"}
          // title="Pick your emoji…"
          categories={[
            "search",
            "recent",
            "people",
            "nature",
            "foods",
            "activity",
          ]}
          exceptEmojis={Object.keys(BrokenEmojis)}
        />
      )}
    </>
  );
};

export default EmojiPicker;
