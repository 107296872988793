export const Star = (props: {fill: number, index: number}) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <linearGradient id="gray" x1="0%" x2="100%">
      <stop offset="0%" stopColor="#e2e2e2" />
      <stop offset="100%" stopColor="#eeeeee" />
    </linearGradient>
    <linearGradient id={`golden${props.index}`} >
      <stop offset="0%" stopColor="#ffb800" />
      <stop offset={props.fill} stopColor="#ffea30" />
      <stop offset={props.fill} stopColor="#00000000" />
    </linearGradient>
    <path fill="url(#gray)" fillRule="evenodd" clipRule="evenodd" d="M14 0L9.49048 7.79321L0.685181 9.67377L6.70349 16.3708L5.771 25.3262L14 21.672L22.229 25.3262L21.2965 16.3708L27.3148 9.67377L18.5095 7.79321L14 0Z"/>
    <path fill={`url(#golden${props.index})`} fillRule="evenodd" clipRule="evenodd" d="M14 0L9.49048 7.79321L0.685181 9.67377L6.70349 16.3708L5.771 25.3262L14 21.672L22.229 25.3262L21.2965 16.3708L27.3148 9.67377L18.5095 7.79321L14 0Z"/>
  </svg>
)