import { SessionType } from "../types/clientListTypes";

export function getSessionType(value: string): SessionType {
  switch (value) {
    case "voice":
    case "voice call":
      return SessionType.Voice;
    case "chat":
      return SessionType.Chat;
    case "video call":
    case "video":
      return SessionType.Video;
    case "test":
    case "rush":
    case "regular":
    case "offline":
    default:
      return SessionType.NA;
  }
}
