import _ from 'lodash';
import * as React from 'react';
import { useCallback } from 'react';
import './FavoriteIcon.css';


interface FavoriteIconProps {
    onclick:() => void;
    isFavoriteClient:boolean;
    isImpersonated: boolean;
}

const FavoriteIcon = (props:FavoriteIconProps) => {


    const onClickHandler = useCallback(_.throttle((originalOnClick) => {  originalOnClick(); }, 500), []);

    function getClass(isFavoriteClient:boolean){
        if(isFavoriteClient) return "fav-red-icon"
        return "fav-white-icon"
    }

    return <i data-tooltip-content="Favorite" 
        data-tooltip-id="client-card-tooltip"
        className={getClass(props.isFavoriteClient)} 
        onClick={(e) => { e.stopPropagation(); if(props.isImpersonated) return; onClickHandler(props.onclick); }} />; // title="Favorite"
};


export default FavoriteIcon;