import DobCell from '../../atoms/ClientList/DobCell';
import "./DobCellWithAdd.scss";

export interface IDobCellWithAddProps {
  birthDate: Date | null;
  dobOrder: number;
  onClickHandler:(e:any)=>void;
}

export function DobCellWithAdd (props: IDobCellWithAddProps) {
  return (
    <>
      { props.birthDate && <DobCell
              birthDate={props.birthDate}
              dobOrder={props.dobOrder}
            />
      }
      { !props.birthDate && <span className="dob-cell-with-add" onClick={props.onClickHandler}> + Add</span> }
    </>
  );
}
