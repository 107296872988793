import axios from "axios";
import { CANCEL } from "redux-saga";
import {
  ClientListResponse,
  ClientsByGroupsData,
} from "../../types/clientListTypes";
import api from "./api";
import {
  MyClientsResponse,
  mapToClientListResponse,
  mapToGetClientListParams,
} from "./models/my-clients";

export enum ClientListFilterPresetEnum {
  RecentlyContacted = 1,
  Favorite = 2,
  Referrals = 3,
  Leads = 4,
  Everyone = 5,
  New = 6,
  Past = 7,
  KasambaSuggestions = 8,
  Search = 9,
  Pending = 10,
  Blocked = 11,
  MissedChats = 12,
  MissedCalls = 13,
  VIPClients = 14,
  PotentialClients = 15,
}

export enum TagFilterEnum {
  NotConverted = 0,
  FirstSession = 1,
  SecondSession = 2,
  ThirdSession = 3,
  Returning = 4,
  Potential = 5,
}

export enum ClientListSortFieldEnum {
  IsFavoriteClient = 1,
  ClientAlias = 2,
  ClientId = 3,
  SessionCount = 4,
  LastSessionEndTime = 5,
  LastInstantMessage = 6,
  IsScheduledMessage = 7,
  ClientNameFromNote = 8,
  ClientTag = 9,
  DOB = 10,
  DOBWithLastSessionEndTime = 11,
  KasambaSuggestionsSorting = 12,
  Gender = 13,
}

export class SortOrder {
  public static Asc = "asc";
  public static Desc = "desc";
}
export type ClientListRequest = {
  filterPreset: ClientListFilterPresetEnum;
  pageNumber: number;
  rowsPerPage: number;
  sortField: ClientListSortFieldEnum;
  sortingOrder: string;
  searchText: string | null;
  filterTag: TagFilterEnum | null;
};

export type ClientListCountResponse = {
  result: number;
};

export type ClientListTagCountResponse = Array<TagValue>;

export type TagValue = {
  tag: TagFilterEnum;
  amount: number;
};

export function getClientList(
  request: ClientListRequest
): Promise<ClientListResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let requestCall: any = api
    .get<MyClientsResponse>("/my/clients", {
      cancelToken: source.token,
      params: mapToGetClientListParams(request),
    })
    .then((response) => response.data)
    .then((data) => mapToClientListResponse(data));

  requestCall[CANCEL] = () => source.cancel();

  return requestCall;
}

export function updateFavorite(
  clientId: number,
  isFavorite: boolean
): Promise<any> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let request: any
  if (isFavorite){
  request = api.post(
    'my/favorite_clients',
    {user_id:clientId},
    { cancelToken: source.token }
    ).catch((reason:any)=>console.log(reason));
  } else {
    request = api.delete(
      `my/favorite_clients/${clientId}`,
      { cancelToken: source.token }
      );
  }

  request[CANCEL] = () => source.cancel(); // cancel request if saga is cancelled (takeLatest for example). Not necessary if we use throttle or debounce

  return request;
}

export function getClientListTagCount(): Promise<ClientListTagCountResponse> {
  // TODO: replace this MOCK
  return Promise.resolve([
    {
      tag: 0,
      amount: 5,
    },
    {
      tag: 1,
      amount: 11,
    },
  ]);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get<ClientListTagCountResponse>("api/experts/me/clients/tagcount", {
      cancelToken: source.token,
    })
    .then((response) => response.data);

  request[CANCEL] = () => source.cancel();

  return request;
}

export function getClientsByGroups(): Promise<ClientsByGroupsData> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let requestCall: any = api
    .get<ClientsByGroupsData>("/api/experts/me/clients/forGroupMessages", {
      cancelToken: source.token,
    })
    .then((response) => response.data);

  requestCall[CANCEL] = () => source.cancel();

  return requestCall;
}
