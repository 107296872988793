import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { onBoardingFinish, showNext } from "../../slices/onBoardingSlice";
import { onBoardingSelector } from "../../selectors/onBoardingSelector";
import { AppState } from "../../store";
import "./OnBoardingToolTip.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { userSelector } from "../../selectors/userSelectors";
import { NUMBER_OF_ONBOARDING_TOOLTIPS } from "../../config";

export interface IOnBoardingToolTip1Props {
  title: string;
  body: string;
  step: number;
  children: any;
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end"
    | null;
  appendTo?: "parent" | Element | ((ref: Element) => Element);
  width?: string | number | null;
}

function OnBoardingToolTip(props: PropsFromRedux) {
  function isLastStep(): boolean {
    return props.own.step >= props.onBoarding.countOfSteps;
  }
  function stopPropagationClick(e: any) {
    e.stopPropagation();
  }
  function toolTipTamplate() {
    return (
      <div
        className="on-boarding-tool-tip-wrapper"
        style={{ maxWidth: props.own.width ? props.own.width : "207px" }}
        onClick={stopPropagationClick}
      >
        <div className="on-boarding-tool-tip-title">{props.own.title}</div>
        <div className="on-boarding-tool-tip-body">{props.own.body}</div>

        <div className="on-boarding-tool-tip-button-wrapper">
          <div
            className={
              "on-boarding-tool-tip-button" +
              (props.onBoarding.onlyNewShow ? " next" : (isLastStep() ? " finish" : " next"))
            }
            onClick={() =>
              isLastStep() ? props.onBoardingFinish() : props.showNext()
            }
          >
            <span className="on-boarding-tool-tip-button-text">
              {!props.onBoarding.onlyNewShow &&
                ((!isLastStep() &&
                  "NEXT " +
                    props.own.step +
                    "/" +
                    NUMBER_OF_ONBOARDING_TOOLTIPS) ||
                  (isLastStep() && "YOU’RE ALL SET!"))}
              {props.onBoarding.onlyNewShow && "GOT IT"}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Tippy
      content={toolTipTamplate()}
      visible={
        props.onBoarding.isShowing &&
        props.onBoarding.step === props.own.step 
      }
      interactive={true}
      theme="wem"
      placement={props.own.placement ? props.own.placement : undefined}
      appendTo={document.body}
    >
      {props.own.children}
    </Tippy>
  );
}

const mapState = (state: AppState, own: IOnBoardingToolTip1Props) => ({
  onBoarding: onBoardingSelector(state),
  user: userSelector(state),
  own: own,
});

const connector = connect(mapState, {
  showNext: showNext,
  onBoardingFinish: onBoardingFinish,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OnBoardingToolTip);
