import { FC } from "react";
import { GenderType } from "../../types/clientListTypes";
import GenderIcon from "./GenderIcon";
import "./Avatar.scss";
import { ReactSVG } from "react-svg";

type Props = {
  url: string | null;
  gender?: GenderType;
};

export const Avatar: FC<Props> = ({ url, gender }: Props) => {
  return (
    <div className="client-avatar">
      {url && <img src={url} alt="client's avatar" />}
      {!url && (
        <ReactSVG
          className="default-avatar"
          src="/images/default-profile-avatar.svg"
        />
      )}
      {gender && (
        <div className="gender-wrapper">
          <GenderIcon gender={gender} />
        </div>
      )}
    </div>
  );
};
