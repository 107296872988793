import "./SessionTopBar.scss";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import FeePicker from "../atoms/FeePicker";
import {
  isUserHiredSelector,
  isSessionEndedSelector,
  isSessionPausedSelector,
  isTryoutSelector,
} from "../../selectors/sessionSelectors";
import { useStopwatch, useTimer } from "react-timer-hook";
import { useEffect } from "react";
import { isKasambaApp } from "../../helpers/settingsHelper";
import {
  freeDurationSelector,
  paidDurationSelector,
} from "../../slices/sessionSlice";

const formatTime = (num: number): string => {
  return num.toString().padStart(2, "0");
};

const SessionTopBar = ({
  isSessionEnded,
  freeDuration,
  paidDuration,
  isPaused,
  isTryout,
}: PropsFromRedux) => {
  const timerType = isKasambaApp ? useStopwatch : useTimer;
  const expiryTimestamp = new Date(Date.now() + (paidDuration || 0) * 1000);

  const timerResult = timerType({
    autoStart: false,
    expiryTimestamp: expiryTimestamp,
  });

  useEffect(() => {
    if (freeDuration === 0) {
      "restart" in timerResult
        ? timerResult.restart(expiryTimestamp, true)
        : timerResult.start();
    }
  }, [freeDuration]);

  useEffect(() => {
    if (isSessionEnded || isPaused) {
      timerResult.pause();
    }
  }, [isSessionEnded, isPaused]);

  return (
    <div className="session-page__top-bar">
      <div className="session-page__top-bar__fee-picker">
        <FeePicker />
      </div>
      <div className="session-page__top-bar__timer">
        {freeDuration !== 0 && <span>Free Setup time</span>}
        {freeDuration === 0 && (
          <span className="timer">
            {formatTime(timerResult.hours)}:{formatTime(timerResult.minutes)}:
            {formatTime(timerResult.seconds)}
          </span>
        )}
        {freeDuration === 0 && isTryout && <span className="tryout">Free Tryout time</span>}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  freeDuration: freeDurationSelector(state),
  paidDuration: paidDurationSelector(state),
  isUserHired: isUserHiredSelector(state),
  isSessionEnded: isSessionEndedSelector(state),
  isPaused: isSessionPausedSelector(state),
  isTryout: isTryoutSelector(state),
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SessionTopBar);
