import { useState } from "react";
import Spinner from "../components/atoms/Spinner";
import "./Revenue.scss";
import { AppState } from "../store";
import { connect, ConnectedProps } from "react-redux";
import { advisorBalanceUrlSelector } from "../slices/userSlice";

function Revenue(props: PropsFromRedux) {
  const [loading, setLoading] = useState(true);
  const handlerIframeLoaded = () => {
    setLoading(false);
  };
  const handlerIframeError = () => {
    setLoading(false);
  };
  return (
    <div className="revenues">
      {loading && <Spinner />}
      <iframe
        src={props.advisorBalanceUrl || ""}
        title="revenue page"
        onLoad={handlerIframeLoaded}
        onError={handlerIframeError}
      />
    </div>
  );
}

const mapState = (state: AppState) => ({
  advisorBalanceUrl: advisorBalanceUrlSelector(state),
});

const connector = connect(mapState, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Revenue);
