import "./App.css";
import routes from "./router";
import MainLayout from "./components/layouts/MainLayout";
import ModalRoot from "./components/layouts/ModalRoot";
import IncomingChatPopup from "./components/organisms/IncomingChatPopup";
import AppSound from "./components/organisms/AppSound/AppSound";
import ClientCard from "./components/organisms/ClientCard";
import { Tooltip } from "react-tooltip";
import NewVersionBanner from "./components/atoms/NewVersionBanner";
import ActiveReadingPopup from "./components/organisms/ActiveReadingPopup";

function App() {
  return (
    <>
      <MainLayout>{routes}</MainLayout>
      <ModalRoot />
      <IncomingChatPopup />
      <ActiveReadingPopup />
      <ClientCard />
      <AppSound />
      <Tooltip id="wem-tooltip" />
      {/* <NewVersionBanner /> */}
    </>
  );
}

export default App;
