import "./WaitingClientModal.scss";
import ReactModal from "react-modal";
import { connect, ConnectedProps } from "react-redux";
import { closeModal } from "../../slices/modalSlice";
import { endSession } from "../../slices/sessionSlice";
import { AppState } from "../../store";
import { useTimer } from "react-timer-hook";

const WaitingClientModal = ({
  hangupTimeout,
  closeModal,
  endSession,
}: PropsFromRedux) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + hangupTimeout);

  const { totalSeconds } = useTimer({
    expiryTimestamp: time,
    autoStart: true,
  });

  return (
    <ReactModal
      isOpen={true}
      overlayClassName="modal-overlay"
      className="modal-content waiting-client-modal"
    >
      <div className="modal-content-wrapper">
        <h2>Waiting for the client to top up</h2>
        <p>
          To keep the conversation going, the client should add more credits. If
          there's no activity within {hangupTimeout} seconds, you may end the
          chat.
        </p>

        <button
          disabled={totalSeconds > 0}
          className="primary"
          onClick={() => {
            closeModal();
            endSession();
          }}
        >
          {!!totalSeconds && <span className="counter">{totalSeconds}</span>}
          End chat
        </button>
      </div>
    </ReactModal>
  );
};

const mapState = (state: AppState) => ({
  hangupTimeout: state.modal.props.hangupTimeout,
});

const connector = connect(mapState, { closeModal, endSession });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WaitingClientModal);
