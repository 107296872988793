import { createSlice } from "@reduxjs/toolkit";
import {
  NetworkStatus,
  SessionState,
  SessionStatus,
  SessionSummary,
} from "../types/sessionTypes";
import {
  ClientReadingData,
  ClientSupportedFeatures,
} from "../types/clientTypes";
import { SessionReading } from "../services/api/messagesApi";
import { setFavorite } from "./clientListSlice";
import { AdvisorStartChatResponse } from "../services/api/userApi";

const initialState: SessionState = {
  sessionId: "",
  uuid: "",
  channelId: "",
  client: null,
  callingClient: null,
  maxFeePerHour: 0,
  feePerHour: 0,
  sessionSummary: null,
  scheduledMessageLink: null,
  isClientLifecycleTypeLoading: false,
  sessionCloseReason: null,
  isMessageScheduled: false,
  lastReadings: null,
  nextPage: 1,
  isTryout: false,
  sessionReadingData: {
    isLoading: false,
    data: null,
    isShow: false,
    isError: false,
  },
  ringData: null,
  freeDuration: 0,
  paidDuration: 0,
  activeSessionClient: null,
  status: SessionStatus.none,
  networkStatus: NetworkStatus.connected,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: (create) => ({
    clientAskToStart: create.preparedReducer(
      (
        clientId: number,
        sessionId: string,
      ) => ({
        payload: {
          clientId,
          sessionId,
        },
      }),
      (state, action) => {
        state.sessionId = action.payload.sessionId;
      }
    ),
    acceptSession: create.preparedReducer(
      (sessionStartUtcTime = Date.now()) => ({
        payload: { sessionStartUtcTime },
      }),
      (state, action) => ({
        ...initialState,
        ...action.payload,
        status: SessionStatus.accepted,
        client: { ...state.callingClient!, ...state.ringData! },
        sessionId: state.sessionId,
      })
    ),
    declineSession: (state) => {
      state.callingClient = null;
      state.ringData = null;
      state.activeSessionClient = null;
    },
    sessionDeclined: create.preparedReducer(
      (sessionEndingUtcTime = Date.now()) => ({
        payload: { sessionEndingUtcTime },
      }),
      (state, action) => {
        state.sessionEndingUtcTime = action.payload.sessionEndingUtcTime;
        state.status |= SessionStatus.ended;
      }
    ),
    missedSession: (state) => {
      state.callingClient = null;
      state.ringData = null;
      state.activeSessionClient = null;
    },
    answeredOnAnotherDevice: create.preparedReducer(
      (clientId: number, clientName: string) => ({ payload: { clientId, clientName } }),
      (state, action) => {
        state.callingClient = null;
        state.ringData = null;
        state.activeSessionClient = {
          id: action.payload.clientId,
          name: action.payload.clientName
        }
      }
    ),
    sessionStarted: create.preparedReducer(
      (
        freeDuration: number,
        paidDuration: number,
        isTryout: boolean,
        supportedFeatures: ClientSupportedFeatures[]
      ) => ({ payload: { supportedFeatures, freeDuration, paidDuration, isTryout } }),
      (state, action) => {
        if (state.client) {
          state.client.supportedFeatures = action.payload.supportedFeatures;
        }
        state.freeDuration = action.payload.freeDuration;
        state.paidDuration = action.payload.paidDuration;
        state.isTryout = action.payload.isTryout;
        state.status = SessionStatus.started;
      }
    ),
    sessionResumed: create.preparedReducer(
      (freeDuration: number, paidDuration: number, isTryout: boolean) => ({
        payload: { freeDuration, paidDuration, isTryout },
      }),
      (state, action) => {
        state.freeDuration = action.payload.freeDuration;
        state.paidDuration = action.payload.paidDuration;
        state.isTryout = action.payload.isTryout;
        state.status ^= SessionStatus.paused;
      }
    ),
    sessionConnected: create.preparedReducer(
      (sessionId: string, uuid: string, channelId: string) => ({
        payload: { sessionId, uuid, channelId },
      }),
      (state, action) => ({ ...state, ...action.payload })
    ),
    sessionPaused: create.preparedReducer(
      (hangupTimeout: number) => ({ payload: { hangupTimeout } }),
      (state) => {
        state.status |= SessionStatus.paused;
      }
    ),
    initFee: create.preparedReducer(
      (feePerHour: number) => ({ payload: { feePerHour } }),
      (state, action) => {
        state.maxFeePerHour = action.payload.feePerHour;
        state.feePerHour = action.payload.feePerHour;
      }
    ),
    decreaseFee: create.preparedReducer(
      (feePerHour: number) => ({ payload: { feePerHour } }),
      () => {}
    ),
    decreaseFeeSucceeded: create.preparedReducer(
      (feePerHour: number) => ({ payload: { feePerHour } }),
      (state, action) => {
        state.feePerHour = action.payload.feePerHour;
      }
    ),
    decreaseFeeFailed: create.preparedReducer(
      (reason: string) => ({ payload: reason }),
      () => {}
    ),
    setUserHired: create.preparedReducer(
      (isResuming: boolean, isTryout: boolean, userHiredUtcTime = Date.now()) => ({ payload: { userHiredUtcTime, isResuming, isTryout } }),
      (state, action) => {
        state.userHiredUtcTime = action.payload.userHiredUtcTime;
        state.sessionEndingUtcTime = undefined;
        state.freeDuration = 0;
      }
    ),
    endSession: create.preparedReducer(
      (sessionEndingUtcTime = Date.now()) => ({
        payload: { sessionEndingUtcTime },
      }),
      (state, action) => {
        state.sessionEndingUtcTime = action.payload.sessionEndingUtcTime;
        state.status |= SessionStatus.ended;
      }
    ),
    sessionEnded: create.preparedReducer(
      (sessionEndingUtcTime = Date.now()) => ({
        payload: { sessionEndingUtcTime },
      }),
      (state, action) => {
        state.sessionEndingUtcTime = action.payload.sessionEndingUtcTime;
        state.status |= SessionStatus.ended;
        state.activeSessionClient = null;
      }
    ),
    sessionClientDetailsLoaded: create.preparedReducer(
      (lastConsulted: Date | null, isFavoriteClient: boolean) => ({
        payload: { lastConsulted, isFavoriteClient },
      }),
      (state, action) => {
        if (state.client) {
          state.client.isFavorite =
            action.payload.isFavoriteClient;
          state.client.lastConsulted = 
            action.payload.lastConsulted;
        }
      }
    ),
    showSessionSummary: create.preparedReducer(
      (summary: SessionSummary) => ({ payload: summary }),
      (state, action) => {
        state.sessionSummary = { ...action.payload };
      }
    ),
    closeSession: (state) => {
      state.status = SessionStatus.none;
    },
    setClientDevice: create.preparedReducer(
      (device: string) => ({ payload: { device } }),
      (state, action) => {
        if (state.client) {
          state.client.device = action.payload.device;
        }
      }
    ),
    setScheduledMessageLink: create.preparedReducer(
      (url: string | null) => ({
        payload: {
          scheduledMessageLink: url,
          isMessageScheduled: url === null,
        },
      }),
      (state, action) => ({ ...state, ...action.payload })
    ),
    setSessionCloseReason: create.preparedReducer(
      (message: string) => ({ payload: { message } }),
      (state, action) => {
        state.sessionCloseReason = action.payload.message;
      }
    ),
    sessionLastReadingsLoaded: create.preparedReducer(
      (data: ClientReadingData[], nextPage: number | undefined) => ({
        payload: { data, nextPage },
      }),
      (state, action) => {
        state.lastReadings = action.payload.data;
        state.nextPage = action.payload.nextPage;
      }
    ),
    getReadingBySessionId: create.preparedReducer(
      (sessionId: number, sessionStartTime: Date | null) => ({
        payload: { sessionId, sessionStartTime },
      }),
      () => {}
    ),
    startLoadingSessionReading(state) {
      state.sessionReadingData = {
        ...state.sessionReadingData,
        isLoading: true,
        isError: false,
        isShow: true,
      };
    },
    sessionReadingLoaded: create.preparedReducer(
      (data: SessionReading) => ({ payload: data }),
      (state, action) => {
        state.sessionReadingData = {
          ...state.sessionReadingData,
          isLoading: false,
          data: action.payload,
        };
      }
    ),
    showSessionReading: create.preparedReducer(
      (flag: boolean) => ({ payload: flag }),
      (state, action) => {
        state.sessionReadingData = {
          ...state.sessionReadingData,
          isShow: action.payload,
          isError: false,
        };
      }
    ),
    sessionReadingError(state) {
      state.sessionReadingData = {
        ...state.sessionReadingData,
        isLoading: false,
        data: null,
        isError: true,
      };
    },
    sessionReadingClose(state) {
      state.sessionReadingData = {
        ...state.sessionReadingData,
        isShow: false,
        isLoading: false,
        isError: false,
      };
    },
    sessionSetIsClientLifecyleLoading: create.preparedReducer(
      (value: boolean) => ({ payload: value }),
      (state, action) => {
        state.isClientLifecycleTypeLoading = action.payload;
      }
    ),
    ringData: create.preparedReducer(
      (ringData: AdvisorStartChatResponse) => ({ payload: { ringData } }),
      (state, action) => {
        state.ringData = action.payload.ringData;
        state.callingClient = {
          id: action.payload.ringData.buyer_id,
          name: action.payload.ringData.buyer_nickname,
          category: "",
          tryout: action.payload.ringData.tryout,
          durationMinutes: action.payload.ringData.duration,
          device: "",
          isFavorite: null,
          lastConsulted: null,
          isReturningClient: false,
          memberSince: null,
          supportedFeatures: [],
        };
      }
    ),
    networkStatusChanged: create.preparedReducer(
      (state: NetworkStatus) => ({ payload: state }),
      (state, action) => {
        state.networkStatus = action.payload;
      }
    ),
  }),
  extraReducers: (builder) => {
    builder.addCase(setFavorite, (state, action) => {
      if (state.client?.id === action.payload.clientId) {
        state.client.isFavorite = action.payload.isFavorite;
      }
    });
  },
  selectors: {
    ringDataSelector: (state) => state.ringData,
    paidDurationSelector: (state) => state.paidDuration,
    freeDurationSelector: (state) => state.freeDuration,
    networkStatusSelector: (state) => state.networkStatus,
  },
});

export const { ringDataSelector, freeDurationSelector, paidDurationSelector, networkStatusSelector } =
  sessionSlice.selectors;

export const {
  acceptSession,
  setSessionCloseReason,
  clientAskToStart,
  closeSession,
  declineSession,
  sessionDeclined,
  missedSession,
  decreaseFee,
  decreaseFeeFailed,
  decreaseFeeSucceeded,
  endSession,
  getReadingBySessionId,
  initFee,
  sessionClientDetailsLoaded,
  sessionConnected,
  sessionEnded,
  sessionLastReadingsLoaded,
  sessionPaused,
  sessionReadingClose,
  sessionReadingError,
  sessionReadingLoaded,
  sessionResumed,
  sessionSetIsClientLifecyleLoading,
  sessionStarted,
  setClientDevice,
  setScheduledMessageLink,
  setUserHired,
  showSessionReading,
  showSessionSummary,
  startLoadingSessionReading,
  ringData,
  answeredOnAnotherDevice,
  networkStatusChanged,
} = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
