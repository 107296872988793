import * as React from 'react';
import './FileUploadIcon.scss';


interface FileUploadProps {
    onclick:() => void; 
    enabled: boolean;
}

const FileUploadIcon = (props:FileUploadProps) => {
    return <i className={"clip-icon " +(props.enabled ? "clip-icon-enabled":"")} onClick={props.onclick}/>;
};

export default FileUploadIcon;