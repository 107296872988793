import React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../store';
import { closeModal } from '../../slices/modalSlice'
import './SlidesModal.css';
import { useState } from 'react';
import { Howl } from 'howler';
import { userStatusSelector } from '../../selectors/userSelectors';
import { soundsSelector } from '../../selectors/appSettingsSelector';
import { GetRingingByType } from '../../helpers/settingsHelper';
import { NUMBER_OF_TUTORIAL_SLIDES } from '../../config';

const slides = Array(NUMBER_OF_TUTORIAL_SLIDES).fill('').map((_element, index) => index + 1);

// preload images
new Image().src = '/images/slides/1.svg';
new Image().src = '/images/slides/2.png';
new Image().src = '/images/slides/3.gif';
new Image().src = '/images/slides/4.gif';
new Image().src = '/images/slides/5.gif';

const SlidesModal =
    (props: PropsFromRedux) => {
        const startFrom = props.formParams.startFrom || 0;
        const slidesToView = slides.filter((_n,index) => index == 0 || slides.indexOf(startFrom) <= index);
        const ringingAudio = new Howl({ src: GetRingingByType(props.soundSettings.ringing)});
        
        const [currentSlide, setCurrentSlide] = useState(slidesToView[0]);
        const getButtonLabel = () => {
            const currentSlideIndex = slidesToView.indexOf(currentSlide);
            if (currentSlideIndex == 0)
                return "Let’s get started";
            if (currentSlideIndex == slidesToView.length - 1)
                return "Done";
            return "Next";
        };

        const clickHandler = () => {
            const currentSlideIndex = slidesToView.indexOf(currentSlide);
            if (currentSlideIndex == slidesToView.length - 1) {
                props.closeModal();
                return;
            }

            setCurrentSlide(slidesToView[currentSlideIndex + 1]);
        }

        const testRingHandler = () => {
            ringingAudio.play();
        }

        return (<ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content slides"  >
            <div className="content">
                {currentSlide == 1 && <div className='slide-1'>
                    <img src="/images/slides/1.svg" />
                    <h2>Get a Hold on your Features</h2>
                    <p>We’ve released LOADS of new features in the past months. Go through them to make sure you are not missing out!</p>
                </div>}

                {currentSlide == 2 && <div className='slide-2'>
                    <img src="/images/slides/2.png" />
                    <h2>Message status tags</h2>
                    <p>Know if the client received your message. Look for the messages status tags on your messages tab.</p>
                </div>}

                {currentSlide == 3 && <div className='slide-3'>
                    <img src="/images/slides/3.gif" />
                    <h2>Filter clients by their status</h2>
                    <p>Filter your clients by their status from 1st paid session to a returning client. <a href={props.tutorialLifecycleVideoUrl} target="_blank">Watch our video</a> to learn more about the client lifecycle.</p>
                </div>}

                {currentSlide == 4 && <div className='slide-4'>
                    <img src="/images/slides/4.gif" />
                    <h2>Send a discount to your client</h2>
                    <p>Giving your clients a discount at the right moment will encourage them to come back to have another session with you.</p>
                </div>}

                {currentSlide == 5 && <div className='slide-5'>
                    <img src="/images/slides/5.gif" />
                    <h2>Use the template messages</h2>
                    <p>Template message will help you send messages to your clients based on their status faster and easier. Contact us to start using the template messages.</p>
                </div>}

            </div>
            <div className="nav">
                <button onClick={clickHandler} className="primary">{getButtonLabel()}</button>
                <div className="dots">
                    {
                        slidesToView.map(n => <span key={n} onClick={() => setCurrentSlide(n)} className={n == currentSlide ? 'active' : ''}></span>)}
                </div>
            </div>
        </ReactModal>
        )
    };

const mapState = (state: AppState) => ({
    formParams: state.modal.props,
    extertStatus: userStatusSelector(state),
    soundSettings: soundsSelector(state),
    tutorialLifecycleVideoUrl: state.appSettings.tutorialLifecycleVideoUrl
});

const connector = connect(mapState, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SlidesModal);