import "./ClientDetail.css";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../store";
import ClientLastReading from "../../atoms/ClientLastReading";
import {
  clientSelector,
  isUserHiredSelector,
} from "../../../selectors/sessionSelectors";
import FavoriteIcon from "../../atoms/FavoriteIcon";
import { setFavorite } from "../../../slices/clientListSlice";
import { DOB } from "../../atoms/DOB";
import { Avatar } from "../../atoms/Avatar";

const mapState = (state: AppState) => ({
  client: clientSelector(state),
  isUserHired: isUserHiredSelector(state),
});

const connector = connect(mapState, {
  setFavorite,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

const ClientDetail = (props: PropsFromRedux) => (
  <>
    <div className="title client-detail">client details</div>

    <div className="avatar-and-name">
      <Avatar
        url={props.client!.buyer_avatar_url}
        gender={props.client!.gender}
      />
      <div>
        <div className="mute-text">
          ID {props.client?.id}
          {props.client?.isReturningClient && (
            <img
              className="returning-client-icon"
              src="/images/returning-client-icon.svg"
            />
          )}
        </div>

        <div className="client-name">
          <h1>{props.client?.name}</h1>
          {props.client &&
            (props.client.isFavorite != null || props.isUserHired) && (
              <div className="client-name_fav">
                <FavoriteIcon
                  isFavoriteClient={props.client?.isFavorite || false}
                  isImpersonated={false}
                  onclick={() => {
                    props.setFavorite(
                      props.client?.id || 0,
                      !props.client?.isFavorite,
                      props.client?.name || "",
                      "client detail"
                    );
                  }}
                />
              </div>
            )}
          {/* <div className='client-name__device'><ClientDevice deviceType={props.client?.device || ""} /></div> */}
        </div>
      </div>
    </div>

    <DOB dob={(props.client?.date_of_birth && new Date(props.client?.date_of_birth)) || null} />

    <div className="mute-text last-reading">
      <ClientLastReading
        lastReading={props.client?.lastConsulted || null}
        isReturningClient={props.client?.isReturningClient || false}
      />
    </div>
  </>
);

export default connector(ClientDetail);
