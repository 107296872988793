import { ConnectedProps, connect } from "react-redux";
import { AppState } from "../../store";
import { showClientCard } from "../../slices/clientSlice";

import './ActiveReadngPopup.scss';

const ActiveReadingPopup = ({
  activeSessionClient, showClientCard
}: PropsFromRedux) => {
  return (
    <div className={"active-reading-popup" + (activeSessionClient ? " open" : "")}>
      <div>
        <div className="client-info">
          <span>Active reading</span>
          <span className="client-name">{activeSessionClient?.name}</span>
        </div>

        {activeSessionClient && <button className="view-client" onClick={() => showClientCard(activeSessionClient.id)}>
          View client
        </button>}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  activeSessionClient: state.session.activeSessionClient
});

const connector = connect(mapState, { showClientCard });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActiveReadingPopup);
