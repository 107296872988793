import * as React from "react";
import "./ClientListTable.css";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { ClientListTableName } from "../atoms/ClientList/ClientListTableName";
import { ClientListTableHeader } from "../atoms/ClientList/ClientListTableHeader";

import { showScheduledMessageForm } from "../../actions/messageActions";
import { clientListSelector } from "../../selectors/clientListSelector";
import {
  gotoNextPage,
  gotoPrevPage,
  gotoPage,
  rowsPerPageChanged,
  markAsScheduled,
  setFavorite,
  clickColumnHeader,
} from "../../slices/clientListSlice";
import { showClientCard } from "../../slices/clientSlice";
import Spinner from "../atoms/Spinner";
import Paging from "../molecules/Paging";
import { ClientListTableRow } from "../molecules/ClientList/ClientListTableRow";

import { onBoardingSelector } from "../../selectors/onBoardingSelector";
import OnBoardingToolTip from "../atoms/OnBoardingToolTip";
// import TagFilter from "../molecules/ClientList/TagFilter";
import { isManagerOfVIPAccountsSelector } from "../../selectors/userSelectors";
import { isImpersonatedSelector } from "../../slices/userSlice";
import { showModal } from "../../slices/modalSlice";
import { ModalType } from "../../types/modalTypes";

const mapState = (state: AppState) => ({
  clientList: clientListSelector(state),
  onBoarding: onBoardingSelector(state),
  selectedClientId: state.client.selectedClientId,
  isManagerOfVIPAccounts: isManagerOfVIPAccountsSelector(state),
  isImpersonated: isImpersonatedSelector(state),
});

const connector = connect(mapState, {
  gotoNextPage: gotoNextPage,
  gotoPrevPage: gotoPrevPage,
  gotoPage: gotoPage,
  rowsPerPageChanged: rowsPerPageChanged,
  markAsScheduled,
  showScheduledMessageForm,
  setFavorite: setFavorite,
  clickColumnHeader: clickColumnHeader,
  showClientCard,
  showModal
});

type PropsFromRedux = ConnectedProps<typeof connector>;

class ClientListTable extends React.Component<PropsFromRedux> {
  constructor(props: PropsFromRedux) {
    
    super(props);
  }

  showScheduledMessageForm(clientId: number) {
    const item = this.props.clientList.pageData.find(
      (i) => i.clientId == clientId
    );
    if (!item) {
      return;
    }
    // const link =
    //   KASAMBA_SCHEDULE_MESSAGE_LINK +
    //   `&guid=${item.expertGuid}&clientId=${clientId}&clientAlias=${item.clientAlias}` +
    //   `&chatMinutes=${item.lastSessionDurationInMinutes}&chatEndTime=${item.lastSessionEndTime}` +
    //   `&sessionId=${item.lastSessionId}`;

    this.props.showScheduledMessageForm({
      clientId: clientId,
      clientAlias: item.clientAlias,
      clientNoteName: item.clientNameFromNote,
      clientType: item.tag,
      sessionDuration: item.lastSessionDurationInMinutes || 0,
      sessionEndTime: new Date(item.lastSessionEndTime || "")
    });
  }

  isDataNotFound() {
    return (
      this.props.clientList.pageData &&
      this.props.clientList.pageData.length === 0
    );
  }
  isDataFound() {
    return (
      this.props.clientList.pageData &&
      this.props.clientList.pageData.length > 0
    );
  }
  isShowPagination() {
    return (
      !this.props.clientList.isLoadingCount &&
      this.props.clientList.pageData &&
      this.props.clientList.pageData.length > 0
    );
  }
  
  
  public render() {
    return (
      <div className="cl-result">

        <ClientListTableName
          filterPreset={this.props.clientList.filterPreset}
          recordCount={this.props.clientList.count}
          isLoadingCount={this.props.clientList.isLoadingCount}
          isManagerOfVIPAccounts= {this.props.isManagerOfVIPAccounts}
        />
        {/* <TagFilter /> */}
        <table>
          <OnBoardingToolTip
            title="MANAGE YOUR CLIENTS"
            body="See your clients’ details, mark your favorites, and send scheduled messages."
            step={1}
            placement="top"
          >
            <ClientListTableHeader
              clickColumnHeader={this.props.clickColumnHeader}
              sortingOrder={this.props.clientList.sortingOrder}
              sortField={this.props.clientList.sortField}
            />
          </OnBoardingToolTip>

          <tbody>
            {this.isDataNotFound() && (
              <>
                <tr>
                  <td colSpan={9}>
                    <div className="cl-not-found-wrapper">
                      {this.isDataNotFound() && (
                        <div>
                          There are no clients matching the selected criteria
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </>
            )}
            {this.isDataFound() && (
              <ClientListTableRow
                onSelectClient={this.props.showClientCard}
                selectedClientId={this.props.selectedClientId}
                data={this.props.clientList.pageData}
                isImpersonated={this.props.isImpersonated}
                scheduleMessageClick={(clientId) =>
                  this.showScheduledMessageForm(clientId)
                }
                couponBtnClick={(clientId, clientName) =>
                  this.props.showModal(ModalType.SelectCoupon, { clientId, clientName })
                }
                updateFavorite={(clientId: number, isFavorite: boolean, clientName:string) =>
                  this.props.setFavorite(clientId, isFavorite,clientName, "client list")
                }
              />
            )}
          </tbody>
        </table>

        {this.isShowPagination() && (
          <Paging
            currentPage={this.props.clientList.currentPage}
            rowsPerPage={this.props.clientList.rowsPerPage}
            totalRows={this.props.clientList.count}
            nextPage={this.props.gotoNextPage}
            prevPage={this.props.gotoPrevPage}
            gotoPage={this.props.gotoPage}
            rowsPerPageChanged={this.props.rowsPerPageChanged}
          />
        )}
        {!this.props.clientList.isLoadingPageData &&
          this.props.clientList.isLoadingCount && <div className="cl-paging-loader-wrapper"><Spinner size={20} /></div>}
        <div
          className="cover-screen"
          hidden={!this.props.onBoarding.isShowing}
        ></div>
      </div>
    );
  }
}

export default connector(ClientListTable);
