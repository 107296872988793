import { AppState } from "../store";
import { ServerUserStatus } from "../types/userTypes";

export const userSelector = (state: AppState) => state.user;

export const userNameSelector = (state: AppState) => state.user.userName;

export const userAvatarUrlSelector = (state: AppState) => state.user.userAvatarUrl;

export const userStatusSelector = (state: AppState):ServerUserStatus => state.user.serverUserStatus;

export const isManagerOfVIPAccountsSelector = (state: AppState) => state.user.managerOfVipAccounts;

export const isGroupMessageEnabledSelector = (state: AppState) => state.user.shownGroupMessages;
  