import * as React from "react";
import { ClientListFilterPresetEnum } from "../../../services/api/clientListApi";
import Spinner from "../Spinner";
import "./ClientListTableName.css";

export interface IClientListTableNameProps {
  filterPreset: ClientListFilterPresetEnum;
  recordCount: number;
  isLoadingCount: boolean;
  isManagerOfVIPAccounts: boolean;
}

export function ClientListTableName(props: IClientListTableNameProps) {
  return (
    <div className="cs-table-name">
      {props.isManagerOfVIPAccounts && (
        <div>
          VIP CLIENTS
          <span className="cl-sub-name">Tier 4 + Tier 5 clients.</span>
        </div>
      )}
      {!props.isManagerOfVIPAccounts && (
        <div>
          {/* removed by https://ingenio.atlassian.net/browse/IM-151
           {props.filterPreset ===
            ClientListFilterPresetEnum.KasambaSuggestions && (
            <>
              KASAMBA DAILY SUGGESTIONS
              <span className="cl-sub-name">
              RECOMMENDED EXISTING CLIENTS TO ENGAGE
              </span>
            </>
          )} */}
          {props.filterPreset ===
            ClientListFilterPresetEnum.RecentlyContacted && (
            <>
              RECENT CONTACTS<span className="cl-sub-name">LAST 60 DAYS</span>
            </>
          )}
          {props.filterPreset === ClientListFilterPresetEnum.Favorite && (
            <>
              FAVORITES
              <span className="cl-sub-name">
                You have{" "}
                {!props.isLoadingCount && <span>{props.recordCount}</span>}
                {props.isLoadingCount && (
                  <span className="inline-spinner">
                    <Spinner size={8} />
                  </span>
                )}{" "}
                favorite clients
              </span>
            </>
          )}

          {props.filterPreset === ClientListFilterPresetEnum.Everyone && (
            <>
              ALL CLIENTS
              <span className="cl-sub-name">
                You have{" "}
                {!props.isLoadingCount && <span>{props.recordCount}</span>}
                {props.isLoadingCount && (
                  <span className="inline-spinner">
                    <Spinner size={8} />
                  </span>
                )}{" "}
                clients
              </span>
            </>
          )}

          {props.filterPreset === ClientListFilterPresetEnum.Search && (
            <>
              SEARCH RESULTS
              <span className="cl-sub-name">
                {!props.isLoadingCount && <span>{props.recordCount}</span>}
                {props.isLoadingCount && (
                  <span className="inline-spinner">
                    <Spinner size={8} />
                  </span>
                )}{" "}
                clients
              </span>
            </>
          )}

          {props.filterPreset === ClientListFilterPresetEnum.Past && (
            <>
              PAST CLIENTS
              <span className="cl-sub-name">
                Clients that didn't have a session with you in the last 7-21
                days.
              </span>
            </>
          )}

          {props.filterPreset === ClientListFilterPresetEnum.New && (
            <>
              NEW CLIENTS
              <span className="cl-sub-name">
                New clients that had a session with you in the last 7 days.
              </span>
            </>
          )}

          {props.filterPreset === ClientListFilterPresetEnum.Pending && (
            <>
              CLIENTS PENDING
              <span className="cl-sub-name">
                Clients you had a session with today and didn’t contact yet
              </span>
            </>
          )}

          {/* removed by https://ingenio.atlassian.net/browse/IM-151 
          {props.filterPreset === ClientListFilterPresetEnum.Blocked && (
            <>
              BLOCKED CLIENTS
              <span className="cl-sub-name">
                You have{" "}
                {!props.isLoadingCount && <span>{props.recordCount}</span>}
                {props.isLoadingCount && (
                  <span className="inline-spinner">
                    <Spinner size={8} />
                  </span>
                )}{" "}
                blocked clients
              </span>
            </>
          )} */}

          {props.filterPreset === ClientListFilterPresetEnum.MissedCalls && (
            <>
              MISSED PHONE CALLS from your clients
              <span className="cl-sub-name">
                Clients that you missed a phone call with today
              </span>
            </>
          )}

          {props.filterPreset === ClientListFilterPresetEnum.MissedChats && (
            <>
              MISSED CHATS from your clients
              <span className="cl-sub-name">
                Clients that you missed a chat with today
              </span>
            </>
          )}

          {props.filterPreset ===
            ClientListFilterPresetEnum.PotentialClients && (
            <>
              POTENTIAL CLIENTS<span className="cl-sub-name">
                CLIENTS INTERESTED IN YOU, IN THE LAST 3 DAYS, WITH NO HIRED SESSION YET.
                </span>
            </>
          )}

        </div>
      )}
    </div>
  );
}
