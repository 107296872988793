import { batchFull, logAdded } from "../actions/logsActions";
import { LOGS_BATCH_KEY, LOGS_MAX_BATCH_SIZE } from "../config";
import { toISOStringWithTimezone } from "../helpers/dateFormatHelper";
import { store } from "../store";
import { AddLogPayload, LogEventEntry, LogLevel } from "../types/logTypes";
import { v4 as uuidv4 } from "uuid";

export function log(message: string, context: { [key: string]: any } = {}) {
  addLog({
    level: LogLevel.info,
    context: { message: message, ...context },
  });
}

export function warn(message: string, context: { [key: string]: any } = {}) {
  addLog({
    level: LogLevel.warn,
    context: { message: message, ...context },
  });
}

export function error(
  message: string | Error,
  context: { [key: string]: any } = {}
) {
  if (typeof message === "string") {
    context = { message: message, ...context };
  } else {
    context = {
      message: message.message,
      stack: message.stack || "",
      name: message.name,
      ...context,
    };
  }

  addLog({ level: LogLevel.error, context });
}

const addLog = (payload: AddLogPayload) => {
  const batch: LogEventEntry[] = JSON.parse(
    localStorage.getItem(LOGS_BATCH_KEY) || "[]"
  );

  const date = new Date();

  const event = {
    insert_id: uuidv4(),
    level: payload.level,
    tag: "Expert Messenger",
    device_time: toISOStringWithTimezone(date),
    utc_time: date.toISOString(),
    context: payload.context,
  };

  batch.push(event);
  localStorage.setItem(LOGS_BATCH_KEY, JSON.stringify(batch));

  if (batch.length >= LOGS_MAX_BATCH_SIZE) {
    store.dispatch(batchFull());
  } else {
    store.dispatch(logAdded());
  }
};
