import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import './NotificationsAllowModal.css';
import { closeModal } from '../../slices/modalSlice'

const NotificationsAllowModal =
    ({ closeModal }: PropsFromRedux) => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content notifications-allow-modal"  >
            <div>
                <img src="/images/notifications-modal-allow.png" />
                <h2>Never miss a chat with your clients</h2>
                <p>We’ll send notifications to your computer every time a client wants to chat with you. Enable our notifications to be available for clients.</p>

                <button className="primary" onClick={() => closeModal()}>Allow notifications</button>

            </div>
        </ReactModal>
    );


const connector = connect(null, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationsAllowModal);