import React from "react";
import { ChatExpertMessage } from "../../types/chatTypes";
import LightboxWrapper from "./LightboxWrapper";
import "./ExpertMessage.scss";
import { useSelector } from "react-redux";
import { sessionIdSelector } from "../../selectors/sessionSelectors";
import { API_BASE_URL } from "../../config";

interface messageProps {
  message: ChatExpertMessage;
  parentWidth: number | undefined;
  imgLoaded: () => void;
}

export const ExpertMessage: React.FunctionComponent<messageProps> = (props) => {
  const sessionId = useSelector(sessionIdSelector);
  return (
    <>
      {props.message.assetId && (
        <LightboxWrapper
          imageUrl={`${API_BASE_URL}/orders/${sessionId}/assets/chat_asset/${props.message.assetId}`}
          thumbnailUrl={`${API_BASE_URL}/orders/${sessionId}/assets/chat_asset/${props.message.assetId}`}
          width={Math.round(props.parentWidth ? props.parentWidth * 0.65 : 234)}
          thumbnailLoaded={props.imgLoaded}
        />
      )}
      {!props.message.assetId && (
        <div className="expert-message">{props.message.text}</div>
      )}
    </>
  );
};
