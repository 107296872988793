import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store";

import { isRingingSelector } from "../../../selectors/uiSelectors";
import Ringing from "./Ringing";
import { soundsSelector } from "../../../selectors/appSettingsSelector";
import * as appSettingsTypes from "../../../types/appSettingsTypes";

interface Props {
  isRinging: boolean;
  ringing: appSettingsTypes.Ringing;
  volume: number;
}

const AppSound = ({ isRinging, ringing, volume }: Props) => {
  return <Ringing isRinging={isRinging} ringing={ringing} volume={volume} />;
};

const mapState = (state: AppState) => {
    const soundSettings = soundsSelector(state);
  return {
    isRinging: isRingingSelector(state),
    ringing: soundSettings.ringing,
    volume: soundSettings.volume || 100,
  };
};

export default connect(mapState)(AppSound);
