import * as React from 'react';
import "./PromotionMessageButton.scss";

interface PromotionMessageButtonProps {
    onClick: () => void;
    isActive: boolean;
    isDisabled?: boolean;
}

const disabledTooltipText = "This client received a promotion from you. Once it’s expired or used you will be able to send another one.";

const PromotionMessageButton = (props: PromotionMessageButtonProps) => {
    let buttonClassName = "white promotion-message-button";
    let tooltip = "";
    if(props.isActive) {
        buttonClassName += " active";
    } else if(props.isDisabled) {
        tooltip = disabledTooltipText;
        buttonClassName += " disabled";
    }
    return <button data-tooltip-content={tooltip}
        data-tooltip-id="wem-tooltip"
        className={buttonClassName} 
        onClick={() => { if (!props.isDisabled) props.onClick(); }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="26.963" height="20.007" viewBox="0 0 26.963 20.007">
            <g id="Group_1448" transform="translate(.75)">
                <path className="present" id="Icon_metro-gift" fill="#303030" d="M18.306 8.378a9.7 9.7 0 0 0 1.2-.853 4.363 4.363 0 0 0 1.244-2.288 2.542 2.542 0 0 0-.625-2.275 2.379 2.379 0 0 0-1.727-.674 4.124 4.124 0 0 0-2.84 1.292A11.164 11.164 0 0 0 13.065 8a9.028 9.028 0 0 0-2.1-4.24 3.168 3.168 0 0 0-2.207-.96 2.321 2.321 0 0 0-1.666.662 2.766 2.766 0 0 0 .3 3.877 6.371 6.371 0 0 0 1.4 1.036H4.5v5.061h1.264v8.856h15.182v-8.853h1.265V8.378zm-1.742-3.792a2.743 2.743 0 0 1 1.83-.875 1.009 1.009 0 0 1 .721.257c.515.515.226 1.706-.618 2.55a8.913 8.913 0 0 1-3.053 1.859h-1a9.7 9.7 0 0 1 2.123-3.791zm-8.706.646a.971.971 0 0 1 .242-.761.919.919 0 0 1 .659-.245 1.746 1.746 0 0 1 1.2.543 7.032 7.032 0 0 1 1.595 3.081l.03.108-.108-.03A7.032 7.032 0 0 1 8.4 6.335a1.8 1.8 0 0 1-.539-1.1zm4.78 15.8H7.029v-7.593h5.609zm0-8.856H5.764V9.643h6.874v2.53zm7.043 8.856h-5.467v-7.593h5.467zm1.265-8.856h-6.732V9.643h6.732v2.53z" transform="translate(4.001 -2.288)" />
                <g className="plus" id="Group_1394" transform="translate(0 7)">
                    <path id="Line_45" d="M0 0L6 0"  transform="translate(0 3)" />
                    <path id="Path_1598" d="M0 6V0"  transform="translate(3)" />
                </g>
            </g>
        </svg>

    </button>
}

export default PromotionMessageButton;