import { ClientReadingData } from "../../types/clientTypes";
import { LastReadingCell, showIcon } from "./ClientList/LastReadingCell";
import {Tooltip} from "react-tooltip";
import './LastReadings.scss';
import RatingFace from "./RatingFace";
import { isKasambaApp } from "../../helpers/settingsHelper";
import { SessionType } from "../../types/clientListTypes";
import { textManager } from "../../config/textManager";

type LastReadingsProps = {
    items: ClientReadingData[];
    isLoading: boolean;
    viewedReadingsId: number[];
    readingClickHandler: (reading: ClientReadingData) => void;
}

const LastReadings = (props: LastReadingsProps) => {

const formatDuration = (durationInSecond?:number | null ) => {
    if (durationInSecond === null || durationInSecond === undefined) {
        return "-";
    }
    if (isKasambaApp) {
        return `${Math.ceil(durationInSecond / 60)}m`;   
    }
    else {
        const hour = Math.floor(durationInSecond / 3600).toString().padStart(2, '0');
        const min = Math.floor((durationInSecond % 3600) / 60).toString().padStart(2, '0');
        const sec = (durationInSecond % 60).toString().padStart(2, '0');
        return `${hour}:${min}:${sec}`;  
    }
    }
    const getRowClasses = (sessionId:number, sessionType:SessionType):string =>{
        return (props.viewedReadingsId.includes(sessionId) ? 'viewed ' : '')+(showIcon(sessionType)?"":"no-icon ")
    }

    return <div className="last-readings">
         <Tooltip id="client-reading-tooltip" data-tooltip-place="top-end" className="tool-tip-up"/>
        <table>
            <thead>
                <tr>
                    <th className="date-column">Date</th>
                    <th className="duration-column">Paid min</th>
                    <th className="cost-column">Earned</th>
                    <th className="rating-column">Rating</th>
                    <th className="view-column"></th>
                </tr>
            </thead>
            <tbody>
                {
                    props.items?.map((item, index) => (
                        <tr className={getRowClasses(item.sessionId, item.sessionType)} key={index}>
                            <td><LastReadingCell lastReadDate={item.sessionStartTime} lastSessionType={item.sessionType} /></td>
                            <td>{formatDuration(item.durationInSecond)}</td>
                            <td>{item.finalFee ? '$' + item.finalFee.toFixed(2) : '$0.00'}</td>
                            <td className={(item.rating === false || item.rating === true) ?'face-icon':'rating' }
                            >
                                {item.rating !==null ? <RatingFace value={item.rating} tooltip={item.ratingComment} /> : 'Unrated'}
                            </td>
                            <td onClick={() =>{if (item.sessionType === SessionType.Chat) {props.readingClickHandler(item)} } } className="view">
                                {item.sessionType === SessionType.Chat ? "View" : ""}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
        {!props.isLoading && (!props.items || props.items.length === 0) && <div className="no-items">{textManager.readings.noReadings}</div>}
    </div>;
};
export default LastReadings;