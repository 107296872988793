
export const content = {
    promotionMessages: {
        common: `Hi [client name],

Thanks for choosing me to speak with today.
As a thank you I would like to offer you a discount for our next chat reading.`,
        group: `Hi [client name],

`,
        percentage:

`This offer value is [value]% off. It will expire in [expire] days, and will apply automatically at the beginning of our next chat reading.

[advisor name]`,
        fixed:
`My reduced price is $[value] per minute. It will expire in [expire] days, and will apply automatically at the beginning of our next chat reading.

[advisor name]`
    }
};
