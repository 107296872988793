export enum LogLevel {
  info = "info",
  error = "error",
  warn = "warn",
}

export type AddLogPayload = {
    level: LogLevel;
    context: any;
};

export type LogEventEntry = {
  level: LogLevel;
  tag: string;
  device_time: string;
  utc_time: string;
  insert_id: string;
  context: { [key: string]: string };
};