import "core-js/stable";
import * as FullStory from "@fullstory/browser";
import "react-app-polyfill/ie11";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store, history, persistor } from "./store";
import { Provider } from "react-redux";
//import { ConnectedRouter } from "connected-react-router";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import ReactModal from "react-modal";
import { PersistGate } from "redux-persist/integration/react";
declare let FreshworksWidget: any;

String.prototype.format = function(...args: any[]) {
  let formatted = this;
  for (let i = 0; i < args.length; i++) {
      const regexp = new RegExp('\\{'+i+'\\}', 'gi');
      formatted = formatted.replace(regexp, args[i]);
  }
  return formatted.toString();
};

FullStory.init({
  orgId: "1IAW",
  devMode: process.env.REACT_APP_ENV !== "prod",
});

if (FreshworksWidget) {
  FreshworksWidget("hide", "launcher");
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Provider store={store}>
  <Router history={history}>
    {/* loading={<LoadingView />} */}
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Router>
</Provider>);
ReactModal.setAppElement("#root");

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
