import * as React from 'react';
import Spinner from './Spinner';
import { kasambaFormatDate } from '../../helpers/dateFormatHelper';
import { textManager } from '../../config/textManager';

type Props = {
    lastReading: Date | null,
    isReturningClient:boolean
}

const ClientLastReading = ({lastReading, isReturningClient}:Props) => {
    if(lastReading == null)
    {
        if(isReturningClient){
            return <Spinner size={25}/>;
        }
        
        return <>{textManager.readings.firstReading}</>;
    }

    return <>{textManager.readings.lastReading}{kasambaFormatDate(lastReading.toString())}</>;

};

export default ClientLastReading; 