import { FC } from "react";
import { InsightResponseItem } from "../../../services/api/models/insight";
import "./ComparisonWidget.scss";
import { Card } from "../../atoms/Card";
import { TooltipQuestionMark } from "../../atoms/dashboard/TooltipQuestionMark";

export const ComparisonWidget: FC<InsightResponseItem> = (props) => {
  return (
    <Card className="comparison-widget">
      <div className="comparison-widget-title">
        Return clients (last 30 days)
        <TooltipQuestionMark
          tooltipId="dashboard-widget-tooltip"
          text="A return client is a client who had a previous reading with you and returned for a new reading within the last 30 days" />
      </div>
      <div className="container">
        <div>{props.data.left}</div>
        {
          props.data.right &&
          <>
            <div className="divider"></div>
            <div>{props.data.right}</div>
          </>
        }
      </div>
    </Card>
  )
};