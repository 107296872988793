import { ServiceType } from "../services/api/models/my-advisor-dashboard";

export function calculatePercent(current: number, baseValue: number): number {
  if (current === baseValue) {
    return 0;
  }

  if (baseValue === 0) {
    return 100;
  }
  return ((current - baseValue) * 100) / baseValue;
}

export function calculatePercentToText(
  current: number,
  baseValue: number
): string {
  const percent = calculatePercent(current, baseValue);
  return formatPercentToText(percent);
}

export function formatPercentToText(percent: number): string {
  if (percent < 0) {
    return percent.toFixed(0) + "%";
  }
  return "+" + percent.toFixed(0) + "%";
}

export const earningItemTitleByType = (type: ServiceType): string => {
  const titles: Record<string, string> = {
    [ServiceType.offline]: "Standard",
    [ServiceType.standard]: "Standard",
    [ServiceType.rush]: "Rush delivery",
    [ServiceType.video]: "Live video",
    [ServiceType.chat]: "Live chat",
    [ServiceType.voice]: "Live voice",
    [ServiceType.tides]: "Tides",
  };

  return titles[type] || "";
};
