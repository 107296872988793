import { createSlice } from "@reduxjs/toolkit";
import { ModalState, ModalType } from "../types/modalTypes";

const initialState: ModalState = {
  modalType: null,
  modalTitle: '',
  modalContent: '',
  btnOkText: '',
  props: null
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: (create) => ({
    showAlertModal: create.preparedReducer(
      (modalTitle = 'title', modalContent = 'content', btnOkText = 'OK') =>
        ({ payload: { modalTitle, modalContent, btnOkText } }),
      (state, action) => ({
        ...state,
        modalType: ModalType.Alert,
        ...action.payload
      })
    ),
    showModal: create.preparedReducer(
      (modalType: ModalType, props: any = null) => ({ payload: { modalType, props } }),
      (state, action) => ({
        ...state,
        ...action.payload
      })
    ),
    closeModal: create.preparedReducer(
      (confirmed = true) => ({ payload: { confirmed } }),
      () => initialState
    )
  })
});

export const { showAlertModal, showModal, closeModal } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;