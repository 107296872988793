import * as React from 'react';
import './ExpertAvatar.css';

interface ExpertAvatarProps {
    expertAvatarUrl:string 
}


const ExpertAvatar = (props:ExpertAvatarProps) => {

    return <img alt="" src={props.expertAvatarUrl} className = "expert-avatar"/>
};

export default ExpertAvatar; 