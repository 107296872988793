import { createSlice } from "@reduxjs/toolkit";
import { ChatClientMessage, ChatExpertMessage, ChatState, ChatSystemMessage, RealTimeActivityLocation } from "../types/chatTypes";
import { FileUploadItemStatus } from "../components/atoms/FileUploadItem";

const initialState: ChatState = {
  realTimeMessage: "",
  isClientTyping: false,
  nextMessageId: 1,
  messages: { 0: new ChatExpertMessage("") },
  prevMessages: [],
  editableMessage: 0,
  submitFormFlag: false,
  files: {},
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: (create) => ({
    updateClientIsTyping: create.preparedReducer(
      (isClientTyping: boolean) => ({ payload: { isClientTyping } }),
      (state, action) => {
        state.isClientTyping = action.payload.isClientTyping;
      }
    ),
    clientCancelTyping: create.preparedReducer(
      (isClientTyping: boolean) => ({ payload: { isClientTyping } }),
      () => { }
    ),
    setRealTimeActivity: create.preparedReducer(
      (clientRealTimeActivity: string, location: RealTimeActivityLocation) =>
        ({ payload: { clientRealTimeActivity, location } }),
      () => { }
    ),
    setRealTimeMessage: create.preparedReducer(
      (realTimeMessage: string) => ({ payload: { realTimeMessage } }),
      () => { }
    ),
    addClientMessage: create.preparedReducer(
      (message: ChatClientMessage) => ({ payload: { message } }),
      (state, action) => {
        state.isClientTyping = false;
        state.messages[state.nextMessageId] = action.payload.message;
        state.prevMessages.push(state.nextMessageId)
        state.nextMessageId++;
      }
    ),
    addSystemMessage: create.preparedReducer(
      (message: ChatSystemMessage) => ({ payload: { message } }),
      (state, action) => {
        state.isClientTyping = false;
        state.messages[state.nextMessageId] = action.payload.message;
        state.prevMessages.push(state.nextMessageId)
        state.nextMessageId++;
      }
    ),
    setSubmitFormFlag(state) {
      state.submitFormFlag = true;
    },
    resetSubmitFormFlag(state) {
      state.submitFormFlag = false;
    },
    startTyping() { },
    stopTyping() { },
    sendFile: create.preparedReducer(
      (fileName: string, assetId: number, aspectRatio: number, type: string, caption?: string) =>
        ({ payload: { fileName, assetId, aspectRatio, caption, type } }),
      (state, action) => {
        if (state.files[action.payload.fileName]) {
          state.files[action.payload.fileName].status = FileUploadItemStatus.Sending;
        }
        state.messages[state.nextMessageId] = new ChatExpertMessage("", action.payload.assetId);
        state.prevMessages.push(state.nextMessageId);
        state.nextMessageId++;
      }
    ),
    addExpertNewMessage(state) {
      state.messages[state.nextMessageId] = new ChatExpertMessage();
      state.prevMessages.push(state.editableMessage);
      state.editableMessage = state.nextMessageId;
      state.nextMessageId++;
    },
    editExpertMessage: create.preparedReducer(
      (text: string) => ({ payload: text }),
      (state, action) => {
        const currentEditableMessage = state.messages[state.editableMessage] as ChatExpertMessage;
        const updatedMessage: ChatExpertMessage = {
          ...currentEditableMessage,
          createdOn:
            currentEditableMessage.text.length > 0
              ? currentEditableMessage.createdOn
              : Date.now(),
          text: action.payload,
        };
        state.messages[state.editableMessage] = updatedMessage;
      }
    ),
    commitExpertMessage() { },
    resetChatData: () => initialState,
    addFile: create.preparedReducer(
      (file: File) => ({ payload: { file } }),
      (state, action) => {
        state.files[action.payload.file.name] = {
          file: action.payload.file,
          progress: 0,
          status: FileUploadItemStatus.Uploading,
          cancelObject: null,
          assetId: null,
          aspectRatio: 0,
          startAt: new Date(),
          duration: null,
        }
      }
    ),
    fileSelected: create.preparedReducer(
      (file: File) => ({ payload: { file } }),
      () => { }
    ),
    fileUploaded: create.preparedReducer(
      (file: File, assetId: number, aspectRatio: number, caption?: string) =>
        ({ payload: { file, assetId, aspectRatio, caption } }),
      (state, action) => {
        const file = state.files[action.payload.file.name];
        if (file) {
          file.status = FileUploadItemStatus.Uploaded;
          file.cancelObject = null;
          file.assetId = action.payload.assetId;
          file.aspectRatio = action.payload.aspectRatio;
          file.caption = action.payload.caption;
          file.duration = action.payload.assetId
            ? new Date().getTime() - file.startAt.getTime()
            : null;
        }
      }
    ),
    fileUploadedError: create.preparedReducer(
      (fileName: string) => ({ payload: { fileName } }),
      (state, action) => {
        const file = state.files[action.payload.fileName];
        if (file) {
          file.status = FileUploadItemStatus.Error;
          file.cancelObject = null;
        }
      }
    ),
    fileUploadingProgress: create.preparedReducer(
      (file: File, progress: number) => ({ payload: { file, progress } }),
      (state, action) => {
        const file = state.files[action.payload.file.name];
        if (file) {
          file.progress = action.payload.progress;
        }
      }
    ),
    fileAddCancelObject: create.preparedReducer(
      (file: File, handler: any) => ({ payload: { file, cancelObject: handler } }),
      (state, action) => {
        const file = state.files[action.payload.file.name];
        if (file) {
          file.cancelObject = action.payload.cancelObject;
        }
      }
    ),
    fileRemoveAll() { },
    fileRemove: create.preparedReducer(
      (fileName: string) => ({ payload: { fileName } }),
      () => { },
    ),
    fileDeleteInfo: create.preparedReducer(
      (fileNames: string[]) => ({ payload: { fileNames } }),
      (state, action) => {
        action.payload.fileNames.forEach((item: string) => delete state.files[item]);
      }
    ),
    fileWrongSelected: create.preparedReducer(
      (file: File, errorType: string) => ({ payload: { file, errorType } }),
      () => { }
    )
  })
});

export const {
  updateClientIsTyping, clientCancelTyping, setRealTimeActivity, setRealTimeMessage, addClientMessage,
  addSystemMessage, setSubmitFormFlag, resetSubmitFormFlag, startTyping, stopTyping, sendFile, addExpertNewMessage,
  editExpertMessage, commitExpertMessage, resetChatData, addFile, fileSelected, fileUploaded, fileUploadedError, 
  fileUploadingProgress, fileAddCancelObject, fileRemoveAll, fileRemove, fileDeleteInfo, fileWrongSelected
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;