export enum HubRequestMessageId {
  SignIn = 1428,
  KeepAlive = 1025,
  SetAvailability = 2052,
  AcceptSession = 1077,
  DeclineSession = 1092,
  EndSession = 1078,
  DecreaseFee = 2080,
  OutgoingMessage = 1084,
  ShowText = 2041,
  OutgoingFile = 1099
}

export interface HubRequest {
  MSG: {
    HEADER: {
      msgID: string;
      sessionID: string;
      dest: number;
      source: number;
      appID: string;
      seqNum: "0"; //Not sure if needed
    };
    DATA: any;
  };
}

export function createHubRequest(
  messageId: string,
  body: { [key: string]: string } = {},
  source?: number,
  dest?: number): HubRequest {
  return {
    MSG: {
      HEADER: {
        msgID: messageId,
        sessionID: "0",
        dest: dest || 0,
        source: source || 0,
        appID: "3072",
        seqNum: "0"
      },
      DATA: body
    }
  }
}


