import { CURRENT_SITE_ID } from '../config';

export function askNotificationPermission(): Promise<NotificationPermission> {

    if (checkNotificationPromise()) {
        return Notification.requestPermission();
    }

    return new Promise((resolve, reject) => {
        if (!("Notification" in window)) {
            reject("This browser does not support desktop notification");
        }

        Notification.requestPermission(result => resolve(result));
    });
}

export function createIncomingChatNotification(title: string): Notification | null {
    if (typeof Notification === 'undefined') {
        return null;
    }

    if (document.hasFocus()) {
        return null;
    }

    var options = {
        silent: true,
        requireInteraction: true,
        icon: `/${CURRENT_SITE_ID?.toLocaleLowerCase()}_fav.png`,
        badge: `/${CURRENT_SITE_ID?.toLocaleLowerCase()}_fav.png`
    }

    var notification = new Notification(title, options);
    notification.onclick = () => {
        window.focus();
        notification.close();
    };
    return notification;
}

export function createNotification(text: string, action?: () => void, showAlways: boolean = false, tag: string | undefined = undefined) {
    if (typeof Notification === 'undefined') {
        return null;
    }

    if (!showAlways && document.hasFocus()) {
        return null;
    }

    var options = {
        tag: tag,
        silent: true,
        requireInteraction: true,
        icon: `/${CURRENT_SITE_ID?.toLocaleLowerCase()}_fav.png`,
        badge: `/${CURRENT_SITE_ID?.toLocaleLowerCase()}_fav.png`
    }

    var notification = new Notification(text, options);
    notification.onclick = () => {
        window.focus();
        notification.close();
        if (action) {
            action();
        }
    };

    return notification;
}

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
}
