import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import './QuitSessionModal.css';
import { closeModal } from '../../slices/modalSlice';
import { endSession } from '../../slices/sessionSlice';

const QuitSessionModal =
    ({ closeModal, endSession }: PropsFromRedux) => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content quit-session-modal"  >
            <div>
                <h2>Are you sure you want to quit this session?</h2>
                <div className="actions">
                    <button className="primary confirm" onClick={() => { closeModal(); endSession();}}>Yes, Quit</button>
                    <button className="primary" onClick={() => closeModal()}>No, Continue</button>
                </div>
            </div>
        </ReactModal>
    );


const connector = connect(null, { closeModal, endSession });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(QuitSessionModal);