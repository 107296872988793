import axios from "axios";
import { API_BASE_URL } from "../../config";
import { getDeviceId } from "../../config/device_id";
import mixpanel from "mixpanel-browser";
import { isKasambaApp } from "../../helpers/settingsHelper";

export type Response<T> = {
  result: T;
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(async (request) => {
  if (["POST", "PUT"].includes(request.method!) && request.data?.length > 0) {
    request.headers["Content-Type"] = "application/json;charset=utf-8";
  }

  request.headers["WEBDEVICE_ID"] = await getDeviceId();
  request.headers["WEB_CLIENT"] = isKasambaApp ? "KAWem" : "POWem";

  return request;
});

export default axiosInstance;
