import * as React from "react";
import "./Chat.css";
import ClientsTypingIndication from "../molecules/ClientsTypingIndication/ClientsTypingIndication";
import { ClientMessage } from "../atoms/ClientMessage";
import { SystemMessage } from "../atoms/SystemMessage";
import { ExpertMessage } from "../atoms/ExpertMessage";
import { connect, ConnectedProps } from "react-redux";
import {
  isClientTypingSelector,
  messagesSelector,
  realTimeMessageSelector,
  editableMessageSelector,
} from "../../selectors/chatSelectors";
import { AppState } from "../../store";
import {
  ChatMessage,
  ChatClientMessage,
  ChatMessageType,
  ChatExpertMessage,
  ChatSystemMessage,
} from "../../types/chatTypes";
import { default as TypingArea } from "../molecules/TypingArea";
import { SendButton } from "../atoms/SendButton";
import { setSubmitFormFlag } from "../../slices/chatSlice";
import { isSessionEndedSelector } from "../../selectors/sessionSelectors";
import SessionSummary from "../molecules/SessionSummary";
import { fontsSelector } from "../../selectors/appSettingsSelector";
import { fontSizeClass } from "../../helpers/settingsHelper";
import { filesSelector } from "../../selectors/chatSelectors";
import { FileUploadItemStatus } from "../atoms/FileUploadItem";

const mapState = (state: AppState) => ({
  isClientTyping: isClientTypingSelector(state),
  messages: messagesSelector(state),
  realTimeMessage: realTimeMessageSelector(state),
  editableMessage: editableMessageSelector(state),
  nextMessageId: 1,
  prevMessages: [],
  isSessionEnded: isSessionEndedSelector(state),
  fontSettings: fontsSelector(state),
  filesSelector: filesSelector(state),
});

const connector = connect(mapState, {
  setSubmitFormFlag: setSubmitFormFlag,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type ownState = {
  emojiIsOpen: boolean;
  parentwidth: number | undefined;
};

class Chat extends React.Component<PropsFromRedux, ownState> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      emojiIsOpen: false,
      parentwidth: 0,
    };
  }
  public node = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.scrollToBottom();
    if (this.node !== null) {
      this.setState({
        parentwidth: this.node.current?.getBoundingClientRect().width,
      });
    }
  }
  componentDidUpdate() {
    this.scrollToBottom();
    if (this.node !== null) {
      const width = this.node.current?.getBoundingClientRect().width;

      if (this.state.parentwidth !== width) {
        this.setState({ parentwidth: width });
      }
    }
  }

  scrollToBottom = () => {
    if (this.node.current) {
      this.node.current.scrollTop = this.node.current?.scrollHeight;
    }
  };

  isExistsFileReadyToSend() {
    let result = false;
    if (this.props.filesSelector) {
      Object.keys(this.props.filesSelector).forEach(
        (value: string, index: number) => {
          if (
            this.props.filesSelector[value].assetId &&
            this.props.filesSelector[value].status ===
              FileUploadItemStatus.Uploaded
          ) {
            result = true;
          }
        }
      );
    }
    return result;
  }

  render() {
    return (
      <div className="session-page__chat">
        <div className="session-page__chat-container">
          <div
            className={
              "messages-container " +
              fontSizeClass(this.props.fontSettings.size)
            }
            ref={this.node}
          >
            {this.props.messages.map((message: ChatMessage, i: number) =>
              message.type === ChatMessageType.Client ? (
                <div
                  className="client-message-container message-max-width"
                  key={i}
                >
                  <ClientMessage
                    message={message as ChatClientMessage}
                    key={i}
                    parentWidth={this.state.parentwidth}
                    imgLoaded={() => this.scrollToBottom()}
                  />
                </div>
              ) : message.type === ChatMessageType.Expert ? (
                <div
                  className="expert-message-container message-max-width"
                  key={i}
                >
                  <ExpertMessage
                    message={message as ChatExpertMessage}
                    key={i}
                    parentWidth={this.state.parentwidth}
                    imgLoaded={() => this.scrollToBottom()}
                  />
                </div>
              ) : (
                <SystemMessage
                  text={(message as ChatSystemMessage).text}
                  key={i}
                />
              )
            )}

            <div className="typing-indicator-container">
              {this.props.isClientTyping && <ClientsTypingIndication />}
            </div>
          </div>
          {this.props.isSessionEnded ? (
            <SessionSummary />
          ) : (
            <div className="typing-container">
              <TypingArea />
              <div>
                <SendButton
                  isdisabled={
                    this.props.editableMessage.text.length === 0 &&
                    !this.isExistsFileReadyToSend()
                  }
                  onClick={this.props.setSubmitFormFlag}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connector(Chat);
