import * as React from "react";
import "./HelpWidgetIcon.css";
import RedDot from "./RedDot";

interface HelpWidgetIconProps {
  onclick?: () => void;
  isRedDot?: boolean;
}

const HelpWidgetIcon = (props: HelpWidgetIconProps) => {
  return (
    <i
      className={
        "help-widget-icon " +
        (props.isRedDot ? "with-red-point" : "without-red-point")
      }
      onClick={props.onclick}
    ></i>
  );
};

export default HelpWidgetIcon;
