import { FC } from "react";
import './BadgeAndText.scss'

type BadgeProps = {
  count: number;
  text: string;
};

export const BadgeAndText: FC<BadgeProps> = (props) => {
  return (
    <div className="badgeAndText">
      <div className="badge">{props.count}</div>
      <div className="text">{props.text}</div>
    </div>
  );
};
