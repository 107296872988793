import * as React from 'react';
import "./TopBarMenuItem.scss"

export interface ITopBarMenuItemProps {
    isActive:boolean
  clickHandler: () => void
  children?: React.ReactNode
}

export default class TopBarMenuItem extends React.PureComponent<ITopBarMenuItemProps> {
  public render() {
    return (
      <div className={"top-bar-menu-item" + (this.props.isActive?" active":"")} onClick={this.props.isActive?()=>{}:this.props.clickHandler}>
         {this.props.children} {/*// TODO: upgrade v18 */}
      </div>
    );
  }
}
