import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../store';
import { closeModal } from '../../slices/modalSlice'

const AlertModal =
    (props: PropsFromRedux) => (
        <ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content"  >
            {props.title &&
                <h1 style={{ marginBottom: '20px' }}>{props.title}</h1>
            }
            <div style={{whiteSpace: 'pre-line'}} >{props.content}</div>
            {props.btnOkText &&
                <button style={{ marginTop: '20px' }} className="primary" onClick={() => props.closeModal(false)} >{props.btnOkText}</button>
            }
        </ReactModal>
    );

const mapState = (state: AppState) => ({
    title: state.modal.modalTitle,
    content: state.modal.modalContent,
    btnOkText: state.modal.btnOkText
});

const connector = connect(mapState, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AlertModal);