import "./SessionSummary.scss";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { closeSession } from "../../slices/sessionSlice";
import { showScheduledMessageForm } from "../../actions/messageActions";
import Spinner from "../atoms/Spinner";
import { ScheduledMessageFormParams } from "../../types/messageTypes";
import { Tooltip } from "react-tooltip";
import { ServerUserStatus } from "../../types/userTypes";

const SessionSummary = ({
  sessionEnded,
  summary,
  closeSession,
  scheduledMessageLink,
  showScheduledMessageForm,
  isMessageScheduled,
  clientNoteName,
  expertStatus,
  isClientLifecycleTypeLoading,
}: PropsFromRedux) => {
  if (!sessionEnded) {
    return null;
  }

  const scheduleMessageLinkHandler = () => {
    if (scheduledMessageLink == null || isClientLifecycleTypeLoading) {
      return;
    }

    showScheduledMessageForm(
      parseScheduledMessageLink(scheduledMessageLink, clientNoteName)
    );
  };

  const wrapText = (text: string): string => {
    const parts = text.split(" ");

    return [
      "<span>",
      "<strong>",
      parts.slice(0, 3).join(" "),
      "</strong> ",
      parts.slice(3).join(" "),
      "</span>",
    ].join('');
  };

  return (
    <div className="session-page__session-summary">
      <p>{summary?.title}</p>
      <div className="info">
        {summary?.large_numbers.map((item, i) => (
          <div key={i}>
            <label>{item.label}</label>
            <span>{item.value}</span>
          </div>
        ))}
        <div className="earnings">
          <label>{summary?.total.label}</label>
          <span>{summary?.total.value}</span>
        </div>
      </div>
      <div className="small-info">
        {summary?.small_numbers.map((item, i) => (
          <span key={i}>
            {item.label}: {item.value}
          </span>
        ))}
        {summary?.small_numbers.some((item) => !!item.help_text) && (
          <>
            <i data-tooltip-id="summary_tooltip" className="help-icon"></i>
            <Tooltip id="summary_tooltip">
              <div
                className="tooltip-text"
                dangerouslySetInnerHTML={{
                  __html: summary.small_numbers
                    .filter((x) => !!x.help_text)
                    .map((x) => wrapText(x.help_text!))
                    .join("<br />"),
                }}
              ></div>
            </Tooltip>
          </>
        )}
      </div>

      {summary?.alert && (
        <div className="summary-alert">
          <span className="summary-alert_label">{summary.alert.label}</span>
          <span className="summary-alert_hint">{summary.alert.hint}</span>
        </div>
      )}

      {expertStatus !== ServerUserStatus.Busy &&
        scheduledMessageLink != null && (
          <div className="scheduled-link">
            <a href="#" onClick={scheduleMessageLinkHandler}>
              Schedule a message
            </a>
            {isClientLifecycleTypeLoading && <Spinner size={8} />}
          </div>
        )}
      {isMessageScheduled && (
        <div className="message-scheduled">Message scheduled</div>
      )}
      <button onClick={() => closeSession()}>CLOSE CHAT</button>
    </div>
  );
};

const parseScheduledMessageLink = (
  url: string,
  clientNoteName?: string
): ScheduledMessageFormParams => {
  const qs = new URL(url).search;
  const urlParams = new URLSearchParams(qs);
  return {
    clientId: Number(urlParams.get("clientId")),
    clientAlias: urlParams.get("clientAlias") || "",
    sessionDuration: Number(urlParams.get("chatMinutes")),
    sessionEndTime: new Date(urlParams.get("chatEndTime") || ""),
    clientNoteName,
  };
};

const mapState = (state: AppState) => ({
  sessionEnded: state.session.sessionEndingUtcTime,
  expertStatus: state.user.serverUserStatus,
  summary: state.session.sessionSummary,
  clientNoteName: state.note.title?.clientName,
  scheduledMessageLink: state.session.scheduledMessageLink,
  isMessageScheduled: state.session.isMessageScheduled,
  isClientLifecycleTypeLoading: state.session.isClientLifecycleTypeLoading,
});

const connector = connect(mapState, { closeSession, showScheduledMessageForm });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SessionSummary);
