import "./ClientMessage.css";
import { ChatClientMessage } from "../../types/chatTypes";
import LightboxWrapper from "./LightboxWrapper";
import { API_BASE_URL } from "../../config";
import { useSelector } from "react-redux";
import { sessionIdSelector } from "../../selectors/sessionSelectors";

interface messageProps {
  message: ChatClientMessage;
  parentWidth: number | undefined;
  imgLoaded: () => void;
}

export const ClientMessage: React.FunctionComponent<messageProps> = (props) => {
  const sessionId = useSelector(sessionIdSelector);
  return (
    <>
      {props.message.assetId && (
        <LightboxWrapper
          imageUrl={`${API_BASE_URL}/orders/${sessionId}/assets/chat_asset/${props.message.assetId}`}
          thumbnailUrl={`${API_BASE_URL}/orders/${sessionId}/assets/chat_asset/${props.message.assetId}`}
          width={Math.round(props.parentWidth ? props.parentWidth * 0.65 : 234)}
          thumbnailLoaded={props.imgLoaded}
          caption={props.message.text}
        />
      )}
      {!props.message.assetId && (
        <div className="client-message">{props.message.text}</div>
      )}
    </>
  );
};
