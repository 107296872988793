import { replace } from "redux-first-history";
import { put, takeEvery } from "redux-saga/effects";
import { RoutesM } from "../router";
import { gotoRevenue } from "../slices/revenueSlice";

function* handleOpenRevenuePage() {
    console.log("handleOpenRevenuePage");
    yield put(replace(RoutesM.Revenue));
}
  
export default function* revenueRoot() {
    yield takeEvery(gotoRevenue.type, handleOpenRevenuePage);
}