import { createSlice } from "@reduxjs/toolkit";
import { NoteState } from "../types/noteTypes";
import { NotesResponse, NotesTitle } from "../services/api/noteApi";

const initialState: NoteState = {
  lastUpdated: null,
  text: null,
  title: null,
  isLoading: false,
  isSavingClientDetails: false,
};

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: (create) => ({
    resetNoteData: () => initialState,
    noteLoaded: create.preparedReducer(
      (note: NotesResponse) => {
        const emptyObject: NotesTitle = {
          clientName: "",
          birthDate: null,
        };
        return {
          payload: {
            title: note?.title || emptyObject,
            text: note?.text,
            lastUpdated: note?.lastUpdated,
          },
        };
      },
      (state, action) => ({ ...initialState, ...action.payload })
    ),
    updateClientDetails: create.preparedReducer(
      (clientId: number, clientName: string, name: string, oldName: string, birthDate: Date | null, oldBirthDate: Date | null) =>
        ({ payload: { clientId, clientName, name, oldName, birthDate, oldBirthDate } }),
      (state, action) => {
        state.isSavingClientDetails = true;
        state.title = {
          clientName: action.payload.name,
          birthDate: action.payload.birthDate,
        }
      }
    ),
    updatedClientDetails: create.preparedReducer(
      (clientId: number, clientName: string, clientDob: Date | null) => ({ payload: { clientId, clientName, clientDob } }),
      (state) => {
        state.isSavingClientDetails = false;
      }
    ),
    updateNote: create.preparedReducer(
      (clientId: number, clientName: string, text: string, plainText: string, isAdded: boolean) =>
        ({ payload: { clientId, clientName, text, plainText, isAdded } }),
      () => { }
    ),
    noteUpdateSuccess() { },
    noteUpdateFailed() { },
    loadNote: create.preparedReducer(
      (clientId: number) => ({ payload: { clientId } }),
      (state) => {
        state.isLoading = true;
      }
    ),
    noteSetIsLoading: create.preparedReducer(
      (value: boolean) => ({ payload: value }),
      (state, action) => {
        state.isLoading = action.payload;
      }
    )
  })
})

export const {
  resetNoteData, noteLoaded, updateClientDetails, updatedClientDetails, updateNote, noteUpdateSuccess, noteUpdateFailed, loadNote, noteSetIsLoading
} = noteSlice.actions;
export const noteReducer = noteSlice.reducer;