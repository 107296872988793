import { Routes, Route } from "react-router-dom";

import ClientList from "../pages/ClientList";
import Dashboard from "../pages/Dashboard";
import Init from "../pages/Init";
import Session from "../pages/Session";
import SignIn from "../pages/SignIn";
import Revenue from "../pages/Revenue";

export const RoutesM = {
  Init: "/init",
  Clients: "/clients",
  Session: "/session",
  Dashboard: "/dashboard",
  SignIn: "/sign-in",
  Revenue: "/revenue",
};

const routes = (
  <Routes>
    <Route path={RoutesM.Init} Component={Init} />
    <Route path={RoutesM.Clients} Component={ClientList} />
    <Route path={RoutesM.Session} Component={Session} />
    <Route path={RoutesM.Dashboard} Component={Dashboard} />
    <Route path={RoutesM.SignIn} Component={SignIn} />
    <Route path={RoutesM.Revenue} Component={Revenue} />
  </Routes>
);

export default routes;
