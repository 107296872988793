import Pubnub, { PublishParameters } from "pubnub";
import { store } from "../store";
import {
  ChatClientMessage,
  ChatMessageType,
  ChatSystemMessage,
} from "../types/chatTypes";
import {
  addClientMessage,
  addSystemMessage,
  updateClientIsTyping,
} from "../slices/chatSlice";
import {
  CurrentUserResponse,
} from "../services/api/userApi";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  initFee,
  sessionConnected,
  sessionEnded,
  sessionPaused,
  sessionResumed,
  sessionStarted,
  showSessionSummary,
  setSessionCloseReason,
  networkStatusChanged,
  networkStatusSelector,
} from "../slices/sessionSlice";
import {
  clientSelector,
  isSessionEndedSelector,
  isSessionPausedSelector,
  sessionChannelIdSelector,
  sessionUuidSelector,
} from "../selectors/sessionSelectors";
import { PubNubConfig } from "../types/pubNubTypes";
import {
  processMessageQueue,
  processAdminMessage,
  processSystemMessage,
  processUserMessage,
  sendPubNubMessage,
  initPubNubSession,
} from "../actions/pubNubActions";
import { ClientSupportedFeatures } from "../types/clientTypes";
import {
  AdminMessageState,
  MessageKind,
  MessageReason,
  PubNubMessage,
} from "../types/messageTypes";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { Client, NetworkStatus } from "../types/sessionTypes";
import { userSelector } from "../selectors/userSelectors";
import * as loggly from "../services/logger";

let pubNub: Pubnub | null = null;
let listener: Pubnub.ListenerParameters | null = null;
let hiredTimeout: NodeJS.Timeout;

let messageQueue: Array<PubNubMessage> = [];

const mapCategoryToNetworkStatus = (category: string): NetworkStatus => {
  switch (category) {
    case Pubnub.CATEGORIES.PNTimeoutCategory:
    case Pubnub.CATEGORIES.PNNetworkDownCategory:
    case Pubnub.CATEGORIES.PNNetworkIssuesCategory:
      return NetworkStatus.networkDown;
    case Pubnub.CATEGORIES.PNConnectedCategory:
    case Pubnub.CATEGORIES.PNReconnectedCategory:
      return NetworkStatus.connected;
    default:
      return NetworkStatus.unknown;
  }
};

const createListener = (): Pubnub.ListenerParameters => ({
  message: (props) => {
    const { message } = props;
    processMessage(message);
  },
  status: (event) => {
    const networkStatus = mapCategoryToNetworkStatus(event.category);
    store.dispatch(networkStatusChanged(networkStatus));
  },
  presence: function (event) {
    if (event.action === 'timeout') {
      loggly.error('Error PubNub heartbeat timeout:', event);
    }
  }
});

const processMessage = (message: any) => {
  const { admin, user, sm } = message;

  if (admin) {
    store.dispatch(processAdminMessage(admin));
  }

  if (user) {
    store.dispatch(processUserMessage(user));
  }

  if (sm) {
    store.dispatch(processSystemMessage(sm));
  }
};

function* connectChatProvider(channelId: string, pubNubConfig: PubNubConfig) {
  disconnectChatProvider();
  pubNub = new Pubnub({
    subscribeKey: pubNubConfig.subscribe_key,
    publishKey: pubNubConfig.publish_key,
    userId: pubNubConfig.uuid,
    authKey: pubNubConfig.auth_key,
    presenceTimeout: pubNubConfig.presence_timeout,
    heartbeatInterval: pubNubConfig.heartbeat_interval,
    ssl: true,
    restore: true,
  });
  listener = createListener();
  pubNub.addListener(listener);
  pubNub.subscribe({ channels: [channelId], withPresence: true });
  pubNub.fetchMessages({ channels: [channelId] }, (status, response) => {
    response?.channels[channelId]?.forEach((props) => {
      processMessage(props.message);
    });
  });

  const result: Pubnub.HereNowResponse = yield call(() => pubNub!.hereNow({ channels: [channelId], includeUUIDs: true }));
  console.log(result.channels[channelId]);

  if (result.channels[channelId].occupancy === 0) {
    disconnectChatProvider();
    yield put(sessionEnded());
  }
};

export function disconnectChatProvider() {
  if (!pubNub) {
    return;
  }
  if (listener) {
    pubNub.removeListener(listener);
  }
  pubNub.stop();
  pubNub = null;
}

function* handleInitPubNubSession(): any {
  messageQueue = [];

  const client: Client = yield select(clientSelector);
  const advisor: CurrentUserResponse = yield select(userSelector);

  yield put(initFee(client.analytics.ppm * 60));
  yield call(connectChatProvider, client.channel_id, client.pubnub);

  yield put(
    sessionConnected(
      client.order_id,
      client.pubnub.uuid,
      client.channel_id
    )
  );

  const eventData: any = {
    "buyer id": client.buyer_id,
    "buyer name": client.buyer_nickname,
    "order id": client.order_id,
    "chat initial minutes": client.durationMinutes,
    "chat initial credit": client.tryout
      ? 0.0
      : (+client.analytics.ppm || 0.0) * client.durationMinutes,
    "advisor score": advisor.score,
  };

  for (const key in client.analytics) {
    eventData[key.replace("_", " ")] = client.analytics[key];
  }

  if (client.tryout) {
    eventData["tryout"] = true;
  }

  mixpanelService.trackEvent(TrackEvents.ChatAnswered, eventData);
}

function* handleProcessAdminMessage(
  action: ReturnType<typeof processAdminMessage>
) {
  const message = action.payload;
  const { state } = message;

  switch (state) {
    case AdminMessageState.started:
      const {
        free_duration,
        paid_duration,
        supported_attachment_types,
        tryout,
      } = message;
      const isPaused: boolean = yield select(isSessionPausedSelector);
      if (isPaused) {
        yield put(sessionResumed(free_duration, paid_duration, tryout));
      } else {
        const isSessionEnded: boolean = yield select(isSessionEndedSelector);
        if (!isSessionEnded) {
          yield put(
            sessionStarted(
              free_duration,
              paid_duration,
              tryout,
              supported_attachment_types.map((x) => <ClientSupportedFeatures>x)
            )
          );
        } else {
          console.log('Received "start session" from pubnub, but session was already ended.');
        }
      }

      break;
    case AdminMessageState.paused:
      const { advisor_enable_hangup_in, reason } = message;
      if (reason === MessageReason.timer) {
        yield put(sessionPaused(advisor_enable_hangup_in));
      }
      break;
    case AdminMessageState.ended:
      clearTimeout(hiredTimeout);
      yield put(sessionEnded(Date.now()));
      disconnectChatProvider();

      const { advisor_summary_screen } = message;

      if (advisor_summary_screen.hangup_error) {
        yield put(
          setSessionCloseReason(advisor_summary_screen.hangup_error.label)
        );
      }

      yield put(showSessionSummary(advisor_summary_screen));
      break;
  }
}

function* handleProcessUserMessage(
  action: ReturnType<typeof processUserMessage>
) {
  const message = action.payload;
  const ownUuid: string = yield select(sessionUuidSelector);
  const { kind, uuid } = message;

  if (parseInt(ownUuid, 10) === parseInt(uuid, 10)) return;

  switch (kind) {
    case MessageKind.text:
      const { body } = message;
      yield put(addClientMessage(new ChatClientMessage(body)));
      break;
    case MessageKind.asset:
      const {
        body: { asset_id, caption },
      } = message;
      yield put(
        addClientMessage(new ChatClientMessage(caption || "", asset_id))
      );
      break;
    case MessageKind.notification:
      const { reason } = message;
      switch (reason) {
        case MessageReason.startTyping:
          yield put(updateClientIsTyping(true));
          break;
        case MessageReason.stopTyping:
          yield put(updateClientIsTyping(false));
          break;
      }
      break;
  }
}

function* handleProcessSystemMessage(
  action: ReturnType<typeof processSystemMessage>
) {
  const ownUuid: string = yield select(sessionUuidSelector);

  if (!action.payload.receiver_ids.includes(parseInt(ownUuid, 10))) return;

  const message: ChatSystemMessage = {
    text: action.payload.body,
    type: ChatMessageType.System,
    createdOn: Date.now(),
  };
  yield put(addSystemMessage(message));
}

function* handleSendMessage(action: ReturnType<typeof sendPubNubMessage>) {
  const networkStatus: NetworkStatus = yield select(networkStatusSelector);
  if (networkStatus === NetworkStatus.networkDown) {
    if (action.payload.kind !== MessageKind.notification) {
      messageQueue.push(action.payload);
    }
    return;
  }

  messageQueue.push(action.payload);
  yield put(processMessageQueue());
}

function* handleProcessMessageQueue() {
  if (!messageQueue.length) {
    return;
  }

  const channelId: string = yield select(sessionChannelIdSelector);
  const uuid: string = yield select(sessionUuidSelector);

  while (messageQueue.length) {
    const message = messageQueue.shift();
    yield call(sendMessage, channelId, uuid, message!);
  }
}

function* sendMessage(channelId: string, uuid: string, message: PubNubMessage) {
  const params: PublishParameters = {
    channel: channelId,
    sendByPost: true,
    storeInHistory: message.kind !== MessageKind.notification,
    message: {
      user: {
        kind: message.kind,
        body: message.body,
        reason: message.reason,
        uuid: parseInt(uuid, 10),
      },
    },
  };

  try {
    yield call(() => pubNub?.publish(params));
  } catch (status: any) {
    if (!status.error || message.kind === MessageKind.notification) {
      return;
    }
    messageQueue.push(message);
    store.dispatch(processMessageQueue());
  }
}

export default function* pubNubRoot() {
  yield takeLatest(initPubNubSession.type, handleInitPubNubSession);
  yield takeEvery(processAdminMessage.type, handleProcessAdminMessage);
  yield takeEvery(processUserMessage.type, handleProcessUserMessage);
  yield takeEvery(processSystemMessage.type, handleProcessSystemMessage);
  yield takeEvery(sendPubNubMessage.type, handleSendMessage);
  yield takeEvery(
    [
      (action: any) =>
        action.type === networkStatusChanged.type &&
        action.payload === NetworkStatus.connected,
      processMessageQueue.type,
    ],
    handleProcessMessageQueue
  );
}
