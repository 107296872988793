import * as React from "react";
import "./ScheduleButton.scss";

interface IScheduleButtonProps {
  click: (a: number) => void;
  rowid: number;
}

const ScheduleButton: React.FunctionComponent<IScheduleButtonProps> = (
  props
) => {
  function onclickHandler(e: any) {
    e.stopPropagation();
    props.click(props.rowid);
  }
  return (
    <>
      <button className="cl-shedule-button" onClick={onclickHandler}>
        <i></i>Message
      </button>
    </>
  );
};

export default ScheduleButton;
