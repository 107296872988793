
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import './ForgotPasswordModal.scss';
import { closeModal } from '../../slices/modalSlice';

import { useState } from "react";
import { AppState } from '../../store';
import { sendResetPassword } from '../../slices/authorizationSlice';
import { textManager } from '../../config/textManager';


const ForgotPasswordModal =
    (props: PropsFromRedux) => {
        const [email, setEmail] = useState(""+props.formParams);

        const handleSend = () => { 
            props.resetPassword(email);
            props.closeModal();
        }


        const buttonDisabled =!email ;
        return (<ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content forgot-password-modal"  >
            <div>
                <h2>Reset Password</h2>
                <p>
                    Submit your email address and we'll send you a link to reset your password.
                </p>
                <form className="email-form" onSubmit={handleSend}>
                    <div className="input-container">
                        <label className="input-label">Email</label>
                        <input
                            className="input-field"
                            type="email"
                            placeholder={textManager.login.emailPlaceholder}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="button-container">
                        <button
                            className={"button"}
                            disabled={buttonDisabled}
                            type="submit"
                        >
                            SUBMIT
                        </button>
                    </div>
            
                </form>
                <div className="back-to-login">
                    <span onClick={() => { props.closeModal() }}>Back to log in</span>
                </div>
            </div>
        </ReactModal>
        );
    }

    const mapState = (state: AppState) => ({
        formParams: state.modal.props as string,
    });

const connector = connect(
    mapState,
    { closeModal,  resetPassword:sendResetPassword });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ForgotPasswordModal);