import React from "react";
import "./SystemMessage.css";

interface messageProps {
  text: string;
}

export const SystemMessage: React.FunctionComponent<messageProps> = (props) => (
  <div
    className="system-message"
    dangerouslySetInnerHTML={{ __html: props.text }}
  ></div>
);
