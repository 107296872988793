import React, { useState } from "react";
import "./SlidingSegmentedControl.scss";

export type Props = {
  segments: string[];
  onSegmentChanged: (segment: string) => void;
};

export const SlidingSegmentedControl: React.FC<Props> = ({
  segments,
  onSegmentChanged,
}: Props) => {
  const [selectedSegment, setSelectedSegment] = useState<number>(0);

  const handleSegmentChange = (segment: string, index: number) => {
    setSelectedSegment(index);
    onSegmentChanged(segment);
  };

  return (
    <div className="sliding-segmented-control">
      {segments.map((segment, index) => (
        <div
          key={segment}
          className={`segment ${selectedSegment === index ? "selected" : ""}`}
          onClick={() => handleSegmentChange(segment, index)}
        >
          {segment}
        </div>
      ))}
    </div>
  );
};
