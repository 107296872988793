import fingerprintjs from "fingerprintjs2";
import { getDataFromStorage, saveDataToStorage } from "./util";

const delayDeviceId = (resolve: (fingerprint: string) => void) => {
  setTimeout(() => {
    fingerprintjs.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = fingerprintjs.x64hash128(values.join(""), 31);
      saveDataToStorage("DEVICE_ID", fingerprint);
      resolve(fingerprint);
    });
  }, 500);
};

const deviceId = (): Promise<string> =>
  new Promise((resolve) => {
    const dId = getDataFromStorage("DEVICE_ID");
    if (dId) {
      resolve(dId);
      return;
    }

    delayDeviceId(resolve);
  });

export const getDeviceId = async (): Promise<string | null> => {
  try {
    return await deviceId();
  } catch (e) {
    return null;
  }
};
