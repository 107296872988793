import { AppState } from "../../store";
import { ModalType } from "../../types/modalTypes";
import { InternalErrorModal } from "../modals/InternalErrorModal";
import { connect, ConnectedProps } from "react-redux";
import { ConnectionErrorModal } from "../modals/ConnectionErrorModal";
import AlertModal from "../modals/AlertModal";
import ScheduledMessageModal from "../modals/ScheduledMessageModal";
import NotificationsAllowModal from "../modals/NotificationsAllowModal";
import ReactModal from "react-modal";
import NotificationsBlockedModal from "../modals/NotificationsBlockedModal";
import OnboardSlidesModal from "../modals/OnboardSlidesModal";
import FeedbackForm from "../modals/FeedbackForm";
import QuitSessionModal from "../modals/QuitSessionModal";
import BlockClientConfirmModal from "../modals/BlockClientConfirmModal";
import BlockClientHighValuedModal from "../modals/BlockClientHighValuedModal";
import SlidesModal from "../modals/SlidesModal";
import GroupMessageModal from "../modals/GroupMessageModal";
import GroupMessageConfirmationModal from "../modals/GroupMessageConfirmationModal";
import { BlacklistAlertModal } from "../modals/BlacklistAlertModal";
import WaitingClientModal from "../modals/WaitingClientModal";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
import AcceptNewTermsAndPoliciesModal from "../modals/AcceptNewTermsAndPoliciesModal";
import { UnauthorizedModal } from "../modals/UnauthorizedModal";
import SelectCouponModal from "../modals/SelectCouponModal";


const MODAL_COMPONENTS = {
    [ModalType.InternalError]: InternalErrorModal,
    [ModalType.ConnectionError]: ConnectionErrorModal,
    [ModalType.Alert]: AlertModal,
    [ModalType.ScheduledMessage]: ScheduledMessageModal,
    [ModalType.NotificationsAllow]: NotificationsAllowModal,
    [ModalType.NotificationsBlocked]: NotificationsBlockedModal,
    [ModalType.OnboardSlides]: OnboardSlidesModal,
    [ModalType.FeedbackForm]: FeedbackForm,
    [ModalType.QuitSession]: QuitSessionModal,
    [ModalType.BlockClientConfirm]: BlockClientConfirmModal,
    [ModalType.BlockClientHighValued]: BlockClientHighValuedModal,
    [ModalType.Slides]: SlidesModal,
    [ModalType.GroupMessage]: GroupMessageModal,
    [ModalType.GroupMessageConfirm]: GroupMessageConfirmationModal,
    [ModalType.BlacklistAlert]: BlacklistAlertModal,
    [ModalType.WaitingClient]: WaitingClientModal,
    [ModalType.ForgotPassword]: ForgotPasswordModal,
    [ModalType.AcceptNewTermsAndPolicies]: AcceptNewTermsAndPoliciesModal,
    [ModalType.Unauthorized]: UnauthorizedModal,
    [ModalType.SelectCoupon]: SelectCouponModal
}

const ModalRoot = (props: PropsFromRedux) => {
    if (props.modalType == null) {
        return null;
    }

    if (props.modalType == ModalType.Empty) {
        return <ReactModal isOpen={true} overlayClassName="modal-overlay" className="hidden-modal"></ReactModal>;
    }

    const SpecificModal = MODAL_COMPONENTS[props.modalType];
    return <SpecificModal />;
};

const mapState = (state: AppState) => state.modal;

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(ModalRoot); 