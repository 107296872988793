import K3Login from "../components/molecules/login/K3Login";
import styles from "./SignIn.module.scss";

 const SignIn = () => {

  return (
    <div className={styles.container}>
      <div className={styles["image-container"]}>
        <img
          src="/images/mandala.svg"
          alt="mandala img"
          className={styles.mandala}
        />
      </div>
      <K3Login />
    </div>
  );
};

export default SignIn;