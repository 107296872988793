import Countdown, { zeroPad } from "react-countdown";
import "./IncomingChatPopup.css";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import {
  callingClientSelector,
  clientAskToStartSelector,
} from "../../selectors/sessionSelectors";
import { acceptSession, missedSession, ringDataSelector } from "../../slices/sessionSlice";
import { CHAT_RING_DURATION } from "../../config";

const IncomingChatPopup = ({
  client,
  clientAskToStart,
  acceptSession,
  ringData,
}: PropsFromRedux) => {
  return (
    <div className={"incoming-chat-popup" + (clientAskToStart && ringData ? " open" : "")}>
      <div>
        <div className="incoming-chat-popup_header">
          {client?.tryout ? (
            <div className="tryout">
              <img src="/images/tryout.svg" />
              <div>
                <span className="tryout-header">Tryout chat</span>
                <span>First {client.durationMinutes} minutes free</span>
              </div>
            </div>
          ) : (
            <p>Incoming Chat</p>
          )}
          {clientAskToStart && (
            <Countdown
              onComplete={() => {
                if (ringData) missedSession();
              }}
              date={Date.now() + CHAT_RING_DURATION}
              renderer={({ minutes, seconds }) => (
                <span>
                  {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
              )}
            />
          )}
        </div>
        <h1>{client?.name}</h1>
        <p className="incoming-chat-popup_user-id">ID {client?.id}</p>
        <button
          onClick={() => {
            if (clientAskToStart) {
              acceptSession();
            }
          }}
          className="incoming-chat-popup_answer"
        >
          ANSWER CHAT
        </button>
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  clientAskToStart: clientAskToStartSelector(state),
  client: callingClientSelector(state),
  ringData: ringDataSelector(state),
});

const connector = connect(mapState, { acceptSession, missedSession });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(IncomingChatPopup);
