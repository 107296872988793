import { AppState } from "../store";
import { ClientSupportedFeatures } from "../types/clientTypes";
import { SessionStatus } from "../types/sessionTypes";

export const sessionSelector = (state: AppState) => state.session;

export const clientSelector = (state: AppState) =>
  sessionSelector(state).client;

export const callingClientSelector = (state: AppState) =>
  sessionSelector(state).callingClient;

export const isUserHiredSelector = (state: AppState) =>
  !!sessionSelector(state).userHiredUtcTime;

export const sessionStatusSelector = (state: AppState) =>
  sessionSelector(state).status

export const isSessionEndedSelector = (state: AppState) =>
  (sessionStatusSelector(state) & SessionStatus.ended) !== 0;

export const isSessionPausedSelector = (state: AppState) =>
  (sessionStatusSelector(state) & SessionStatus.paused) !== 0;

export const isTryoutSelector = (state: AppState) =>
  sessionSelector(state).isTryout;

export const clientAskToStartSelector = (state: AppState) =>
  callingClientSelector(state) != null;

export const isSessionStartedSelector = (state: AppState) =>
  (sessionStatusSelector(state) & SessionStatus.started) !== 0;

export const isInSessionSelector = (state: AppState) =>
  isSessionStartedSelector(state) &&
  !isSessionEndedSelector(state);

export const closeReasonSelector = (state: AppState) =>
  sessionSelector(state).sessionCloseReason;

export const sessionIdSelector = (state: AppState) =>
  sessionSelector(state).sessionId;

export const sessionEndingUtcTimeSelector = (state: AppState) =>
  sessionSelector(state).sessionEndingUtcTime;

export const userHiredUtcTimeSelector = (state: AppState) =>
  sessionSelector(state).userHiredUtcTime;

export const sessionReadingSelector = (state: AppState) =>
  sessionSelector(state).sessionReadingData;

export const sessionReadingSessionIdSelector = (state: AppState) =>
  sessionSelector(state).sessionReadingData.data?.sessionId;

export const isSessionReadingShowSelector = (state: AppState) =>
  sessionSelector(state).sessionReadingData.isShow;

export const isClientImagesSharingSupportedSelector = (state: AppState) =>
  sessionSelector(state).client?.supportedFeatures?.includes(
    ClientSupportedFeatures.ImagesSharing
  );

export const sessionUuidSelector = (state: AppState) =>
  sessionSelector(state).uuid;

export const sessionChannelIdSelector = (state: AppState) =>
  sessionSelector(state).channelId;

export const isSessionAccepted = (state: AppState) =>
  (sessionStatusSelector(state) & SessionStatus.accepted) !== 0

export const activeSessionClientSelector = (state: AppState) =>
  sessionSelector(state).activeSessionClient;