import { ConnectedProps, connect } from "react-redux";
import { AppState } from "../../store";
import TranscriptBody from "../atoms/TranscriptBody";
import {
  clientTranscriptPopupState,
  clientTranscriptLoad,
} from "../../slices/clientSlice";
import {
  isTranscriptLoadingSelector,
  selectedClientSelector,
  sessionTranscriptSelector,
} from "../../selectors/clientSelectors";
import { PopupState } from "../../types/clientTypes";
import TranscriptHeader from "../atoms/TranscriptHeader";
import "./ClientCardTranscript.scss";
import { userNameSelector } from "../../selectors/userSelectors";

export type ClientCardTranscriptProps = {
  createdAt: Date;
};

const ClientCardTranscript = (props: PropsFromRedux) => {
  const onCloseHandler = () => {
    props.clientTranscriptPopupState(PopupState.Close);
  };

  return (
    <div className="session-transcript">
      <TranscriptHeader
        startedAt={props.transcriptData?.startedAt}
        closeHandler={onCloseHandler}
        isloading={props.isTranscriptLoading}
      />
      <TranscriptBody
        data={props.transcriptData}
        isError={false}
        isLoading={props.isTranscriptLoading}
        clientId={props.client?.clientId}
        clientName={props.client?.clientAlias}
        expertName={props.expertName}
      />
      {/* <div className="transcript-footer"></div> */}
    </div>
  );
};

const mapState = (state: AppState) => ({
  transcriptData: sessionTranscriptSelector(state),
  isTranscriptLoading: isTranscriptLoadingSelector(state),
  client: selectedClientSelector(state),
  expertName: userNameSelector(state),
});

const connector = connect(mapState, {
  ClientTranscriptLoad: clientTranscriptLoad,
  clientTranscriptPopupState,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClientCardTranscript);
