import ReactModal from "react-modal";
import "./AcceptNewTermsAndPoliciesModal.scss";
import {
  CURRENT_SITE_ID,
  HELP_LINK,
  PRIVACY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from "../../config";
import { useState } from "react";
import { closeModal } from "../../slices/modalSlice";
import { ConnectedProps, connect } from "react-redux";

const AcceptNewTermsAndPoliciesModal = ({ closeModal }: PropsFromRedux) => {
  const [accepted, setAccepted] = useState(false);

  const handleClick = () => {
    localStorage.setItem("terms-accepted", "true");
    closeModal();
  };

  return (
    <ReactModal
      isOpen={true}
      overlayClassName="modal-overlay"
      className="modal-content terms-modal"
    >
      <img
        className="logo"
        src={`/images/logo-${CURRENT_SITE_ID?.toLowerCase()}-icon.svg`}
      />
      <h3>We updated our Terms &&nbsp;Policies</h3>
      <div className="content">
        <div>
          <input
            type="checkbox"
            checked={accepted}
            onChange={(e) => setAccepted(e.target.checked)}
            className="accept-checkbox"
          />
        </div>
        <p>
          I have read and accept the{" "}
          <a href={TERMS_AND_CONDITIONS_LINK} target="_blank">
            Terms & Conditions
          </a>{" "}
          and the{" "}
          <a href={PRIVACY_LINK} target="_blank">
            Privacy Policy
          </a>
          .
        </p>
      </div>
      <button disabled={!accepted} type="button" onClick={handleClick}>
        Continue
      </button>
      <p className="need-help">
        Need help? <a href={HELP_LINK}>Contact us</a>.
      </p>
    </ReactModal>
  );
};

const connector = connect(null, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AcceptNewTermsAndPoliciesModal);
