import { createSlice } from "@reduxjs/toolkit";
import { UiState } from "../types/uiTypes";

const initialState: UiState = {
  isRinging: false,
  isAudioAllowed: false
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: (create) => ({
    startRinging(state) {
      state.isRinging = true;
    },
    stopRinging(state) {
      state.isRinging = false;
    }
  })
});

export const { startRinging, stopRinging } = uiSlice.actions;
export const uiReducer = uiSlice.reducer;