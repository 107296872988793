import {
  ClientListFilterPresetEnum,
  ClientListSortFieldEnum,
  TagFilterEnum,
} from "../services/api/clientListApi";

export enum SessionType {
  NA = -1,
  Offline = 1,
  QuickResponse = 2,
  Chat = 3,
  Voice = 4,
  Tollfree = 5,
  Video = 6,
}

export enum GenderType {
  Female = "F",
  Male = "M",
  Other = "O",
}

export interface ClientListResponse {
  clients: Array<ClientListData>;
  totalCount?: number;
}

export interface ClientListData {
  clientId: number;
  clientAlias: string;
  sessionCount: number;
  lastSessionStartTime: string | null;
  lastSessionEndTime: string | null;
  lastSessionDurationInMinutes: number | null;
  lastSessionId: number | null;
  lastSessionType: SessionType | null;
  expertGuid: string;
  lastInstantMessage: string | null;
  lastScheduledMessageCreated: string | null;
  lastScheduledMessageDate: string | null;
  isScheduledMessageEnabled: boolean;
  isFavoriteClient: boolean;
  lastInstantMessageXDayAgo: number | null;
  clientNameFromNote?: string;
  tag: TagFilterEnum | null;
  hasUnreadMessages: boolean;
  dobOrder: number;
  gender: GenderType;
  avatarUrl: string;
  totalPaid: number;
  dob: Date | null;
  givenDob: Date | null;
  offerAvailable: boolean;
  offerDisabledUntil: Date | null;
}

export enum DiscountOfferingStatus {
  allowed = 'allowed',
  locked = 'locked'
}

export interface ClientsByGroupsData {
  recentClientsIds: number[];
  newClientsIds: number[];
  favoriteClientsIds: number[];
  promotionClientsIds: number[];
}

export interface ClientListState {
  currentPage: number;
  pageData: Array<ClientListData>;
  rowsPerPage: number;
  count: number;
  isLoadingPageData: boolean;
  isLoadingCount: boolean;
  filterPreset: ClientListFilterPresetEnum;
  sortingOrder: string;
  sortField: ClientListSortFieldEnum;
  searchText: string | null;
  tags: TagFilter;
  requestedPreset: ClientListFilterPresetEnum | null;
}

export interface TagFilter {
  selected: TagFilterEnum | null;
  amounts: Array<number>;
  isLoading: boolean;
}
