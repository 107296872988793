import {
  takeEvery,
  put,
  select,
  delay,
  fork,
  takeLatest,
  take,
  call,
} from "redux-saga/effects";
import {
  RealTimeActivityLocation,
  ChatSystemMessage,
  ChatExpertMessage,
  IFile,
} from "../types/chatTypes";

import {
  updateClientIsTyping,
  setRealTimeMessage,
  clientCancelTyping,
  fileUploaded,
  fileUploadedError,
  sendFile,
  addFile,
  fileUploadingProgress,
  fileAddCancelObject,
  addExpertNewMessage,
  fileDeleteInfo,
  startTyping,
  stopTyping,
  commitExpertMessage,
  setRealTimeActivity,
  fileSelected,
  fileRemove,
  addSystemMessage,
} from "../slices/chatSlice";
import {
  editableMessageSelector,
  filesSelector,
} from "../selectors/chatSelectors";
import { sessionIdSelector } from "../selectors/sessionSelectors";
import { LIFE_TIME_OF_TYPING_INDICATOR } from "../config";
import { showAlertModal } from "../slices/modalSlice";
import { END, EventChannel, eventChannel } from "redux-saga";
import {
  AttachmentType,
  createAttachment,
  postAttachment,
  uploadFile,
} from "../services/api/sessionApi";
import { AxiosProgressEvent, CancelTokenSource } from "axios";
import { FileUploadItemStatus } from "../components/atoms/FileUploadItem";
import { sendPubNubMessage } from "../actions/pubNubActions";
import {
  CreateAttachmentResponse,
  PostAttachmentResponse,
} from "../services/api/models/my-orders";
import { compressImage } from "../helpers/utils";
import { MessageKind, MessageReason } from "../types/messageTypes";

function* handleSetRealTimeActivity(action: ReturnType<typeof setRealTimeActivity>) {
  const { clientRealTimeActivity, location } = action.payload;

  switch (location) {
    case RealTimeActivityLocation.aboveUserInput:
      const isClientTyping =
        clientRealTimeActivity.toLowerCase() === "client is typing";
      const realTimeMessage = isClientTyping
        ? ""
        : clientRealTimeActivity.trim();

      yield put(clientCancelTyping(isClientTyping));
      if (isClientTyping) {
        yield put(updateClientIsTyping(isClientTyping));
      }

      if (realTimeMessage) {
        yield put(showAlertModal("", realTimeMessage, "OK"));
      }

      yield put(setRealTimeMessage(realTimeMessage));
      break;

    case RealTimeActivityLocation.inMessagesView:
      yield put(
        addSystemMessage(new ChatSystemMessage(clientRealTimeActivity))
      );
      break;

    default:
      break;
  }
}

function* handleUpdateClientCancelTypingAction(action: ReturnType<typeof clientCancelTyping>) {
  if (!action.payload.isClientTyping) {
    yield delay(LIFE_TIME_OF_TYPING_INDICATOR);
    yield put(updateClientIsTyping(false));
  }
}

function* handleSendFile(action: ReturnType<typeof sendFile>) {
  yield put(
    sendPubNubMessage({
      kind: MessageKind.asset,
      body: {
        asset_id: action.payload.assetId,
        type: action.payload.type,
        aspect_ratio: action.payload.aspectRatio,
        caption: action.payload.caption,
      },
    })
  );
}

function* handleCommitExpertMessage() {
  const editableMessage: ChatExpertMessage = yield select(
    editableMessageSelector
  );
  if (editableMessage.text) {
    yield put(
      sendPubNubMessage({
        kind: MessageKind.text,
        body: editableMessage.text.trim(),
      })
    );
    yield put(addExpertNewMessage());
  }

  const files: { [key: string]: IFile } = yield select(filesSelector);

  let keys = Object.keys(files);
  let sended = new Array<string>();

  for (let index = 0; index < keys.length; index++) {
    const currentFile = files[keys[index]];
    if (currentFile.status === FileUploadItemStatus.Uploaded) {
      yield put(
        sendFile(
          currentFile.file.name,
          currentFile.assetId!,
          currentFile.aspectRatio,
          AttachmentType.image,
          currentFile.caption
        )
      );
      //   const sessionId: string = yield select(sessionIdSelector);

      //   yield call(sendChatAdvisorImageTrackingEvent, "AdvisorImageSent", {
      //     guid: files[keys[index]].assetId!.toString(),
      //     SessionId: sessionId,
      //   });

      sended.push(keys[index]);
    }
  }
  if (sended && sended.length > 0) {
    yield put(fileDeleteInfo(sended));
  }
}

function* handleStartTyping(action: ReturnType<typeof startTyping>) {
  yield put(
    sendPubNubMessage({
      kind: MessageKind.notification,
      reason: MessageReason.startTyping,
    })
  );
}

function* handleStopTyping(action: ReturnType<typeof stopTyping>) {
  yield put(
    sendPubNubMessage({
      kind: MessageKind.notification,
      reason: MessageReason.stopTyping,
    })
  );
}

function* handleFileSelected(action: ReturnType<typeof fileSelected>) {
  yield put(addFile(action.payload.file));

  yield fork(handleFileUpload, action.payload.file);
}

function* handleFileUpload(file: File) {
  const sessionId: string = yield select(sessionIdSelector);

  try {
    const assetInfo: CreateAttachmentResponse = yield call(
      createAttachment,
      sessionId,
      AttachmentType.image
    );

    file = yield call(compressImage, file, assetInfo);

    const [uploadPromise, chan, cancelObject] = createUploader(
      assetInfo.presigned_url,
      file,
      assetInfo.content_type
    );

    yield fork(watchOnProgress, chan);

    yield put(fileAddCancelObject(file, cancelObject));

    yield call(() => uploadPromise);

    const response: PostAttachmentResponse = yield call(
      postAttachment,
      sessionId,
      assetInfo.presigned_url_key,
      assetInfo.content_type
    );

    if (response.chat_asset_id) {
      const files: { [key: string]: IFile } = yield select(filesSelector);

      if (files[file.name]) {
        const bitmap: ImageBitmap = yield call(() =>
          createImageBitmap(files[file.name].file)
        );
        yield put(
          fileUploaded(
            file,
            response.chat_asset_id,
            bitmap.width / bitmap.height
          )
        );
        // yield fork(sendTrackingEvent, file.name, "success");
      }
    } else {
      yield put(fileUploadedError(file.name));
    }
  } catch (err) {
    yield put(fileUploadedError(file.name));
  }
}

function* watchOnProgress(
  chan: EventChannel<{ progress: number; file: File }>
) {
  while (true) {
    const data: { progress: number; file: File } = yield take(chan);

    yield put(fileUploadingProgress(data.file, data.progress));
  }
}

function createUploader(
  url: string,
  file: File,
  contentType: string
): [
  Promise<unknown>,
  EventChannel<{ progress: number; file: File }>,
  CancelTokenSource
] {
  let emit: any;

  const chan = eventChannel<{ progress: number; file: File }>((emitter) => {
    emit = emitter;
    return () => {}; // it's necessarily. event channel should
    // return unsubscribe function. In our case
    // it's empty function
  });

  const [uploadPromise, cancelObject] = uploadFile(
    url,
    file,
    contentType,
    (event: AxiosProgressEvent) => {
      const progress =
        (event.total && (event.loaded / event.total) * 100) || 100;

      emit({ progress: progress * 0.9, file });

      if (progress === 100) {
        emit(END);
      }
    }
  );

  return [uploadPromise, chan, cancelObject];
}

function* handleFileUploadError(action: ReturnType<typeof fileUploadedError>) {
  yield put(
    showAlertModal(
      "Upload Failed",
      "Something went wrong with the upload. Please try again.",
      "GOT IT"
    )
  );

  //   yield fork(sendTrackingEvent, action.payload.fileName, "failed");

  yield fork(processFileRemove, action.payload.fileName);
}

// function* sendTrackingEvent(fileName: string, status: string) {
//   const files: { [key: string]: IFile } = yield select(filesSelector);

//   if (files && files[fileName]) {
//     const fd = files[fileName];
//     const sessionId: string = yield select(sessionIdSelector);

//     yield call(sendChatAdvisorImageTrackingEvent, "AdvisorImageUpload", {
//       UploadDuration: "" + fd.duration,
//       FileSize: "" + fd.file.size,
//       FileType: "" + fd.file.name.split(".").pop(),
//       FileName: "" + fd.file.name,
//       Guid: "" + fd.assetId?.toString(),
//       UploadStatus: status,
//       SessionId: sessionId,
//     });
//   }
// }

function* processFileRemove(fileName: string) {
  const files: { [key: string]: IFile } = yield select(filesSelector);

  if (
    files[fileName] &&
    files[fileName].progress === FileUploadItemStatus.Uploading
  ) {
    if (files[fileName].cancelObject) {
      yield files[fileName].cancelObject?.cancel();
    }
  }

  yield put(fileDeleteInfo([fileName]));
}

function* handleFileRemove(action: ReturnType<typeof fileRemove>) {
  //   yield fork(sendTrackingEvent, action.payload.fileName, "canceled");
  yield fork(processFileRemove, action.payload.fileName);
}
// function* handleFileWrongSelected(action: ReturnType<typeof fileWrongSelected>) {
//   const sessionId: string = yield select(sessionIdSelector);
//   yield call(sendChatAdvisorImageTrackingEvent, "AdvisorImageUpload", {
//     UploadDuration: "",
//     FileName: "" + action.payload.file.name,
//     FileSize: "" + action.payload.file.size,
//     FileType: "" + action.payload.file.name.split(".").pop(),
//     UploadStatus: "failed",
//     SessionId: sessionId,
//     UploadErrorSize: action.payload.errorType === "size" ? "true" : "false",
//     UploadErrorType: action.payload.errorType === "size" ? "false" : "true",
//   });
// }

export default function* root() {
  yield takeEvery(sendFile.type, handleSendFile);
  yield takeEvery(startTyping.type, handleStartTyping);
  yield takeEvery(stopTyping.type, handleStopTyping);
  yield takeEvery(commitExpertMessage.type, handleCommitExpertMessage);
  yield takeEvery(setRealTimeActivity.type, handleSetRealTimeActivity);
  yield takeLatest(clientCancelTyping.type, handleUpdateClientCancelTypingAction);
  yield takeEvery(fileSelected.type, handleFileSelected);
  yield takeEvery(fileRemove.type, handleFileRemove);
  yield takeEvery(fileUploadedError.type, handleFileUploadError);
  // yield takeEvery(fileWrongSelected.type, handleFileWrongSelected);
}
