import { MessagePromotionInfo } from "../services/api/messagesApi";

export interface ScheduledMessageFormParams {
  clientId: number;
  clientAlias: string;
  clientNoteName?: string;
  sessionDuration: number;
  sessionEndTime: Date;
  clientType?: number | null;
}

export interface MessageTemplateData {
  id: number;
  title: string;
  content: string;
  lifecycleType?: number;
  discountType?: number; // 0 - percent, 1 - fixed
  discountValue?: number;
  discountDurationDays?: number;
}

export interface MessageBulkSendRequestData {
  recipientIds: number[];
  message: string;
  templateData: {
    messageType: number;
    lifecycleType?: number;
    messageTemplateId?: number;
  };
  promotionData?: MessagePromotionInfo;
}

export type MessageAsset = {
  asset_id: number;
  caption?: string;
  type: string;
  aspect_ratio: number;
};

export type PubNubMessage = {
  kind: MessageKind;
  body?: MessageBody;
  reason?: MessageReason;
};

export type MessageBody = MessageAsset | string | null;

export enum MessageKind {
  notification = "notification",
  text = "text",
  asset = "chat_asset",
  textSystemMessage = "text_system_message",
}

export enum MessageReason {
  startTyping = "typing",
  stopTyping = "stop_typing",
  timer = "timer",
}

export enum AdminMessageState {
  started = "started",
  ended = "ended",
  paused = "paused",
}
