import { useEffect } from "react";

declare var window: any;

function loadSdkGsi(onload: (() => void)|undefined=undefined) {
  const id = "googleSdkGsi";
  const sdk = document.getElementById(id);
  if (!sdk) {
    const script = document.createElement("script");
    script.id = id;
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = () => {
      if (onload) {
        onload();
      }
    }
    
    document.body.appendChild(script);
  }
}

export function clickGoogleSignIn() {
  (document.querySelector(".g_id_signin [role='button']") as any)?.click();
}
type GoogleSignInProps = {
  successHandler: (response: GoogleCredentialResponse) => void;
  sdkLoaded: () => void;
  googleAppId: string;
};
export type GoogleCredentialResponse = {
  credential: string;
  select_by: string;
};

function GoogleSignInGsi(props: GoogleSignInProps) {
  useEffect(() => {
    loadSdkGsi(props.sdkLoaded);
    window.handleCredentialResponse = function (response: GoogleCredentialResponse) {
      props.successHandler(response);
    };
  }, []);

  return (
    <>
      <div style={{ display: "none" }}>
        <div
          id="g_id_onload"
          data-client_id={props.googleAppId}
          data-callback="handleCredentialResponse"
          data-ux_mode="popup"
          data-use_fedcm_for_prompt="true"
        ></div>
        <div className="g_id_signin" data-type="standard"></div>
      </div>
    </>
  );
}

export default GoogleSignInGsi;
