import React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../store';
import { closeModal } from '../../slices/modalSlice'
import './OnboardSlidesModal.css';
import { useState } from 'react';
import { Howl } from 'howler';
import { userStatusSelector } from '../../selectors/userSelectors';
import { soundsSelector } from '../../selectors/appSettingsSelector';
import { GetRingingByType } from '../../helpers/settingsHelper';

const slides = [1, 2, 3, 4];

// preload images
new Image().src = '/images/onboard/slide-1.svg';
new Image().src = '/images/onboard/slide-2.svg';
new Image().src = '/images/onboard/slide-3.png';
new Image().src = '/images/onboard/slide-4.png';
new Image().src = '/images/onboard/play.png';

const OnboardSlidesModal =
    (props: PropsFromRedux) => {

        const ringingAudio = new Howl({ src: GetRingingByType(props.soundSettings.ringing)});
        
        const [currentSlide, setCurrentSlide] = useState(slides[0]);
        const getButtonLabel = () => {
            const currentSlideIndex = slides.indexOf(currentSlide);
            if (currentSlideIndex == 0)
                return "TAKE A QUICK TOUR";
            if (currentSlideIndex == slides.length - 1)
                return "Done";
            return "Next";
        };

        const clickHandler = () => {
            const currentSlideIndex = slides.indexOf(currentSlide);
            if (currentSlideIndex == slides.length - 1) {
                props.closeModal();
                return;
            }

            setCurrentSlide(slides[currentSlideIndex + 1]);
        }

        const testRingHandler = () => {
            ringingAudio.play();
        }

        return (<ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content onboard-slides"  >
            <div className="content">
                {currentSlide == 1 && <div className='slide-1'>
                    <img src="/images/onboard/slide-1.svg" />
                    <h2>Welcome to the New Advisor’s Suite</h2>
                    <p>Discover new ways to chat with your clients,<br/> manage your client lists, and organize your time. </p>
                </div>}

                {currentSlide == 2 && <div className='slide-2'>
                    <img src="/images/onboard/slide-2.svg" />
                    <h2>Smooth chat interface</h2>
                    <p>See your client’s profile, take notes, and enjoy<br/> fluent conversations - emojis included 😉</p>
                </div>}

                {currentSlide == 3 && <div className='slide-3'>
                    <img src="/images/onboard/slide-3.png" />
                    <h2>Easy client management</h2>
                    <p>Track all your clients, mark your favorites, and send scheduled messages to keep them close.</p>
                </div>}

                {currentSlide == 4 && <div className='slide-4'>
                    <img src="/images/onboard/slide-4.png" />
                    <h2>Timely chat notifications</h2>
                    <p>Get notified the second a client wants to chat with you. Test your ringer now.</p>
                    <div className="test-ring" onClick={testRingHandler}>
                        <img src="/images/onboard/play.png" />
                        Click to test ringer
                    </div>
                </div>}

            </div>
            <div className="nav">
                <button onClick={clickHandler} className="primary">{getButtonLabel()}</button>
                <div className="dots">
                    {slides.map(n => <span key={n} onClick={() => setCurrentSlide(n)} className={n == currentSlide ? 'active' : ''}></span>)}
                </div>
            </div>
        </ReactModal>
        )
    };

const mapState = (state: AppState) => ({
    formParams: state.modal.props,
    extertStatus: userStatusSelector(state),
    soundSettings: soundsSelector(state)
});

const connector = connect(mapState, { closeModal });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OnboardSlidesModal);