import { formatDate } from "../../../helpers/dateFormatHelper";
import BirthdayIcon from "./BirthdayIcon";
import "./DobCell.scss";

export interface IDobCellProps {
  birthDate: Date;
  dobOrder: number;
}

export default function DobCell(props: IDobCellProps) {
  return (
    <>
      {props.dobOrder < 3 && (
        <BirthdayIcon
          tooltipText={
            props.dobOrder === 0
              ? "It’s your client's birthday. Send a mes-<br/>sage and wish a happy birthday"
              : "Client birthday is coming up. Send a mes-<br/>sage and wish a happy birthday."
          }
        />
      )}
      <span className="bithday-text">{formatDate(props.birthDate, true)}</span>
    </>
  );
}
