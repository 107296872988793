import LightboxWrapper from "./LightboxWrapper";
import "./TranscriptPhrase.scss";
import { MessageAsset, MessageKind } from "../../types/messageTypes";
import { API_BASE_URL } from "../../config";

export interface ITranscriptPhraseProps {
  ownerName: string;
  isExpert: boolean;
  body: string | MessageAsset;
  upKey: string;
  kind: MessageKind;
  sessionId: number;
}

const renderContent = (kind: MessageKind, body: string | MessageAsset, sessionId: number | undefined) => {
  switch (kind) {
    case MessageKind.text:
      return body as string;
    case MessageKind.asset:
      const asset = body as MessageAsset;
      const url = `${API_BASE_URL}/orders/${sessionId}/assets/chat_asset/${asset.asset_id}`;
      return (
        <LightboxWrapper
            imageUrl={url}
            thumbnailUrl={url}
            width={234}
            thumbnailLoaded={() => {}}
            caption={asset.caption}
          />
      );
    default:
      return <></>;
  }
};

export default function TranscriptPhrase(props: ITranscriptPhraseProps) {
  return (
    <div className="transcript-phrase">
      <div
        className={"tr-ph-header" + (props.isExpert ? " expert" : " client")}
      >
        {props.ownerName}
      </div>

      <div className="tr-ph-sentance" key={props.upKey}>
        {renderContent(props.kind, props.body, props.sessionId)}
      </div>
    </div>
  );
}
