import * as React from "react";
import "./LastReadingCell.css";
import { kasambaFormatDate } from "../../../helpers/dateFormatHelper";
import { SessionType } from "../../../types/clientListTypes";

export interface ILastReadingCellProps {
  lastReadDate: string | Date | null;
  lastSessionType: SessionType | null;
}

export const showIcon = (lastSessionType: SessionType) => {
    return ( lastSessionType === SessionType.Chat
            || lastSessionType === SessionType.Voice
            || lastSessionType === SessionType.Video )
}

export function LastReadingCell(props: ILastReadingCellProps) {
  return (
    <>
      {props.lastReadDate && (
        <>
          {props.lastSessionType === SessionType.Chat && (
            <i className="bubble-icon" />
          )}
          {props.lastSessionType === SessionType.Voice && (
            <i className="phone-icon" />
          )}
          {props.lastSessionType === SessionType.Video && (
            <i className="video-icon" />
          )}
          {kasambaFormatDate(props.lastReadDate)}
        </>
      )}
    </>
  );
}
