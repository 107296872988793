import './DiscountCard.scss';

type Props = {
    percent: number;
    selected: boolean;
    thinVersion?: boolean;
    showCheckMark?: boolean;
    onClick?: VoidFunction;
};

export const DiscountCard = ({ percent, onClick, selected, showCheckMark = false, thinVersion: smallVersion = false }: Props) => {
    return <div className={`discount-card${selected ? ' selected' : ''}${showCheckMark ? ' with-mark' : ''}`} onClick={onClick}>
        <svg width={smallVersion ? 175 : 154} height={smallVersion ? 78 : 112} viewBox={smallVersion ? "0 0 178 77" : '0 0 166 112'} fill={selected ? "url(#selected-gradient)" : "none"} xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2398_19688)">
                {smallVersion ?
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M40 7.5C42.8278 7.5 45.264 5.82321 46.3685 3.40966C47.0579 1.90307 48.3431 0.5 50 0.5H165C170.523 0.5 175 4.97715 175 10.5V67.5C175 73.0228 170.523 77.5 165 77.5H50C48.3431 77.5 47.0579 76.0969 46.3685 74.5903C45.264 72.1768 42.8278 70.5 40 70.5C37.1722 70.5 34.736 72.1768 33.6315 74.5903C32.9421 76.0969 31.6569 77.5 30 77.5H10C4.47715 77.5 0 73.0229 0 67.5V10.5C0 4.97715 4.47715 0.5 10 0.5H30C31.6569 0.5 32.9421 1.90307 33.6315 3.40966C34.736 5.82321 37.1722 7.5 40 7.5Z" />
                    :
                    <>
                        <path fillRule="evenodd" clipRule="evenodd" d="M51 8C53.8278 8 56.264 6.32321 57.3685 3.90966C58.0579 2.40307 59.3431 1 61 1H145C150.523 1 155 5.47715 155 11V81C155 86.5228 150.523 91 145 91H61C59.3431 91 58.0579 89.5969 57.3685 88.0903C56.264 85.6768 53.8278 84 51 84C48.1722 84 45.736 85.6768 44.6315 88.0903C43.9421 89.5969 42.6569 91 41 91H21C15.4772 91 11 86.5229 11 81V11C11 5.47715 15.4772 1 21 1H41C42.6569 1 43.9421 2.40307 44.6315 3.90966C45.736 6.32321 48.1722 8 51 8Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M51 8C53.8278 8 56.264 6.32321 57.3685 3.90966C58.0579 2.40307 59.3431 1 61 1H145C150.523 1 155 5.47715 155 11V81C155 86.5228 150.523 91 145 91H61C59.3431 91 58.0579 89.5969 57.3685 88.0903C56.264 85.6768 53.8278 84 51 84C48.1722 84 45.736 85.6768 44.6315 88.0903C43.9421 89.5969 42.6569 91 41 91H21C15.4772 91 11 86.5229 11 81V11C11 5.47715 15.4772 1 21 1H41C42.6569 1 43.9421 2.40307 44.6315 3.90966C45.736 6.32321 48.1722 8 51 8Z" stroke="#D9D9D9" />
                    </>
                }
            </g>
            <text x="23.38" y="33.36" className="value" fill={selected ? '#fff' : 'var(--main)'}>{percent}% OFF</text>
            <foreignObject x="24" y="45.36" width={smallVersion ? 200 : 100} height="40">
                <p className="subtitle">
                    for your next session
                </p>
            </foreignObject>
            <defs>
                <linearGradient id="selected-gradient" gradientTransform="rotate(9.06)">
                    <stop offset="1.53%" stopColor="var(--discount-gradient-first)" />
                    <stop offset="96.54%" stopColor="var(--discount-gradient-second)" />
                </linearGradient>
                {!smallVersion &&
                    <filter id="filter0_d_2398_19688" x="0.5" y="0.5" width="165" height="111" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2398_19688" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2398_19688" result="shape" />
                    </filter>
                }
            </defs>
        </svg>
    </div>
}
