import { FC, PropsWithChildren } from "react";

import "./Card.scss";

type Props = PropsWithChildren & {
  className?: string;
};

export const Card: FC<Props> = ({ children, className }) => {
  return (
    <div className={`card${className ? " " + className : ""}`}>{children}</div>
  );
};
