import * as React from 'react';
import ReactModal from 'react-modal';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../store';
import { closeModal } from '../../slices/modalSlice'
import { sendScheduledMessage } from '../../actions/messageActions'
import { kasambaFormatDate } from '../../helpers/dateFormatHelper';
import { useState } from 'react';
import './ScheduledMessageModal.css';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../atoms/Spinner';
import { loadMessageTemplates } from "../../actions/messageActions";
import { useEffect } from 'react';
import { MessageTemplateData, ScheduledMessageFormParams } from '../../types/messageTypes';
import { MessageExtra } from '../../services/api/messagesApi';
import { isValidDate, replaceClientName } from '../../helpers/utils';

// const ScheduledMessageModal =
//     (props: PropsFromRedux) => {
//         const [isIframeLoaded, setIsIframeLoaded] = useState(false);

//         const iframeLoaded = () => {
//             setIsIframeLoaded(true)
//         };

//         return (<ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content scheduled-message"  >
//             <div className="close" onClick={() => props.closeModal()}></div>
//             <iframe scrolling="no" style={{ display: isIframeLoaded ? 'block' : 'none' }} src={props.url} onLoad={iframeLoaded} />
//             {!isIframeLoaded && <Loader />}
//         </ReactModal>
//         )
//     };

const options = [
    { value: '0', label: 'Send now' },
    { value: '10', label: 'In 10 minutes' },
    { value: '120', label: 'In 2 hours' },
    { value: '360', label: 'In 6 hours' },
    { value: '720', label: 'In 12 hours' },
    { value: '1440', label: 'In 24 hours (1 day)' },
    { value: '2880', label: 'In 48 hours (2 days)' },
    { value: '4320', label: 'In 72 hours (3 days)' },
    { value: '10080', label: 'In 1 Week' }
]

interface IFormInput {
    text: string;
    delay: any;
}

const ScheduledMessageModal =
    ({ closeModal, formParams, sendScheduledMessage, messageTemplates, loadMessageTemplates }: PropsFromRedux) => {
        const [isSending, setIsSending] = useState(false);
        const { control, register, handleSubmit, formState, setValue } = useForm<IFormInput>();
        const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplateData>();
       
        useEffect(() => {
            loadMessageTemplates();
        }, []);
        
        const onSubmit = (data: IFormInput) => {

            if (isSending) {
                return;
            }

            let messageExtra: MessageExtra = { 
                    templateData: {
                      messageType: selectedTemplate ? 1 : 0,
                      lifecycleType: formParams.clientType }};
                      
            if (selectedTemplate) {
                messageExtra.templateData.messageTemplateId  = selectedTemplate.id;
                if(selectedTemplate.discountType != undefined && selectedTemplate.discountValue != undefined ) {
                    messageExtra.promotionData = {
                        discountType: selectedTemplate.discountType,
                        discountValue: selectedTemplate.discountValue,
                        discountDurationDays: selectedTemplate.discountDurationDays,
                    }
                }
            }
            data.text = replaceClientName(data.text, '');
            if(!data.text) {
                return;
            }
            sendScheduledMessage(formParams.clientId, data.text, Number(data.delay.value), messageExtra);
            setIsSending(true);
        };

        const messageTemplatesOptions = messageTemplates.
            filter(t => !t.lifecycleType || t.lifecycleType == formParams.clientType).
            map(t => ({ value: t.id, label: t.title }));


        const messageTemplateChangedHandler = (templateOption: any) => {
            if(!templateOption) {
                setSelectedTemplate(undefined);
                setValue("text", '');
                return;
            }

            let selectedId = Number(templateOption.value);
            if (!selectedId) {
                return;
            }

            const template = messageTemplates.find(t => t.id == selectedId);
            if (!template) {
                return;
            }

            setSelectedTemplate(template);
            let newText = template.content;
            if (formParams.clientNoteName) {
                
                newText = replaceClientName(newText, formParams.clientNoteName);
            }
            setValue("text", newText);
        }

        const textChangeHanler = (e: any) => {
            if(!e.target.value) {
                setSelectedTemplate(undefined);
            }
        }

        const isSelectedDiscountTemplate = !!selectedTemplate && selectedTemplate.discountType != undefined;

        return (<ReactModal isOpen={true} overlayClassName="modal-overlay" className="modal-content scheduled-message"  >

            <div className="close" onClick={() => closeModal()}></div>
            <h2>Keep in touch!<br />
                Schedule a message to follow up with {formParams.clientAlias}</h2>
                {isValidDate(formParams.sessionEndTime) && <h3>{formParams.sessionDuration} minutes chat, ended {kasambaFormatDate(formParams.sessionEndTime)}</h3>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="message-text" className="text">Message </label>
                <Select
                    placeholder="Select template"
                    classNamePrefix="message-template"
                    isClearable={true}
                    options={messageTemplatesOptions}
                    onChange={messageTemplateChangedHandler}
                />
                <textarea placeholder="Type your message"
                    readOnly={isSelectedDiscountTemplate}
                    className={formState.errors.text ? 'error' : ''}
                    onChange={textChangeHanler}
                    // TODO: upgrade v18
                    // ref={register({
                    //     required: true,
                    //     maxLength: 3000,
                    //     minLength: { value: 10, message: "Your message must include at least 10 characters" }
                    // })}
                    id="message-text"
                    name="text" ></textarea>
                {formState.errors.text?.message && <div className="error-message">{formState.errors.text?.message}</div>}



                <label className="shedule-time" htmlFor="scheduled-send-time">Send time</label>
                {/*// TODO: upgrade v18 */}
                {/* <Controller
                    name="delay"
                     as={Select}
                    maxMenuHeight="142px"
                    rules={{ required: true }}
                    classNamePrefix="shedule-time" className={"shedule-time " + (formState.errors.delay ? "error" : '')}
                    options={options} inputId="scheduled-send-time"
                    control={control}
                    defaultValue={null}
                /> */}

                <button type="submit" className="primary" disabled={isSending}>
                    {isSending ? <Spinner size={10} /> : 'Schedule a Message'}
                </button>
                <p>Please note that any contact with or from the client will automatically delete your scheduled message.</p>
            </form>
        </ReactModal>
        )
    };

const mapState = (state: AppState) => ({
    formParams: state.modal.props as ScheduledMessageFormParams,
    messageTemplates: state.user.messageTemplates
});

const connector = connect(mapState, { closeModal, sendScheduledMessage, loadMessageTemplates });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ScheduledMessageModal);