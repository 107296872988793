import { FC } from "react";
import { ReactSVG } from "react-svg";
import { getZodiacSignIconByDate } from "../../helpers/zodiacSignHelper";
import { formatDate } from "../../helpers/dateFormatHelper";

import "./DOB.scss";

type Props = {
  dob?: Date | null;
};

export const DOB: FC<Props> = ({ dob }: Props) => {
  if (!dob) {
    return <></>;
  }
  return (
    <div className="client-dob">
      <ReactSVG
        height={18}
        width={18}
        className="zodiac-icon"
        src={`/images/zodiac/${getZodiacSignIconByDate(dob)}.svg`}
      />
      {formatDate(dob)}
    </div>
  );
};
