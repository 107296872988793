import { isKasambaApp } from "./settingsHelper";

const dateFormatMonthAndYearOptions = {
  month: "long",
  year: "numeric",
} as Intl.DateTimeFormatOptions;

const dateFormatOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
} as Intl.DateTimeFormatOptions;

const time24HoursFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  hour12: false,
} as Intl.DateTimeFormatOptions;

const time12HoursFormatOptions = {
  ...time24HoursFormatOptions,
  hour12: true,
} as Intl.DateTimeFormatOptions;

const dateFormatter = new Intl.DateTimeFormat("en-GB", dateFormatOptions);
const time24HoursFormatter = new Intl.DateTimeFormat(
  "en-GB",
  time24HoursFormatOptions
);
const time12HoursFormatter = new Intl.DateTimeFormat(
  "en-GB",
  time12HoursFormatOptions
);

export function kasambaFormatDate(
  textDate: string | Date | null | undefined
): string {
  if (!textDate) {
    return "";
  }
  try {
    const date = new Date(textDate);
    const today = new Date();

    const isThisYear = today.getFullYear() === date.getFullYear();
    const isToday =
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      isThisYear;

    const timeFormatter = isKasambaApp
      ? time24HoursFormatter
      : time12HoursFormatter;

    if (isToday) {
      return `Today at ${timeFormatter.format(date)}`;
    }

    if (isThisYear) {
      const optionsTYDate = {
        month: "short",
        day: "numeric",
      } as Intl.DateTimeFormatOptions;

      return `${new Intl.DateTimeFormat("en-GB", optionsTYDate).format(
        date
      )} at ${timeFormatter.format(date)}`;
    }

    return `${formatDate(date)} at ${timeFormatter.format(date)}`;
  } catch (error) {
    console.error(
      " Error convert textDate[" +
        textDate +
        "] to kasambaFormatDate. Error:" +
        error
    );
    return "";
  }
}

export function formatDate(
  date: Date,
  skipYear: boolean = false,
  monthFirst: boolean = false,
  withTime: boolean = false
): string {
  const parts = dateFormatter.formatToParts(date);
  const year = parts.find((t) => t.type === "year");
  const month = parts.find((t) => t.type === "month");
  const day = parts.find((t) => t.type === "day");

  let result = monthFirst
    ? `${month?.value} ${day?.value}`
    : `${day?.value} ${month?.value}`;

  if (!skipYear) {
    result += `, ${year?.value}`;
  }

  if (withTime) {
    result += ` ${time24HoursFormatter.format(date)}`
  }

  return result;
}

export function lastUpdateFormat(date: Date): string {
  return `${dateFormatter.format(date).toUpperCase()} at ${time12HoursFormatter
    .format(date)
    .toUpperCase()}`;
}

export function xDaysAgo(days: number | null) {
  if (days === 0) {
    return "Today";
  }
  if (days === 1) {
    return `1 day ago`;
  }
  if (days) {
    return `${days} days ago`;
  }

  return "";
}

export function diffMinutes(dt2: Date, dt1: Date) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export const months = [
  { value: "1", label: "Jan" },
  { value: "2", label: "Feb" },
  { value: "3", label: "Mar" },
  { value: "4", label: "Apr" },
  { value: "5", label: "May" },
  { value: "6", label: "Jun" },
  { value: "7", label: "Jul" },
  { value: "8", label: "Aug" },
  { value: "9", label: "Sep" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];

export const monthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function convertToMonthAndYear(date: Date | null) {
  if (date) {
    const formatter = new Intl.DateTimeFormat(
      "en-GB",
      dateFormatMonthAndYearOptions
    );
    return formatter.format(date);
  }
  return "";
}

export function dateDiffInDays(a: Date, b: Date = new Date()) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor(Math.abs(utcB - utcA) / _MS_PER_DAY);
}

export function toUTCFormatDate(date: Date | null) {
  if (!date) return null;
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

const pad = (num: number) => `${Math.floor(Math.abs(num))}`.padStart(2, "0");
const getTimezoneOffset = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? "+" : "-";
  return diff + pad(tzOffset / 60) + ":" + pad(tzOffset % 60);
};

export const toISOStringWithTimezone = (date: Date) => {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  );
};
