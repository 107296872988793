import axios from "axios";
import { CANCEL } from "redux-saga";
import api from "./api";
import { AdvisorDashboardDataResponse } from "./models/my-advisor-dashboard";
import { InsightApiResponse } from "./models/insight";

export interface DashboardIndicatorsResponse {
  missedCalls: number;
  missedChats: number;
  clientsPending: number;
  clientContacted: number;
  messageSent: number;
  datestamp: string;
}
export interface MessagesChartData {
  totalMessagesSent: number;
  convertedMessages: number;
  readNotConverted: number;
  unreadMessages: number;
}
export interface LifeCycleDataItem {
  percent: number;
  amount: number;
  clientsToPrevMonth: number;
}
export interface LifecycleChartData {
  firstSession: LifeCycleDataItem;
  secondSession: LifeCycleDataItem;
  thirdSession: LifeCycleDataItem;
  returning: LifeCycleDataItem;
  nonePaid: LifeCycleDataItem;
}

export interface DashboardBiIndicatorsResponse {
  clientsContactedYst: number;
  messagesSentYst: number;
  lastUpdated: Date | null;
  datestamp: string;
  someDataMissing: boolean;
  last30Days: BiCharts | null;
  currentMonth: BiChartsCurrentMonth | null;
  personalGoal: number;
  noData: boolean;
  messagesNoData: boolean;
}

export interface BiCharts {
  messagesChart: MessagesChartData;
  lifecycleChart: LifecycleChartData;
  lifecycleNoData: boolean;
  messagesNoData: boolean;
  someDataMissing: boolean;
  noData: boolean;
}

export interface BiChartsCurrentMonth extends BiCharts {
  monthStamp: number | null;
}

export interface DashboardEarningsResponse {
  monthsEarnings: MonthEarningsData[];
  serverDate: Date;
}

export interface MonthEarningsData {
  month: number;
  year: number;
  earnings: number;
  goal?: number;
  lastUpdated?: Date;
}

export function GetTodayDashboardIndicators(): Promise<DashboardIndicatorsResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get(`/api/experts/me/dashboard/today`)
    .then((response) => response.data);
  request[CANCEL] = () => source.cancel();
  return request;
}

export function GetBiDashboardIndicators(): Promise<DashboardBiIndicatorsResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get(`/api/experts/me/dashboard/biwcm`)
    .then((response) => response.data);
  request[CANCEL] = () => source.cancel();
  return request;
}

export function GetDashboardEarnings(): Promise<DashboardEarningsResponse> {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let request: any = api
    .get(`/api/experts/me/dashboard/earnings`)
    .then((response) => response.data);
  request[CANCEL] = () => source.cancel();
  return request;
}

export function getAdvisorDashboardData(): Promise<AdvisorDashboardDataResponse> {
  return api.get("/my/advisor_dashboard", { params: { 'tz_offset': -(new Date().getTimezoneOffset() * 60) } }).then((response) => response.data);
}

export function getAdvisorDashboardInsightData(): Promise<InsightApiResponse> {
  return api.get("/my/advisor_dashboard/insights", { params: { 'tz_offset': -(new Date().getTimezoneOffset() * 60) } }).then((response) => response.data);
}
