import { createAction } from "@reduxjs/toolkit";
import {
  PubNubMessage,
} from "../types/messageTypes";
import {
  AdminMessageType,
  SystemMessageType,
  UserMessageType,
} from "../types/pubNubTypes";

export const initPubNubSession = createAction("pubnub/init_pubnub_session");

export const processAdminMessage = createAction<AdminMessageType>("pubnub/process_admin_message");

export const processUserMessage = createAction<UserMessageType>("pubnub/process_user_message");

export const processSystemMessage = createAction<SystemMessageType>("pubnub/process_system_message");

export const sendPubNubMessage = createAction<PubNubMessage>("pubnub/send_message");

export const processMessageQueue = createAction("pubnub/process_message_queue");
