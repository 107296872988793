import { useEffect } from "react";
import { ServerUserStatus } from "../../types/userTypes";
import { CURRENT_SITE_ID } from "../../config";

export interface IStatusToFavIconProps {
  status: ServerUserStatus;
  isUserSignedId: boolean;
}
var isSafariDetected: number = 0;

export default function StatusToFavIcon(props: IStatusToFavIconProps) {
  function getFavIconElement(): HTMLLinkElement {
    return document.getElementById("favicon") as HTMLLinkElement;
  }

  function getIconName(status: ServerUserStatus, isUserSignedId: boolean) {
    if (!isUserSignedId) {
      return `${CURRENT_SITE_ID?.toLowerCase()}_fav_off.png`;
    }

    let icon: string;

    switch (status) {
      case ServerUserStatus.Available:
        icon = "fav_available.png";
        break;
      case ServerUserStatus.Away:
        icon = "fav_off.png";
        break;
      case ServerUserStatus.Busy:
        icon = "fav_busy.png";
        break;
      case ServerUserStatus.Suspended:
        icon = "fav_block.png";
        break;
      default:
        icon = "fav.png";
        break;
    }

    return `${CURRENT_SITE_ID?.toLowerCase()}_${icon}`;
  }

  function changeUrlToFavIcon(sourceUrl: string, faviconName: string) {
    var sp = sourceUrl.split("/");
    sp[sp.length - 1] = faviconName;
    return sp.join("/");
  }

  function isSafari(): boolean {
    if (isSafariDetected !== 0) {
      return isSafariDetected === 1;
    }

    const userAgentString = navigator.userAgent;

    // Detect Chrome
    const chromeAgent = userAgentString.indexOf("Chrome") > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf("Safari") > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    isSafariDetected = safariAgent ? 1 : -1;

    return safariAgent;
  }

  useEffect(() => {
    if (isSafari()) {
      return;
    }
    const favicon = getFavIconElement();

    if (favicon) {
      const newUrl = changeUrlToFavIcon(
        favicon.href,
        getIconName(props.status, props.isUserSignedId)
      );
      favicon.href = newUrl;
    }
  }, [
    isSafari() ? 1 : props.status,
    isSafari() ? false : props.isUserSignedId,
  ]);

  return <></>;
}
