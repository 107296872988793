import * as React from 'react';
import { PulseIndicator } from '../../atoms/PulseIndicator';


const ClientsTypingIndication = () => (
    <>
      {
        <PulseIndicator />
      }
    </>
);

export default ClientsTypingIndication; 