import { call, debounce, put, select, takeEvery } from "redux-saga/effects";
import { LogEventEntry } from "../types/logTypes";

import { UAParser } from "ua-parser-js";
import * as jose from "jose";
import {
  APP_VERSION,
  LOGS_BATCH_KEY,
  LOGS_SAVING_INTERVAL,
} from "../config";
import { getDeviceId } from "../config/device_id";
import mixpanelService from "../services/mixpanel";
import { userSelector } from "../selectors/userSelectors";
import { UserState } from "../types/userTypes";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { batchFull, logAdded } from "../actions/logsActions";

type LogEntry = {
  device_os: string;
  device_lang: string;
  device_id: string;
  os_vesion: string;
  app_version: string;
  batch_id: string;
  user_id: number;
  mixpanel_distinct_id: string;
  events: LogEventEntry[];
};

function* sendLogs() {
  const events: LogEventEntry[] = JSON.parse(
    localStorage.getItem(LOGS_BATCH_KEY) || "[]"
  );

  if (!events.length) {
    return;
  }

  const parser = new UAParser();
  const parserResult = parser.getResult();

  const deviceId: string = yield call(getDeviceId);
  const user: UserState = yield select(userSelector);

  const entry: LogEntry = {
    device_id: deviceId,
    app_version: APP_VERSION,
    batch_id: uuidv4(),
    device_lang: navigator.language.substring(0, 2),
    device_os: parserResult.os.name || "",
    os_vesion: parserResult.os.version || "",
    user_id: user.userId!,
    mixpanel_distinct_id: mixpanelService.getDistinctId(),
    events: events,
  };

  const jwt: string = yield call(generateJwt, user.loggerSecret);

  try {
    yield call(sendLogEntry, user.loggerEndpoint, entry, jwt);
    localStorage.removeItem(LOGS_BATCH_KEY);
  } catch {
    yield put(logAdded());
  }
}

const sendLogEntry = async (url: string, entry: LogEntry, jwt: string) => {
  return await axios.post(url, entry, {
    headers: {
      Authentication: `Bearer ${jwt}`,
      "Content-Type": "text/plain",
    },
    withCredentials: true,
  });
};

const generateJwt = async (secretValue: string) => {
  const secret = new TextEncoder().encode(secretValue);

  return await new jose.SignJWT()
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setExpirationTime("1m")
    .sign(secret);
};

export default function* root() {
  yield takeEvery(batchFull.type, sendLogs);
  yield debounce(LOGS_SAVING_INTERVAL, logAdded.type, sendLogs);
}
