import { useEffect } from "react";

declare var FB: any;

export type FbResponse = {
    status: string, //status specifies the login status of the person using the app. The status can be one of the following:
    // connected - the person is logged into Facebook, and has logged into your app.
    // not_authorized - the person is logged into Facebook, but has not logged into your app.
    // unknown - the person is not logged into Facebook, so you don't know if they've logged into your app or FB.logout() was called before and therefore, it cannot connect to Facebook.
    authResponse: {
        accessToken: string,
        data_access_expiration_time:number,
        expiresIn: string,
        graphDomain:string,
        signedRequest:string,
        userID:string
    }
}

function loadSdk(callback:()=>void, fbAppId:string) {
    const sdk = document.getElementById("facebookSdk");
    if (!sdk) {
        const script = document.createElement("script");
        script.id = "facebookSdk"
        script.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0&appId=${fbAppId}`;

        script.onload = function () { 
            FB.init({ appId: `${fbAppId}`, cookie: true, xfbml: false, version: "v17.0" });
            callback();
        }
        document.body.appendChild(script);    
    }
}

export const serviceFB = {
    signIn: function (callback:(response:FbResponse)=> void) { 
        FB.login(function(response:FbResponse) {
            callback(response);
        }, { scope: 'public_profile,email' });
    },
}
type FacebookSignInProps = {
    sdkLoaded: () => void;
    fbAppId: string;
  };

function FacebookSignIn(props: FacebookSignInProps) {
    
    useEffect(() => { 

        loadSdk(props.sdkLoaded, props.fbAppId);

    },[]);


    return(<></>)
}


export default FacebookSignIn;