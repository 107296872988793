import './RatingFace.css';


type RatingFaceProps = {
    value: boolean |null;
    tooltip: string | null;
}

const RatingFace = ({value,tooltip} : RatingFaceProps) => {
    if(!(value === false || value === true)) {
        return null;
    }
    return (<>
        {value && <i className="smile-icon" data-tooltip-content={tooltip }
                                data-tooltip-id="client-reading-tooltip"/>}
        {!value && <i className="sad-icon" data-tooltip-content={tooltip }
                                data-tooltip-id="client-reading-tooltip"/>}
    </>);
    
}

export default RatingFace;

