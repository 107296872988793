import { AppState } from "../store";

export const selectedClientSelector = (state: AppState) => state.client.selectedClient;

export const selectedClientDobOrderSelector = (state: AppState) => state.client.selectedClient?.dobOrder;

export const isTranscriptLoadingSelector = (state: AppState) => state.client.isTranscriptLoading;

export const sessionTranscriptSelector = (state: AppState) => state.client.sessionTranscript;

export const transcriptStateSelector = (state: AppState) => state.client.transcriptState;
