import { useEffect } from "react";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { clientTranscriptLoad, clientTranscriptPopupState, loadClientLastReadings, resetLastReadingsClientData } from '../../slices/clientSlice';
import { setReadingViewed } from '../../slices/userSlice';
import LastReadings from "./LastReadings";
import Spinner from "./Spinner";
import { useRef } from "react";
import { ClientReadingData } from "../../types/clientTypes";
import { SessionType } from "../../types/clientListTypes";

type LastReadingsProps = {
    clientId: number
}

const PAGE_SIZE = 30;

const LastReadingsLoader = (props: LastReadingsProps & PropsFromRedux) => {
    const loadMoreRef = useRef<HTMLDivElement>(null);
    const observer = useRef<IntersectionObserver>();

    useEffect(() => {
        props.resetLastReadingsClientData();
        props.loadClientLastReadings(props.clientId);
        return () => {
            props.resetLastReadingsClientData();
        }
    }, []);


    const scrolledDownHandler = () => {
        if (props.isLastReadingsLoading) {
            return;
        }

        if (props.nextPage) {
            props.loadClientLastReadings(props.clientId, props.nextPage);
        }
    };

    useEffect(() => {

        const handleObserver = (entities: any) => {
            const target = entities[0];
            if (target.isIntersecting) {
                scrolledDownHandler();
            }
        }
    
        if (props.lastReadings.length >= PAGE_SIZE) { 
            var options = {
                root: null,
                threshold: 0.5
            };
            observer.current = new IntersectionObserver(handleObserver, options);
            if (loadMoreRef.current) {
                observer.current.observe(loadMoreRef.current);
            }
        }

        return () => { if (observer.current) { observer.current.disconnect() } };
        
    }, [props.lastReadings]);

    const readingClickHandler = (item: ClientReadingData) => {
        props.setReadingViewed(item.sessionId);
        if (item.sessionType === SessionType.Chat) {
            props.clientTranscriptLoad(item.sessionId, item.sessionStartTime);    
        }
    }

    return <>
        <LastReadings
            viewedReadingsId={props.viewedReadingsId}
            readingClickHandler={readingClickHandler}
            isLoading={props.isLastReadingsLoading}
            items={props.lastReadings} />
        <div ref={loadMoreRef} className="load-more">
            {props.isLastReadingsLoading && <Spinner size={40} />}
        </div>
    </>;
};


const mapState = (state: AppState) => ({
    isLastReadingsLoading: state.client.isLastReadingsLoading,
    lastReadings: state.client.lastReadings,
    nextPage:state.client.nextPage,
    viewedReadingsId: state.user.viewedReadingIds
});

const connector = connect(mapState,
    { loadClientLastReadings, resetLastReadingsClientData, setReadingViewed, clientTranscriptPopupState, clientTranscriptLoad })

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(LastReadingsLoader);

