import * as React from "react";
import { DISPLAY_TIME_OF_SAVED } from "../../../config";
import Spinner from "../Spinner";
import "./SaveConfirmButton.scss";

export interface ISaveConfirmButtonProps {
  isSaving: boolean;
  isDisabled: boolean;
}

export function SaveConfirmButton(props: ISaveConfirmButtonProps) {
  const [isSaved, setIsSaved] = React.useState(false);

  let refBtn: HTMLButtonElement | undefined;

  React.useEffect(() => {
    if (props.isSaving) {
      setIsSaved(true);
    }
  }, [props.isSaving]);

  React.useEffect(() => {
    if (isSaved && !props.isSaving) {
      const timer = setTimeout(() => {
        setIsSaved(false);
      }, DISPLAY_TIME_OF_SAVED);
      return () => clearTimeout(timer);
    }
  }, [isSaved, props.isSaving]);

  return (
    <div className="scb-wrapper">
      {props.isSaving && <Spinner size={17} />}
      {!props.isSaving && (
        <button
          ref={(ref) => (refBtn = ref || undefined)}
          type="submit"
          className="scb-save"
          disabled={props.isDisabled || isSaved}
        >
          {isSaved && "Saved"}
          {!isSaved && "Save"}
        </button>
      )}
    </div>
  );
}
