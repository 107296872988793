import Tippy from "@tippyjs/react";
import * as React from "react";
import { useState } from "react";
import "tippy.js/dist/tippy.css";

import './SlidingButton.scss';

export interface ISlidingButtonProps {
    icon: string,
    children: string,
    expandedWidth: string,
    showTooltip: boolean,
    tooltipContent: JSX.Element,
    onClick: () => void,
}

export const SlidingButton = (props: ISlidingButtonProps) => {
    const [isCollapsed, setCollapsed] = useState(true);

    const clickSetCollapsed = () => {
        if (isCollapsed) {
            return;
        }

        setCollapsed(true);
    };

    const clickSetExpanded = () => {
        if (!isCollapsed || props.showTooltip) {
            return;
        }

        setCollapsed(false);
    };

    return (
        <>
            {
                props.showTooltip && <div className="tooltip-backdrop"></div>
            }
            <div className="sliding-button">
                <Tippy
                    content={props.tooltipContent}
                    visible={props.showTooltip}
                    interactive={true}
                    theme="wem"
                    placement="left"
                    appendTo={document.body}>
                    <div className="sliding-button__icon">
                        <button
                            type="button"
                            style={{
                                display: isCollapsed ? "flex" : "none",
                            }}
                            className="sliding-button__icon__collapsed"
                            onClick={clickSetExpanded}>
                            <img src={props.icon} alt={props.children} title={props.children} />
                        </button>
                        <button
                            type="button"
                            style={{
                                display: isCollapsed ? "none" : "flex",
                            }}
                            className="sliding-button__icon__expanded"
                            onClick={clickSetCollapsed}>
                            <img src="/images/sliding-button-close-icon.svg" alt="Close" title="Close" />
                        </button>
                        <div className="sliding-button__icon__border"></div>
                    </div>
                </Tippy>

                <div
                    className={`sliding-button__content 
                    ${isCollapsed ? "" : "expanded"}
                `}
                    style={{
                        width: isCollapsed ? "0" : props.expandedWidth
                    }}>
                    <button
                        type="button"
                        onClick={props.onClick}>
                        {props.children}
                    </button>
                </div>
            </div>
        </>
    );
}