import React, { useState, useEffect, useRef } from "react";
import ReactHowler from "react-howler";
import { GetRingingByType } from "../../../helpers/settingsHelper";
import * as appSettingsTypes from "../../../types/appSettingsTypes";
interface Props {
  isRinging: boolean;
  ringing: appSettingsTypes.Ringing;
  volume: number;
}

const RingingInterval = 1000;

const Ringing = ({ isRinging, ringing, volume }: Props) => {
  const howler = useRef<ReactHowler>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    if (isRinging) {
      if(howler.current){
        howler.current.seek(0);
      }
      setIsPlaying(true);
    }
  }, [isRinging]);

  const onEndPlay = () => {
    setIsPlaying(false);
    setTimeout(() => setIsPlaying(true), RingingInterval);
  };

  return (
    <ReactHowler
      ref={howler}
      onEnd={onEndPlay}
      src={GetRingingByType(ringing)}
      playing={isRinging && isPlaying}
      preload
      volume={volume / 100}
    />
  );
};

export default Ringing;
