export enum ModalType {
  Empty,
  InternalError,
  ConnectionError,
  Alert,
  ScheduledMessage,
  NotificationsAllow,
  NotificationsBlocked,
  OnboardSlides,
  FeedbackForm,
  QuitSession,
  BlockClientConfirm,
  BlockClientHighValued,
  Slides,
  GroupMessage,
  GroupMessageConfirm,
  BlacklistAlert,
  WaitingClient,
  ForgotPassword,
  AcceptNewTermsAndPolicies,
  Unauthorized,
  SelectCoupon
}

export interface ModalState {
  modalType: ModalType | null;
  modalTitle: string;
  modalContent: string;
  btnOkText: string | null;
  props: any;
}